export class XPayError extends Error {
  constructor({
    errorCode,
  }) {
    super();
    this.name = 'XPayError';
    this.errorCode = errorCode;
  }
  toString() {
    return `${this.errorCode}: ${super.toString()}`;
  }
}

export class SubmitError extends XPayError {
  constructor({
    errorCode,
    errorInfo,
  }) {
    super({ errorCode });
    this.name = 'SubmitError';
    this.errorInfo = errorInfo;
    this.errorMsg = errorInfo ? (errorInfo.errorMsg || errorInfo.message) : null;
  }
}

export class PidlValidationError extends SubmitError {
  constructor({
    errorCode,
    errorInfo,
  }) {
    super({ errorCode, errorInfo });
    this.name = 'PidlValidationError';
  }
}

export class PidlFatalError extends SubmitError {
  constructor({
    errorCode,
    errorInfo,
  }) {
    super({ errorCode, errorInfo });
    this.name = 'PidlFatalError';
  }
}

export class BluesnapError extends Error {
  constructor(errorMsg) {
    super();
    this.errorMsg = errorMsg;
    this.name = 'BluesnapError';
  }
}

export class TransactionError extends Error {
  constructor(errorMsg) {
    super();
    this.name = 'TransactionError';
    this.errorMsg = errorMsg;
  }
}

export class CreatePaymentError extends TransactionError {
  constructor(errorMsg) {
    super(errorMsg);
    this.name = 'CreatePaymentError';
  }
}

export class UpdatePaymentError extends TransactionError {
  constructor(errorMsg) {
    super(errorMsg);
    this.name = 'UpdatePaymentError';
  }
}

export class CapturePaymentError extends TransactionError {
  constructor(errorMsg) {
    super(errorMsg);
    this.name = 'CapturePaymentError';
  }
}

export class AuthPaymentError extends TransactionError {
  constructor(errorMsg) {
    super(errorMsg);
    this.name = 'AuthPaymentError';
  }
}

export class CapturePaymentTimeoutError extends TransactionError {
  constructor(errorMsg, paymentId) {
    super(errorMsg);
    this.name = 'CapturePaymentTimeoutError';
    this.paymentId = paymentId;
  }
}

export class MsaAuthError extends Error {
  constructor(errorMsg) {
    super(errorMsg);
    this.name = 'MsaAuthError';
  }
}

export class ClosePanelError extends Error {
  constructor(errorMsg) {
    super(errorMsg);
    this.name = 'ClosePanelError';
  }
}

export class AjaxServerError extends XPayError {
  constructor(serverError) {
    super(serverError.errorCodes?.length
      ? serverError.errorCodes[0]
      : 'ServerError');

    this.message = serverError.message;
    this.name = 'AjaxServerError';
    this.errorCodes = serverError.errorCodes;
    this.additionalDetails = serverError.additionalDetails;
  }
}

export class CPError extends Error {
  constructor(errorMsg) {
    super(errorMsg);
    this.name = 'CPError';
  }
}

export class JSONError extends Error {
  constructor(data) {
    super(data && JSON.stringify(data));
    this.data = data;
    this.name = 'JSONError';
  }
}

export class UnauthorizedError extends Error {
  constructor(errorMsg) {
    super(errorMsg);
    this.name = 'UnauthorizedError';
  }
}

export class BraintreeHostedFieldError extends Error {
  constructor() {
    super();
    this.name = 'BraintreeHostedFieldError';
  }
}

export class StripeError extends Error {
  constructor(errorMsg) {
    super(errorMsg);
    this.name = 'StripeCreatePaymentMethodError';
  }
}

