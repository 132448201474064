import { Checkbox, DefaultButton, ICheckboxStyles, Stack } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useI18n } from '../../../FunctionModules/Localization/i18n';
import { ubEvent } from '../../../FunctionModules/Logger/log-instrument';
import { PidlValidationErrors } from '../../../Shared/Components/add-pi-pidl/pidl-errors';
import { useGlobalReactContext } from '../../../Shared/Components/context/global-react-context';
import { logError } from '../../Rebates/logging';
import { PiDetailsFromPIDLForm } from '../../Subscription/Components/interface';
import PI_TYPE from '../../../Shared/Entities/pi-types';
import { useIframeAddPIPidl } from '../../../Shared/Components/add-pi-pidl/iframe/iframe-wrapper';

export enum CloError {
  AuthError,
  SaveCardError,
  ExportCardError,
  PiListError,
}

export interface GenericPidlFormResponse{
  err: CloError,
  cardNumber: string,
  isCardSaved: boolean,
  CVV: string;
  City: string;
  Country: string;
  ExpiryMonth: string;
  ExpiryYear: string;
  Line1: string;
  Name: string;
  PostCode: string;
  State: string;
  Line2?: string;
  cardType: string;
}

const checkboxStyles: ICheckboxStyles = {
  root: {
    marginTop: 12,
  },
};

export const PidlForm = ({
  onFinish, cardNumber, shouldShowConfirmButton = true, customSubmitButtonId = '',
} :
  {
    onFinish : (data:GenericPidlFormResponse) => void,
    cardNumber : string,
    shouldShowConfirmButton: boolean,
    customSubmitButtonId: string
  }) => {
  const {
    country,
  } = useGlobalReactContext();
  const [errors, setPIDLValidationError] = useState(null);
  const [agreeToTOS, { toggle: toggleAgreeeToTOS }] = useBoolean(false);
  const { i18n } = useI18n();

  const {
    pidlForm, submitPidl, isValid: isPidlValid, validationErrors, piDetails, cardType,
  } = useIframeAddPIPidl({
    country,
    shouldLoadPidl: true,
    piType: PI_TYPE.CARD,
    prefillValues: { accountToken: cardNumber },
  });

  const onSuccess = useCallback(async () => {
    ubEvent('Submit clicked');
    let cardSaved = false;
    if (!isPidlValid) {
      ubEvent('Error when ConfirmBtnClicked', { validationErrors: (validationErrors || []).join(',') });
      setPIDLValidationError(validationErrors);
      return;
    }
    setPIDLValidationError(null);
    let card = '';
    let error;
    try {
      await submitPidl();
      cardSaved = true;
    } catch (err) {
      logError({
        message: 'SaveCardFailed',
        err,
      });
      error = CloError.SaveCardError;
    }
    card = ((piDetails as PiDetailsFromPIDLForm).CardNumber);
    const { CVV } = piDetails as PiDetailsFromPIDLForm;
    const { City } = piDetails as PiDetailsFromPIDLForm;
    const { Country } = piDetails as PiDetailsFromPIDLForm;
    const { ExpiryMonth } = piDetails as PiDetailsFromPIDLForm;
    const { ExpiryYear } = piDetails as PiDetailsFromPIDLForm;
    const { Line1 } = piDetails as PiDetailsFromPIDLForm;
    const { Name } = piDetails as PiDetailsFromPIDLForm;
    const { PostCode } = piDetails as PiDetailsFromPIDLForm;
    const { State } = piDetails as PiDetailsFromPIDLForm;
    const { Line2 } = piDetails as PiDetailsFromPIDLForm;
    ubEvent('User successfully saved the card to the MSA account');
    onFinish({
      cardNumber: card, CVV, City, Country, ExpiryMonth, ExpiryYear, Line1, Line2, Name, PostCode, State, err: error, isCardSaved: cardSaved, cardType,
    });
  }, [isPidlValid, piDetails, onFinish, cardType, validationErrors, submitPidl]);

  useEffect(() => {
    if (customSubmitButtonId !== '') {
      document.getElementById(customSubmitButtonId).addEventListener('click', onSuccess);
    }
  }, [customSubmitButtonId, onSuccess]);

  return (
    <Stack>
      {pidlForm}
      {errors &&
      <PidlValidationErrors PIDLValidationErrors={errors} />
    }
      {shouldShowConfirmButton &&
      <div id="pidl-form-confirm-button">
        <Checkbox
          styles={checkboxStyles}
          checked={agreeToTOS}
          onChange={toggleAgreeeToTOS}
          label={`${i18n.SapphireTerms} `}
        />
        <DefaultButton text={i18n.Confirm} disabled={!agreeToTOS} onClick={onSuccess} />
      </div>
    }
    </Stack>
  );
};
