export const insertSilentAuthIframeBeforeRefNode = (referContainerId: string, newContainerId: string) => {
  const container = document.getElementById(referContainerId);
  const newNode = document.createElement('div');
  newNode.id = newContainerId;
  container.parentNode.insertBefore(newNode, container);
};

export const insertSilentAuthIframeIntoBody = (newContainerId: string) => {
  const newNode = document.createElement('div');
  newNode.id = newContainerId;
  document.body.appendChild(newNode);
};
