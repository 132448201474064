const EventTypes = Object.freeze({
  IframeLoaded: 'PidlIframeLoaded',
  UpdateProps: 'UpdateProps',
  UpdateReturnValues: 'UpdateReturnValues',
  PidlSubmit: 'PidlSubmit',
  UpdateSize: 'UpdateSize',
  Navigate: 'Navigate',
  PopupPromise: 'PopupPromise',
  PidlLoaded: 'PidlLoaded',
});

export const getCallbackType = key => `PIDL_CALLBACK_${key}`;

export default EventTypes;
