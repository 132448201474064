import { v4 as uuidv4 } from 'uuid';
import { xpayEnv } from '../../Shared/Utils/xpay-env-values';
import { PartnerThemeList } from '../Themes/constants';
import { ScenarioNames } from './constants';
import { jsBuildVersion, jsFeatureName, jsBundleName } from './js-build-variables';
import { MarketMetaData } from './market-metadata-types';
import { getChannelFromURL } from '../../Shared/Utils/url-helper';

const isCurrentWindowInIframe = () => window.self !== window.top;

const makeHostPageId = () => {
  if (window.location) {
    try {
      const { search } = window.location;
      const searchParams = new URLSearchParams(search);
      const hostPageId = searchParams.get('hostPageId');
      if (hostPageId) { return hostPageId; }
    } catch {
      // Nothing to do.
    }
  }
  return uuidv4();
};

interface GlobalContext {
  accountId: string;
  sdkVer: string;
  sdkAppName: string;
  featureName: string;
  bundleEntryName: string;
  scenarioName: string;
  sessionId: string;
  hostPageId: string;
  hostPagePath: string;
  PrevScenarioName: string;
  PrevSessionId: string;
  isInIframe: boolean;
  pilotFlags: any;
  additionalLogProperties: any;
  correlationId: string;
  oneDSEnv: string,
  env: number,
  marketMetaData: MarketMetaData,
  flightIds: string[],
  channel: string | undefined,
  noIframePilots: boolean,
  bizContext: any,
}

export const globalContext: GlobalContext = {
  accountId: undefined,
  sdkVer: jsBuildVersion,
  sdkAppName: undefined,
  featureName: jsFeatureName,
  bundleEntryName: jsBundleName,
  scenarioName: undefined,
  sessionId: null,
  hostPageId: makeHostPageId(),
  hostPagePath: window.location?.pathname,
  PrevScenarioName: undefined,
  PrevSessionId: null,
  isInIframe: isCurrentWindowInIframe(),
  pilotFlags: {},
  additionalLogProperties: {},
  correlationId: null,
  oneDSEnv: undefined,
  env: xpayEnv,
  marketMetaData: undefined,
  flightIds: [],
  channel: getChannelFromURL(),
  noIframePilots: false,
  bizContext: undefined,
};

export const getChannel = () => globalContext.channel;

export const trackClientSdk = (sdkAppName: string) => {
  globalContext.sdkAppName = sdkAppName;
};

export const setPilotFlags = (flagKey: string, value: string|number|boolean) => {
  globalContext.pilotFlags[flagKey] = value ? 1 : 0;
};

export const getPilotFlag = (flagKey: string) => globalContext.pilotFlags[flagKey];

export const setAdditionalLogProperties = (additionalLogProperties: any) => {
  globalContext.additionalLogProperties = additionalLogProperties;
};

export const setScenarioName = (currentTheme: string) => {
  switch (currentTheme) {
    case PartnerThemeList.BingGive:
      globalContext.scenarioName = ScenarioNames.BING_GIVE;
      break;
    case PartnerThemeList.BingSERP:
      globalContext.scenarioName = ScenarioNames.BING_SERP;
      break;
    case PartnerThemeList.EdgeTipping:
      globalContext.scenarioName = ScenarioNames.EDGE_TIPPING;
      break;
    case PartnerThemeList.Buynow:
      globalContext.scenarioName = ScenarioNames.BuyNow;
      break;
    case PartnerThemeList.EdgeMobileWallet:
      globalContext.scenarioName = ScenarioNames.EDGE_MOBILE_WALLET;
      break;
    case PartnerThemeList.BuyNowFirstParty:
      globalContext.scenarioName = ScenarioNames.BUYNOW_FIRST_PARTY;
      break;
    default:
      break;
  }
};

export const setBizContext = (bizContext: any) => {
  globalContext.bizContext = bizContext;
};

export const setMarketMetaData = (marketData: MarketMetaData) => {
  globalContext.marketMetaData = marketData;
};

export const startNewSession = (scenarioName: string) => {
  globalContext.PrevScenarioName = globalContext.scenarioName;
  globalContext.scenarioName = scenarioName;

  globalContext.PrevSessionId = globalContext.sessionId;
  globalContext.sessionId = uuidv4();
};

export const setNoIframePilots = () => {
  globalContext.noIframePilots = true;
};

interface ServerContext {
  hostPageId: string;
  sessionId: string;
  scenarioName: string;
  oneDSEnv: string;
  parentEnv: number;
}

export const initGlobalContext = (serverContext: ServerContext) => {
  if (isCurrentWindowInIframe() && serverContext) {
    if (serverContext.hostPageId) {
      globalContext.hostPageId = serverContext.hostPageId;
    }
    if (serverContext.sessionId) {
      globalContext.sessionId = serverContext.sessionId;
    }
    if (serverContext.scenarioName) {
      setScenarioName(serverContext.scenarioName);
    }
  }
};
