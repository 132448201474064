import { AppInsightsCore, IExtendedConfiguration } from '@microsoft/1ds-core-js';
import { PropertiesPlugin, IPropertyConfiguration } from '@microsoft/1ds-properties-js';
import { PostChannel, IChannelConfiguration } from '@microsoft/1ds-post-js';

import { cookLogWithContext, getLogLevelLabel } from './utils';
import { LogEntity } from './types';

import { oneDSInstrumentationKey } from '../../Shared/Utils/xpay-env-values';

import { logOneUXMessage } from './log-ux';

const appInsightsCore: AppInsightsCore = new AppInsightsCore();
let isInitialized = false;
const defaultInstrumentationKey = '2a671c162c284339b81ee479448942a8-3154a86d-78b7-4957-bba7-a61708ca9dd3-6991';
enum InitFrom {
  privateMethod,
  publicMethod
}

const initCore = (instrumentationKey: string, from: number = InitFrom.publicMethod) => {
  if (appInsightsCore.isInitialized()) {
    return;
  }

  const propertiesPlugin: PropertiesPlugin = new PropertiesPlugin();
  // Sends data to OneCollector
  const collectorChannelPlugin: PostChannel = new PostChannel();

  // https://msasg.visualstudio.com/Shared%20Data/_git/1DS.JavaScript?path=%2Fskus%2FanalyticsWeb%2FREADME.md&version=GBmaster&fullScreen=true&_a=preview
  const coreConfig: IExtendedConfiguration = {
    // send the logs to int kusto DB,, we can check it later
    instrumentationKey: instrumentationKey || defaultInstrumentationKey,
    extensions: [
      propertiesPlugin,
      collectorChannelPlugin,
    ],
    extensionConfig: [],
  };
  const propertiesPluginConfig: IPropertyConfiguration = {
    serviceName: '1ds-xpay',
  };

  const postChannelConfig: IChannelConfiguration = {
    autoFlushEventsLimit: 10,
  };

  coreConfig.extensionConfig[propertiesPlugin.identifier] = propertiesPluginConfig;
  coreConfig.extensionConfig[collectorChannelPlugin.identifier] = postChannelConfig;

  appInsightsCore.initialize(coreConfig, []);
  isInitialized = true;

  if (from === InitFrom.publicMethod) {
    // we get env from setting config, so the 1ds should be inited at first
    // use this log to trace corner cases, in which 1ds will be inited by feature code
    appInsightsCore?.track?.({
      name: 'xpay_client_log_debug',
      data: {
        Message: 'init 1ds using public method',
        URL: window.location?.href.replace(window.location?.hash, ''),
      },
    });
  }
};

export const oneDSInit = () => {
  initCore(oneDSInstrumentationKey);
};

const privateOneDSInit = () => {
  initCore(oneDSInstrumentationKey, InitFrom.privateMethod);
};

const getScreen = () => ({
  colorDepth: window.screen?.colorDepth,
  height: window.screen?.height,
  pixelDepth: window?.screen?.pixelDepth,
  width: window.screen?.width,
  devicePixelRatio: window?.devicePixelRatio,
});

export const logger = (log: LogEntity, occasion = '') => {
  if (!appInsightsCore?.isInitialized() || !isInitialized) {
    initCore(oneDSInstrumentationKey);
  }
  if (!appInsightsCore?.isInitialized()) {
    logOneUXMessage('1DS initialized failed');
  }

  const wrapperLog = cookLogWithContext(log);
  appInsightsCore?.track?.({
    name: 'xpay_client_log',
    data: {
      Timestamp: wrapperLog?.timestamp,
      Message: wrapperLog?.data,
      CorrelationId: wrapperLog?.correlationId,
      Duration: wrapperLog?.duration,
      EventName: wrapperLog?.eventName,
      Uri: wrapperLog?.url,
      Referral: wrapperLog?.referral,
      SessionId: wrapperLog?.sessionId,
      HostPageId: wrapperLog?.hostPageId,
      LogSeq: wrapperLog?.logSeq,
      SC: wrapperLog?.scenarioName,
      IF: wrapperLog?.isInIframe, // If the log is from iframe
      LUID: wrapperLog?.luid,
      OC: occasion, // The occasion when log was being emitted to server
      SAN: wrapperLog?.sdkAppName,
      SV: wrapperLog?.sdkVer,
      FN: wrapperLog?.featureName,
      BEN: wrapperLog?.bundleEntryName,
      MUID: wrapperLog?.muid,
      ANON: wrapperLog?.anon,
      MKT: JSON.stringify(wrapperLog?.market),
      Pilots: wrapperLog?.pilotFlags,
      AS: wrapperLog?.associationId,
      Category: wrapperLog?.isPerf ? 'ClientPerformance' : 'ClientLog',
      LogLevel: wrapperLog?.isPerf ? 'Performance' : getLogLevelLabel(wrapperLog?.logLevel),
      Url: window.location?.href.replace(window.location?.hash, ''),
      screen: JSON.stringify(getScreen()),
      BizContext: JSON.stringify(wrapperLog?.bizContext),
      // flightIds: JSON.stringify(wrapperLog?.flightIds), // Stop it to reduce storage usage.
    },
  });
};

privateOneDSInit();

