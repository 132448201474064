export const MerchantId = {
  defaultID: '0',
};

export const PaymentWorkflow = Object.freeze({
  BlueSnapStoredCardFlow: 0x24,
  BlueSnapGuestCheckoutFlow: 0x25,
  BlueSnapTokenizedFlow: 0x26,
  BlueSnap3DSEnabledStoredCardFlow: 0x28,
  BlueSnap3DSEnabledGuestCheckoutFlow: 0x29,
  BlueSnap3DSEnabledTokenizedFlow: 0x30,
  StripeCardPayment: 0x31,
  StripeCardTokenizeWithPayment: 0x32,
  StripeTokenizedCardPayment: 0x34,
  PayPalAdvancedCard: 0x11,
  PayPalTokenizedCard: 0x14,
  PayPalTokenizedCardWithBraintree: 0x16,
  BitPayFlow: 0x51,
});

export const PaymentInstrumentProvider = Object.freeze({
  None: 0,
  CommercePlatformMsaWallet: 1,
  CommercePlatformXpayWallet: 2,
  PayPal: 3,
  Stripe: 4,
  Benevity: 5,
  BlueSnap: 6,
  BitPay: 7,
  Braintree: 8,
  Rewards: 9,
  Zyla: 10,
  Hyperwallet: 11,
});

export const PaymentInstrumentProviderStrings = Object.freeze({
  None: 'None',
  CommercePlatformMsaWallet: 'CommercePlatformMsaWallet',
  CommercePlatformXpayWallet: 'CommercePlatformXpayWallet',
  PayPal: 'PayPal',
  Stripe: 'Stripe',
  Benevity: 'Benevity',
  BlueSnap: 'BlueSnap',
  BitPay: 'BitPay',
  Braintree: 'Braintree',
  Rewards: 'Rewards',
  Zyla: 'Zyla',
  Hyperwallet: 'Hyperwallet',
});

// Ensure this matches PaymentInstrumentTokenProvider in src\shared\Microsoft.Pay.Entity\Sql.Account\PaymentInstrumentTokenProvider.cs
export const PaymentInstrumentTokenProvider = Object.freeze({
  None: 0,
  CommercePlatform: 1,
  PayPal: 2,
  Stripe: 3,
  Benevity: 4,
  BlueSnap: 5,
  Braintree: 6,
  StripeGeneralDE: 7,
  StripeForumUS: 8,
});

export const PaymentInstrumentDirection = Object.freeze({
  None: 0,
  PayIn: 1,
  PayOut: 2,
  PayInOut: 3,
});

export const PaymentStatus = Object.freeze({
  None: 0,
  Approving: 1,
  Pending: 2,
  Settled: 3,
  Declined: 4,
  Reversal: 5,
  Confirming: 6,
  Deleted: 7,
  Failed: 8,
});

export const RecurringPaymentState = Object.freeze({
  OnlinePaymentRequired: 0,
  OnlinePaymentNotRequired: 1,
  InProgress: 2,
  Settled: 3,
  Declined: 4,
  Failed: 5,
  Expired: 6,
  Completed: 7,
  Cancelled: 9,
  CancelledByPaymentDecline: 10,
});

export const RecurringPaymentStateString = Object.freeze({
  OnlinePaymentRequired: 'OnlinePaymentRequired',
  OnlinePaymentNotRequired: 'OnlinePaymentNotRequired',
  InProgress: 'InProgress',
  Settled: 'Settled',
  Declined: 'Declined',
  Failed: 'Failed',
  Expired: 'Expired',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  CancelledByPaymentDecline: 'CancelledByPaymentDecline',
});

export const OrderSource = {
  BingDonation: 1, // Always be 1 for any donation scenario.
  MSNContentService: 2,
  EdgeTipping: 3,
};

export const DonationOrderType = {
  BingGive: 1, // Give with Bing
  BingSERP: 2, // Bing search result page
  EdgeTipping: 3,
};

export const OrderId = { // Reserved. Always use '0' for now.
  defaultID: '0',
};

export const StripePlatform = {
  General: 'General',
  Forum: 'Forum',
};

export const DefaultPINumberPlaceHolderToGenerateNewPI = '';

export const IndefiniteRecurringCycle = -1;
