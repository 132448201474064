import _ from 'underscore';
import { StringsType } from './migration';

const templateSettings = {
  interpolate: /\{\{([^{^}]+?)\}\}/g,
};

export default function getString(i18n: StringsType, key: keyof StringsType, model: any, {
  preprocess = _.identity,
} = {}) {
  const stringValue = i18n[key];

  if (model) {
    return preprocess(stringValue).replace(templateSettings.interpolate, field => model[field.substr(2, field.length - 4)]);
  }

  return stringValue;
}
