import _ from 'underscore';

const luhnChecksum = (code) => {
  const len = code.length;
  const parity = len % 2;
  let sum = 0;
  for (let i = len - 1; i >= 0; i -= 1) {
    let d = parseInt(code.charAt(i), 10);
    if (i % 2 === parity) {
      d *= 2;
    }
    if (d > 9) {
      d -= 9;
    }
    sum += d;
  }
  return sum % 10;
};

export const checkLuhn =
  cardNum => luhnChecksum(cardNum) === 0;

const acceptedCreditCards = {
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  mc: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
  amex: /^3[47][0-9]{13}$/,
  // eslint-disable-next-line max-len
  discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
};

const checkCardTypeValidation = cardNum => _.findKey(
  acceptedCreditCards,
  validationRegex => validationRegex.test(cardNum)
);

export const validateCard = (cardNumInputText) => {
  if (!cardNumInputText) {
    return {
      isValid: false,
    };
  }
  // remove all non digit characters
  const cardNum = cardNumInputText.replace(/\D/g, '');
  const cardType = checkCardTypeValidation(cardNum);

  return {
    isValid: _.isString(cardType) && checkLuhn(cardNum),
    cardType,
  };
};

export const validateCvv = (cardCvv, cardType) => {
  if (!cardType || !cardCvv) {
    return false;
  }

  if (cardType === 'amex') {
    return cardCvv.length === 4;
  }

  return cardCvv.length === 3;
};

export const inputContainsOnlyNumbers = input => /^\d+$/.test(input);

export const validatePostCode = (postCode, country) => {
  if (!postCode) {
    return false;
  }
  let postCodeRegExp;
  switch (country.toLowerCase()) {
    case 'au':
      postCodeRegExp = /^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/;
      break;
    case 'gb':
    case 'uk':
      postCodeRegExp = /^[A-Z]{1,2}[0-9][0-9A-Z]? ?[0-9][A-Z]{2}$/;
      break;
    case 'ca':
      postCodeRegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
      break;
    case 'us':
    default:
      postCodeRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;
  }
  return postCodeRegExp.test(postCode);
};
