import _ from 'underscore';
import { logError, stringifyMessage } from '../Logger/log';

import {
  PERSISTENT_LISTENER_KEY,
  ONE_TIME_LISTENER_KEY,
  DEFAULT_ORIGIN_KEY,
  MessageTypes
} from './constants';
import { getDefaultOrigin, setDefaultOrigin } from './origin-override';
import { sendMessage } from './send-message';

export {
  MessageTypes,
  sendMessage,
  setDefaultOrigin,
};

type CleanupFunction = () => void;

const getListenerKey = (isOneTimeListener: boolean) => (isOneTimeListener
  ? ONE_TIME_LISTENER_KEY
  : PERSISTENT_LISTENER_KEY);

type MessageListener = (obj: any, event: MessageEvent<any>) => void;
interface TypeListenerObject {
  [ONE_TIME_LISTENER_KEY]?: MessageListener[],
  [PERSISTENT_LISTENER_KEY]?: MessageListener[],
}
interface OriginObject {[type: string]: TypeListenerObject}

export const messageListeners: { [origin: string]: OriginObject} = {};

const createRequestType = (type: string) => `${type}Request`;

let listener: (params: MessageEvent<any>) => void;

const compactListenerObjects = (originKey: string, type: string): void => {
  const originObject = messageListeners[originKey];
  const typeObject = originObject && originObject[type];

  if (typeObject) {
    // clear the single-call listeners if empty
    if (_.size(typeObject[ONE_TIME_LISTENER_KEY]) === 0) {
      delete typeObject[ONE_TIME_LISTENER_KEY];
    }

    // clear the multi-call listeners if empty
    if (_.size(typeObject[PERSISTENT_LISTENER_KEY]) === 0) {
      delete typeObject[PERSISTENT_LISTENER_KEY];
    }

    // clear the type object if empty
    if (_.size(typeObject) === 0) {
      delete originObject[type];
    }
  }

  // if there are no listeners for the origin, remove the origin listener mapping
  if (originObject && _.size(originObject) === 0) {
    delete messageListeners[originKey];

    // if there are no listeners, detach the message listener
    if (_.size(messageListeners) === 0) {
      window.removeEventListener('message', listener);
    }
  }
};

const getListenersAndClearOneTimeListeners = (originKey: string, type: string): TypeListenerObject => {
  const originObject = messageListeners[originKey];
  const typeObject = originObject && originObject[type];
  const oneTimeListeners = (typeObject && typeObject[ONE_TIME_LISTENER_KEY]) || [];
  const persistentListeners = (typeObject && typeObject[PERSISTENT_LISTENER_KEY]) || [];

  // clear the single-call listeners
  if (_.size(oneTimeListeners) !== 0) {
    delete typeObject[ONE_TIME_LISTENER_KEY];
  }

  compactListenerObjects(originKey, type);

  return {
    [ONE_TIME_LISTENER_KEY]: oneTimeListeners,
    [PERSISTENT_LISTENER_KEY]: persistentListeners,
  };
};

listener = (event: MessageEvent<any>) => {
  const type = event?.data?.type;

  if (_.isString(type)) {
    let allOneTimeListeners;
    let allPersistentListeners;
    ({
      [ONE_TIME_LISTENER_KEY]: allOneTimeListeners,
      [PERSISTENT_LISTENER_KEY]: allPersistentListeners,
    } = getListenersAndClearOneTimeListeners('*', type));

    // if there is are listeners for the specific origin
    if (_.has(messageListeners, event.origin)) {
      const {
        [ONE_TIME_LISTENER_KEY]: oneTimeListeners,
        [PERSISTENT_LISTENER_KEY]: persistentListeners,
      } = getListenersAndClearOneTimeListeners(event.origin, type);

      allOneTimeListeners = _.union(allOneTimeListeners, oneTimeListeners);
      allPersistentListeners = _.union(allPersistentListeners, persistentListeners);
    }

    const defaultOrigin = getDefaultOrigin();
    // if there are listeners for the default origin
    if (_.has(messageListeners, DEFAULT_ORIGIN_KEY)
      && (event.origin === defaultOrigin || defaultOrigin === '*')) {
      const {
        [ONE_TIME_LISTENER_KEY]: oneTimeListeners,
        [PERSISTENT_LISTENER_KEY]: persistentListeners,
      } = getListenersAndClearOneTimeListeners(DEFAULT_ORIGIN_KEY, type);

      allOneTimeListeners = _.union(allOneTimeListeners, oneTimeListeners);
      allPersistentListeners = _.union(allPersistentListeners, persistentListeners);
    }

    const listeners = _.union(allOneTimeListeners, allPersistentListeners);

    _.each(listeners, (action) => {
      try {
        action(event.data.data, event);
      } catch (err) {
        logError({
          message: 'Error executing action',
          error: stringifyMessage(err),
          origin: event.origin,
          type,
        });
      }
    });
  }
};

interface ListenerDetails {
  type: string,
  actionCb: MessageListener,
  isOneTimeListener: boolean,
  originOverride: string,
}

/**
 * This callback type is used to define the action performed by a listener.
 *
 * @callback actionCb
 * @param {data} data - Data recieved in the message
 * @param {Event} event - Message Event provided by the listener
 */

/**
 * This API is used to listen for requests from another window, then send responses back.
 * @param {string} type - Key to identity the message
 * @param {actionCb} actionCb - listener to be removed
 * @param {boolean} [isOneTimeListener] - If the listener was only supposed to execute once
 * @param {string} [originOverride] - If included, listener was set to this origin instead of default
 * @returns {void}
 */
export const removeListener = ({
  type,
  actionCb,
  isOneTimeListener = true,
  originOverride,
}: ListenerDetails): void => {
  const originKey = originOverride || DEFAULT_ORIGIN_KEY;
  const originObject = messageListeners[originKey];
  const typeObject = originObject && originObject[type];
  const listKey = getListenerKey(isOneTimeListener);
  const listenerList = _.has(typeObject, listKey) && typeObject[listKey];

  const listLength = _.size(listenerList);
  const newList = _.without(listenerList, actionCb);
  const newListLength = _.size(newList);

  if (newListLength === listLength) {
    // eslint-disable-next-line max-len
    const message = `Failed to remove listener for origin: ${originKey}, type: ${type}, isOneTimeListener: ${isOneTimeListener}`;
    logError(message);
    return;
  }

  typeObject[listKey] = newList;
  compactListenerObjects(originKey, type);
};

const addListeners = (...listeners: ListenerDetails[]): void => {
  // if there were no listeners, the listener wasn't attached, so we attach it
  if (_.size(messageListeners) === 0 && _.size(listeners)) {
    window.addEventListener('message', listener);
  }

  _.each(listeners, (listenerObj) => {
    const {
      originOverride,
      type,
      isOneTimeListener,
      actionCb,
    } = listenerObj;

    const listenerCountKey = getListenerKey(isOneTimeListener);
    const effectiveOrigin = originOverride || DEFAULT_ORIGIN_KEY;

    if (!messageListeners[effectiveOrigin]) {
      messageListeners[effectiveOrigin] = {};
    }

    if (!messageListeners[effectiveOrigin][type]) {
      messageListeners[effectiveOrigin][type] = {};
    }

    if (!messageListeners[effectiveOrigin][type][listenerCountKey]) {
      messageListeners[effectiveOrigin][type][listenerCountKey] = [];
    }
    messageListeners[effectiveOrigin][type][listenerCountKey].push(actionCb);
  });
};

interface ListenerSetupParams {
  type: string,
  actionCb: MessageListener,
  originOverride?: string,
}
/**
 * This API is used to listen for a message from another window.
 * After this message is recieved one time, this listener will clean itself up.
 * @param {string} type - Key to identify the message
 * @param {actionCb} actionCb - Additional data included in the message
 * @param {string} [originOverride] - If included, message will be sent to this origin instead of default
 * @returns {Function} Cleanup function to remove this listener (if not already cleaned up)
 */
export const listenOnce = ({
  type,
  actionCb,
  originOverride,
}: ListenerSetupParams): CleanupFunction => {
  let isRemoved = false;
  const newActionCb = _.wrap(actionCb, (func, ...args) => {
    isRemoved = true;
    func(...args);
  }) as MessageListener;

  const args: ListenerDetails = {
    type,
    isOneTimeListener: true,
    actionCb: newActionCb,
    originOverride,
  };

  addListeners(args);

  return () => {
    if (!isRemoved) {
      removeListener(args);
    }
  };
};

/**
 * This API is used to listen for a message from another window.
 * This listener will repeat on every message of the specified type.
 * @param {string} type - Key to identify the message
 * @param {actionCb} actionCb - Additional data included in the message
 * @param {string} [originOverride] - If included, message will be sent to this origin instead of default
 * @returns {Function} Cleanup function to remove this listener
 */
export const listenPersistently = ({
  type,
  actionCb,
  originOverride,
}: ListenerSetupParams): CleanupFunction => {
  addListeners({
    type,
    isOneTimeListener: false,
    actionCb,
    originOverride,
  });

  return () => {
    removeListener({
      type,
      actionCb,
      isOneTimeListener: false,
      originOverride,
    });
  };
};


/**
 * This API is used to send a request to another window, then wait for a response.
 * It will clean itself up after recieving the message
 * @param {string} type - Key to identity the message
 * @param {Window} [targetWindow] - Window to send the message to. Defaults to window.parent
 * @param {object} sendData - additional data for the message
 * @param {int} [timeout] - if included, specifies the time (in ms) to wait for a result
 * @param {string} [originOverride] - If included, message will be sent to this origin instead of default
 * @returns {Promise<*>} Promise with recieved data (or throw error)
 */
export function requestResponse<T = any>({
  type,
  targetWindow = window.parent,
  sendData,
  timeout = null,
  originOverride,
}: {
  type: string,
  targetWindow?: Window,
  sendData?: any,
  timeout?: number | null,
  originOverride?: string,
}): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    let timeoutToken: NodeJS.Timeout;
    const actionCb = (data: {
      res?: T,
      err?: Error,
    }) => {
      if (timeoutToken) {
        clearTimeout(timeoutToken);
      }

      const { res, err } = data;
      if (err) {
        reject(err);
      }

      resolve(res);
    };

    const responseType = _.uniqueId(`${type}_Response_`);
    const cleanup = listenOnce({
      type: responseType,
      actionCb,
      originOverride,
    });

    if (timeout) {
      timeoutToken = setTimeout(() => {
        cleanup();
        reject(new Error(`Timout reached waiting for response of type ${type}`));
      }, timeout);
    }

    sendMessage({
      targetWindow,
      type: createRequestType(type),
      data: {
        data: sendData,
        responseType,
      },
      originOverride,
    });
  });
}

/**
 * Callback to get the response data from a request
 * Can return a Promise if the response is async
 * @callback transformData
 * @param {object} data - The data sent with the request
 * @returns {Promise | object} - Resolves the promise before sending  respons, or immediately respons with the data
 */

/**
 * This API is used to listen for requests from another window, then send responses back.
 * @param {string} type - Key to identity the message
 * @param {transformData} transformData - Callback to get the response data, can be async
 * @param {string} [originOverride] - If included, message will be sent to this origin instead of default
 * @returns {Function} Cleanup function to remove this listener
 */
export function respondToRequests<InType = any, OutType = any>({
  type,
  transformData,
  originOverride,
}: {
  type: string,
  transformData: (data: InType) => OutType|Promise<OutType>,
  originOverride?: string,
}): CleanupFunction {
  const actionCb = ({ data, responseType }: { data: any, responseType: string }, event: MessageEvent<any>) => {
    let transformedDataRes: OutType | Promise<OutType>;
    let transformedDataErr: Error;
    try {
      transformedDataRes = transformData(data);
    } catch (err) {
      transformedDataErr = err;
    }

    const targetOrign = originOverride || getDefaultOrigin();
    const sendData = (dataToSend: {
      res?: OutType,
      err?: Error,
    }) => {
      sendMessage({
        targetWindow: event.source as Window,
        type: responseType,
        data: dataToSend,
        originOverride: targetOrign,
      });
    };

    if (transformedDataErr) {
      // if there was an error, return the error
      sendData({ err: transformedDataErr });
    } else if (_.isFunction((transformedDataRes as any)?.then)) {
      // if thennable, wait for resolve before posting message
      (transformedDataRes as Promise<OutType>).then(res => sendData({ res })).catch(err => sendData({ err }));
    } else {
      sendData({ res: transformedDataRes as OutType });
    }
  };
  const cleanup = listenPersistently({
    type: createRequestType(type),
    actionCb,
    originOverride,
  });

  return cleanup;
}
