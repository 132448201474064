import { useEffect, useRef, useCallback } from 'react';

import { useLoader } from '../context/loading-context';
import { ajaxCall } from '../../../FunctionModules/AjaxHelper/ajax-helper';
import { useDeepObjectMemo } from '../hooks/react-utils';
import { useI18nObj } from '../../../FunctionModules/Localization/i18n';

export const useCallbackAjax = ({
  url,
  data,
  method = 'POST',
  headers,
  skipAutoAddFields = false,
}, showLoadingPanel = true) => {
  const i18n = useI18nObj();
  const loading = useLoader();
  const isRendered = useRef(true);

  const dataMemo = useDeepObjectMemo(data);
  const headerMemo = useDeepObjectMemo(headers);

  // set isRendered.current to false on remove element
  // this prevents updating states while unmounting after unloading
  useEffect(() => () => {
    isRendered.current = false;
  }, []);

  return useCallback(
    () => {
      if (showLoadingPanel) {
        loading(true, i18n.Loading);
      }

      return ajaxCall({
        url,
        data: dataMemo,
        method,
        headers: headerMemo,
        skipAutoAddFields,
      })
        .finally(() => {
          if (isRendered.current && showLoadingPanel) {
            loading(false);
          }
        });
    },
    [dataMemo, headerMemo, loading, method, url, i18n, showLoadingPanel, skipAutoAddFields]
  );
};
