import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';

import { logInfo, logPerf } from './log';
import { globalContext } from '../../FunctionModules/GlobalContext/global-context';

const logEvent = (eventName: string, timings: any, timeOrigin: number, correlationId?: string) => {
  const startTime: number = timings[`${eventName}Start`];
  if (startTime) {
    const stopTime: number = timings[`${eventName}End`];
    logPerf(eventName, `${eventName} Start`, correlationId, undefined, new Date(timeOrigin + startTime));
    if (stopTime) {
      logPerf(eventName, `${eventName} Stop`, correlationId, undefined, new Date(timeOrigin + stopTime), stopTime - startTime);
    }
  }
};

// TODO: this is a quick mitigation for Beacon logs and we will design new sample mechanism for each feature
const SAMPLING_RATE = 0.05;
const IsInSample = _.once(() => Math.random() < SAMPLING_RATE);

const loggedResources: string[] = [];
// This is separated, so it can be ran after dynamic imports as well, and just log the newly imported files (instead of doing all the page perf logging again)
export const logJsFilePerfInfo = () => {
  if ((globalContext.featureName === 'Beacon' || globalContext.featureName === 'Donation') && !IsInSample()) {
    logInfo(`<- Perf logs are omitted due to sampling (SAMPLING_RATE=${SAMPLING_RATE}) ->`);
    return;
  }
  if (performance) {
    const timeOrigin = performance.timeOrigin || (Date.now() - performance.now());
    const jsLoadEvents = _.filter(
      performance?.getEntriesByType('resource'),
      res => res.name.indexOf('/js/pack') !== -1
        && res.name.indexOf('.js') !== -1
        && !_.contains(loggedResources, res.name)
    );

    if (jsLoadEvents.length) {
      _.each(jsLoadEvents, (jsLoadEvent: any) => {
        const jsCorrelationId = uuidv4();
        const url = jsLoadEvent.name;

        logInfo(`script load details ${JSON.stringify(jsLoadEvent)}`, jsCorrelationId, url);

        logInfo(`Script Start timeing: ${jsLoadEvent.fetchStart}`, jsCorrelationId, url);
        logInfo({
          jsResourceEntryFetchStart: jsLoadEvent.fetchStart,
          jsResourceEntryStartTime: jsLoadEvent.startTime,
          jsResourceEntryResponseEnd: jsLoadEvent.responseEnd,
          timeOrigin,
          dateByTimeOrigin: new Date(timeOrigin),
        }, jsCorrelationId, url);
        const eventName = 'Script Fetch';
        logPerf(eventName, `${eventName} Start`, jsCorrelationId, url, new Date(timeOrigin + jsLoadEvent.startTime));
        logPerf(eventName, `${eventName} Stop`, jsCorrelationId, url, new Date(timeOrigin + jsLoadEvent.responseEnd), jsLoadEvent.duration);

        loggedResources.push(jsLoadEvent.name);
      });
    }
  }
};

export const logPagePerfInfo = _.once(() => {
  if ((globalContext.featureName === 'Beacon' || globalContext.featureName === 'Donation') && !IsInSample()) {
    logInfo(`<- Perf logs are omitted due to sampling (SAMPLING_RATE=${SAMPLING_RATE}) ->`);
    return;
  }
  if (performance) {
    const timingEventsArr = performance?.getEntriesByType('navigation');
    const timingEvents = (timingEventsArr && timingEventsArr[0]) as any;
    const timeOrigin = performance.timeOrigin || (Date.now() - performance.now());

    if (timingEvents) {
      logEvent('connect', timingEvents, timeOrigin, globalContext.correlationId);
      logEvent('domContentLoadedEvent', timingEvents, timeOrigin, globalContext.correlationId);
      logEvent('domainLookup', timingEvents, timeOrigin, globalContext.correlationId);
      logEvent('loadEvent', timingEvents, timeOrigin, globalContext.correlationId);
      logEvent('redirect', timingEvents, timeOrigin, globalContext.correlationId);
      if (timingEvents.redirectCount) {
        logInfo(`Redirect Count ${timingEvents.redirectCount}`);
      }
      logEvent('request', timingEvents, timeOrigin, globalContext.correlationId);
      logEvent('response', timingEvents, timeOrigin, globalContext.correlationId);
      logEvent('secureConnection', timingEvents, timeOrigin, globalContext.correlationId);
      logEvent('fetch', timingEvents, timeOrigin, globalContext.correlationId);

      logPerf('dom', 'DOM Complete', undefined, undefined, new Date(timeOrigin + timingEvents.domComplete));
      logPerf('dom', 'DOM Interactive', undefined, undefined, new Date(timeOrigin + timingEvents.domInteractive));

      logInfo(`decodedBodySize ${timingEvents.decodedBodySize}`);
      logInfo(`encodedBodySize ${timingEvents.encodedBodySize}`);
      logInfo(`transferSize ${timingEvents.transferSize}`);
    }

    logJsFilePerfInfo();
  }
});
