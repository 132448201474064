export enum LogLevel {
  Information = 2,
  Warning = 3,
  Error = 4,
  Metric = 5,
}

export type LogData = string | object | Error;

export interface LogEntity {
  logId: string,
  luid: string,
  timestamp: Date,
  data: LogData,
  logLevel: LogLevel,
  extraEntries?: object,
  sessionId?: string,
  scenarioName?: string,
  correlationId?: string,
  url?: string,
  // perf log
  duration?: string,
  eventName?: number,
  isPerf?: boolean,
  pilotFlags?: {},
  // exteral config
  associationId?: string,
  logSeq: number,
}

export enum MarkerNames {
  InitializeStart = 'InitializeStart',
  XPayTileRendered = 'XPayTileRendered',
  XPaySimpleTileRendered = 'XPaySimpleTileRendered',
  DonateButtonClicked = 'DonateButtonClicked',
  PidlRendered = 'PidlRendered',
  HasPIRendered = 'HasPIRendered',
  SubmitClicked = 'SubmitClicked',
  TransactionSettled = 'TransactionSettled',
  TransactionFailed = 'TransactionFailed',
  LoadOneDSCore = 'LoadOneDSCore',
  LoadOneDSWeb = 'LoadOneDSWeb',
  InitializeEnd = 'InitializeEnd',
  BuynowButtonClicked = 'BuynowButtonClicked',
}
