/* eslint-disable max-len */
import { globalConfig } from '../../Shared/Configs/config';
import { exportCardScope, msaAuthEndPoint, redirectCDNUrl, redirectSiteUrl } from '../../Shared/Utils/xpay-env-values';
import { MsaAuthError } from '../ErrorHandling/error-types';
import { listenOnce, MessageTypes } from '../FrameMessageBus';
import { logError, logInfo } from '../Logger/log';
import { createPopup } from '../popup-helper';

import { TokenOptions, TokenOptionToAuthKeyMapping } from './constants';

const { msAppClientId, tickInterval, iframeTokenReceiveTimeLimit } = globalConfig;

let XPayClientName: string;

export const setXPayClientNameForMSAAuth = (name: string) => {
  XPayClientName = name;
};

export { TokenOptions };

export function makePopUpWindowConfig(popupWindowWidth: number, popupWindowHeight: number, useWindowScreenSize: boolean = false) {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  let width;
  let height;
  if (!useWindowScreenSize) {
    width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth || window.screen.width;
    height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight || window.screen.height;
  } else {
    width = window.screen.availWidth;
    height = window.screen.availHeight;
  }

  const systemZoom = width / window.screen.availWidth;
  const left = ((width - popupWindowWidth) / 2 / systemZoom) + dualScreenLeft;
  const top = ((height - popupWindowHeight) / 2 / systemZoom) + dualScreenTop;

  const openWindowConfigs = `
        scrollbars=yes,
        width=${popupWindowWidth / systemZoom},
        height=${popupWindowHeight / systemZoom},
        top=${top},
        left=${left}
        `;
  return openWindowConfigs;
}

const makeState = (type: string, xPayClientName: string = null) => {
  const stateObj = {
    parentOrigin: window.location.origin,
    type,
    XPayClientName: xPayClientName,
  };
  return encodeURIComponent(JSON.stringify(stateObj));
};

const getLoginOrigin = (tokenOption: TokenOptions) => {
  switch (tokenOption) {
    case TokenOptions.PIFDToken:
      return redirectCDNUrl;
    case TokenOptions.RPSToken:
      return 'https://webxtsvc.microsoft.com';
    default:
      return redirectSiteUrl;
  }
};

function makeRPSLoginUrl() {
  const scopes = 'service::www.microsoft.com::MBI_SSL';
  const redirectURI = encodeURIComponent(`${getLoginOrigin(TokenOptions.RPSToken)}/login`);
  return `https://login.microsoftonline.com/consumers/oauth2/v2.0/authorize?client_id=${msAppClientId}&response_type=token&redirect_uri=${redirectURI}&state=${makeState(MessageTypes.RPSToken)}&scope=${encodeURIComponent(scopes)}`;
}
export const rpsLoginUrl = makeRPSLoginUrl();

function makePIListLoginUrl() {
  const scopes = 'PIFD.Export PIFD.Read PIFD.Create PIFD.Update';
  const redirectURI = encodeURIComponent(`${getLoginOrigin(TokenOptions.PIFDToken)}/login`);
  return `${msaAuthEndPoint}?client_id=${msAppClientId}&response_type=token&redirect_uri=${redirectURI}&state=${makeState(MessageTypes.PIListToken)}&scope=${encodeURIComponent(scopes)}`;
}
export const piListLoginUrl = makePIListLoginUrl();

const edgeTippingScope = 'https://edgetippingservice.webxtsvc.microsoft.com/EdgeTipping.Admin';
function makeEdgeTippingLoginUrl() {
  const redirectURI = encodeURIComponent(`${getLoginOrigin(TokenOptions.EdgeTippingToken)}/login`);
  return `${msaAuthEndPoint}?client_id=${msAppClientId}&response_type=token&redirect_uri=${redirectURI}&state=${makeState(MessageTypes.EdgeTippingToken)}&scope=${encodeURIComponent(edgeTippingScope)}`;
}
export const edgeTippingLoginUrl = makeEdgeTippingLoginUrl();

function makeEdgeTippingHistoryLoginUrl(params: Record<string, string> = {}) {
  const url = new URL(`${redirectSiteUrl}/edgeTippingHistory`);
  Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value));
  const redirectURI = encodeURIComponent(url.toString());
  return `${msaAuthEndPoint}?client_id=${msAppClientId}&response_type=token&redirect_uri=${redirectURI}&state=${makeState(MessageTypes.EdgeTippingToken)}&scope=${encodeURIComponent(edgeTippingScope)}`;
}
export const getEdgeTippingHistoryLoginUrl = (params: Record<string, string>) => makeEdgeTippingHistoryLoginUrl(params);

function makeExportCardLoginUrl() {
  const redirectURI = encodeURIComponent(`${getLoginOrigin(TokenOptions.ExportCardToken)}/login`);
  return `${msaAuthEndPoint}?client_id=${msAppClientId}&response_type=token&redirect_uri=${redirectURI}&state=${makeState(MessageTypes.ExportToken)}&scope=${exportCardScope}`;
}
export const exportCardLoginUrl = makeExportCardLoginUrl();

export function makeOpenIdLoginUrl(clientId: string) {
  const scopes = 'openid';
  const redirectURI = `${getLoginOrigin(TokenOptions.IdToken)}/login`;
  return `${msaAuthEndPoint}?client_id=${msAppClientId}&response_type=id_token&redirect_uri=${redirectURI}&state=${makeState(MessageTypes.OpenIdToken, XPayClientName)}&scope=${encodeURIComponent(scopes)}&nonce=${clientId}&response_mode=form_post`;
}

export function extractToken(hashContent: string) {
  if (hashContent) {
    try {
      const keyValuePairs = hashContent.split('&');
      let token = '';
      for (const pair of keyValuePairs) {
        const [key, value] = pair.split('=');
        if (key === 'access_token') {
          token = decodeURIComponent(value);
          break;
        }
      }
      return token;
    } catch (err) {
      logInfo(`Extract token failed from content: ${hashContent}`);
      return '';
    }
  }
  return '';
}

function addIframe(containerId: string, initialURL: string) {
  const iframe = document.createElement('iframe');
  iframe.src = initialURL;
  iframe.style.display = 'none';
  iframe.width = '1px';
  iframe.height = '1px';
  iframe.title = 'Authorize';
  const iframeContainer = document.getElementById(containerId);
  iframeContainer.appendChild(iframe);
  return iframe;
}

export const makeMsaAuthUrl = (tokenOption: TokenOptions, clientId = '') => {
  switch (tokenOption) {
    case TokenOptions.IdToken:
      return makeOpenIdLoginUrl(clientId);
    case TokenOptions.PIFDToken:
      return piListLoginUrl;
    case TokenOptions.ExportCardToken:
      return exportCardLoginUrl;
    case TokenOptions.EdgeTippingToken:
      return edgeTippingLoginUrl;
    case TokenOptions.RPSToken:
      return rpsLoginUrl;
    default:
      return null;
  }
};

export const createListenerPromise = async (tokenOption: TokenOptions, isSilentAuth: boolean, setCleanupMethod: Function): Promise<any> => {
  let logKey;
  let messageType: string;
  let parseToken: (_: {result?: string}) => string;
  switch (tokenOption) {
    case TokenOptions.IdToken:
      logKey = 'Id';
      messageType = MessageTypes.OpenIdToken;
      parseToken = ({ result }) => result;
      break;
    case TokenOptions.PIFDToken:
      logKey = 'PIList';
      messageType = MessageTypes.PIListToken;
      parseToken = ({ result }) => extractToken(result);
      break;
    case TokenOptions.ExportCardToken:
      logKey = 'ExportCard';
      messageType = MessageTypes.ExportToken;
      parseToken = ({ result }) => extractToken(result);
      break;
    case TokenOptions.EdgeTippingToken:
      logKey = 'EdgeTippingAuth';
      messageType = MessageTypes.EdgeTippingToken;
      parseToken = ({ result }) => extractToken(result);
      break;
    case TokenOptions.RPSToken:
      logKey = 'RPS';
      messageType = MessageTypes.RPSToken;
      parseToken = ({ result }) => {
        const token = extractToken(result);
        return token ? `WLID1.0=${extractToken(result)}` : '';
      };
      break;
    default:
      return Promise.reject(new MsaAuthError('Invalid token option'));
  }
  try {
    const result = await new Promise((resolve, reject) => {
      const cleanupMethod = listenOnce({
        type: messageType,
        actionCb: (data: {err?: Error, result?: string}) => {
          logInfo(`mas auth listener, error: ${data?.err} type: ${messageType}`);
          if (data.err) {
            reject(data.err);
          }

          try {
            resolve(parseToken(data));
          } catch (err) {
            reject(err);
          }
        },
        originOverride: getLoginOrigin(tokenOption),
      });
      if (setCleanupMethod) {
        setCleanupMethod(cleanupMethod);
      }
    });

    if (isSilentAuth) {
      logInfo(`Silently get ${logKey} token succeed`);
    }

    return result;
  } catch (err) {
    const scenario = isSilentAuth ? 'MSA-Silent-Auth-Failed' : 'MSA-Auth-Failed';
    logError({ err, scenario });
    if (isSilentAuth) {
      logInfo(`Silently get ${logKey} token failed`);
    }
    throw err;
  }
};

interface PopupConfig {
  popupConfig?: string;
  containerId?: string;
}

type TokenType = 'clientAuthToken' | 'PIListToken' | 'exportCardToken' | 'RPSToken';

const doMsaAuth = async (tokenOptions: TokenOptions[], clientId: string = '', { popupConfig, containerId } : PopupConfig): Promise<AuthObject> => {
  const usePopup = popupConfig;
  let msaAuthWindowOrIframe: any;
  const cleanupMethods: Function[] = [];


  const addClenupMethod = (cleanupMethod: Function) => {
    cleanupMethods.push(cleanupMethod);
  };
  const timeoutRejects: Function[] = [];
  let popupPromise: Promise<any>;
  const getToken = (tokenOption: TokenOptions) => new Promise<string>(async (tokenResolve, tokenReject) => {
    timeoutRejects.push(tokenReject);
    const url = makeMsaAuthUrl(tokenOption, clientId);
    const listenerPromise = createListenerPromise(tokenOption, !usePopup, addClenupMethod);

    if (msaAuthWindowOrIframe) {
      if (usePopup) {
        msaAuthWindowOrIframe.location.href = url;
      } else {
        msaAuthWindowOrIframe.contentWindow.location.href = url;
      }
    } else if (usePopup) {
      const { windowHandle, closeWindow, promise } = createPopup({
        url,
        target: 'Microsoft Login',
        popupConfig,
      });
      popupPromise = promise;
      msaAuthWindowOrIframe = windowHandle;
      cleanupMethods.push(closeWindow);
    } else {
      // TODO: always do this when !usePopup, with locally scoped variable instead of msaAuthWindowOrIframe
      // This will allow for the silent auth to happen in parallel instead of series
      msaAuthWindowOrIframe = addIframe(containerId, url);
      let ticks = 0;
      const maxTicks = iframeTokenReceiveTimeLimit / tickInterval;

      const intervalId = setInterval(() => {
        if (ticks > maxTicks) {
          for (let i = 0; i < timeoutRejects.length; i += 1) {
            // reject all open promises. If they have already resolved, a reject will be a noop
            timeoutRejects[i](new MsaAuthError('Iframe Auth Timeout'));
          }
        }
        ticks += 1;
      }, tickInterval);
      cleanupMethods.push(() => {
        msaAuthWindowOrIframe?.parentNode?.removeChild(msaAuthWindowOrIframe);
        clearInterval(intervalId);
      });
    }

    if (popupPromise) {
      popupPromise.catch(tokenReject);
    }

    try {
      tokenResolve(await listenerPromise);
    } catch (err) {
      tokenReject(err);
    }
  });
  const returnObj: AuthObject = {};

  // function factory
  const createAuthFunc = (tokenOption: TokenOptions) => {
    const key: TokenType = TokenOptionToAuthKeyMapping[tokenOption as keyof typeof TokenOptionToAuthKeyMapping] as TokenType;
    return key
      ? (async () => {
        returnObj[key] = await getToken(tokenOption);
      })
      : null;
  };

  const authFunctions = tokenOptions.map(createAuthFunc);

  return new Promise(async (resolve, reject) => {
    // TODO: when !usePopup, run in parallel instead:
    // await Promise.all(authFunctions.map(authFunc => authFunc()));
    try {
      for (let i = 0; i < authFunctions.length; i += 1) {
        if (authFunctions) {
          // eslint-disable-next-line no-await-in-loop
          await authFunctions[i]();
        }
      }
      resolve(returnObj);
    } catch (err) {
      reject(err);
    }
  }).catch((err) => {
    // eslint-disable-next-line no-param-reassign
    err.authObj = returnObj;
    throw err;
  }).finally(() => {
    for (let i = 0; i < cleanupMethods.length; i += 1) {
      cleanupMethods[i]();
    }
  });
};


interface AuthObject {
  clientAuthToken?: string;
  PIListToken?: string;
  exportCardToken?: string;
  RPSToken?: string;
}

export const trySilentMSAuth = async (containerId: string, tokenOptions: TokenOptions[], clientId: string = '', rejectOnError: boolean = false, rejectOnPartialError: boolean = false): Promise<AuthObject> => {
  try {
    return doMsaAuth(tokenOptions, clientId, { containerId });
  } catch (err) {
    const { authObj } = err;
    if (rejectOnError) {
      // if there is an auth object, and we want to reject only if no tokens were recieved
      if (!rejectOnPartialError && authObj) {
        let shouldThrow = true;
        // eslint-disable-next-line no-restricted-syntax
        for (const authKey in Object.values(TokenOptionToAuthKeyMapping)) {
          if (authObj[authKey]) {
            shouldThrow = false;
            break;
          }
        }
        if (shouldThrow) {
          throw err;
        }
      } else {
        throw err;
      }
    }
    return authObj;
  }
};

export const openMsaAuthWindow = async (popupConfig: string, tokenOptions: TokenOptions[], clientId: string = ''): Promise<AuthObject> => {
  logInfo('open MSA auth window');
  return doMsaAuth(tokenOptions, clientId, { popupConfig });
};
