// This component is becoming over complicated and need to be refactored
// Temporarily disable the following eslint rule before the refactoring
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import _ from 'underscore';
import { createUseStyles } from 'react-jss';
import { usePiList } from '../managers/pi-list-manager';
import { CvvInput } from '../cvv-input';
import { PiPicker } from './component';
import { getDefaultPI, getSelectedPiId } from './utils';
import PI_TYPE from '../../Entities/pi-types';
import { getPiPickerForSubscriptionStyles } from './style';
import { useTheme } from '../../../FunctionModules/Themes/theme-context';
import { ShowLoaderComponent } from '../show-loader-component';
import { logPerf } from '../../../FunctionModules/Logger/log';
import { MarkerNames } from '../../../FunctionModules/Logger/types';
import { isFirefox } from '../../../FunctionModules/BrowserDetection/is-firefox';

const isCvvValid = (pi, cvvLength) => {
  switch (pi.PaymentType) {
    case PI_TYPE.CARD:
      return pi.IsTokenized
      || (cvvLength === 4 && pi.CardType.toLowerCase() === 'amex')
      || cvvLength === 3;
    default:
      return false;
  }
};

const isPiValid = (pi, cvvLength) => {
  switch (pi.PaymentType) {
    case PI_TYPE.CARD:
      return isCvvValid(pi, cvvLength);
    case PI_TYPE.PAYPAL:
      return true;
    default:
      return false;
  }
};

export const usePiPicker = ({
  selectPi = _.noop,
  defaultUseDropdown = false,
  creditCardEnabled = true,
  paypalEnabled = false,
  cvvPlaceholder,
  cvvLabelName,
  PiPickerLabelName,
  rightAlign = false,
  additionalInfo,
  piFilter = null,
  customFilter = null,
  allowedTokenProviders = [],
  customStyles = null,
  onCustomizedRenderOption = undefined,
  onCustomizedRenderTitle = undefined,
} = {}) => {
  const [pi, setPi] = useState();
  const [cvv, setCvv] = useState('');
  const {
    isLoading,
    piList,
    error,
    reset,
  } = usePiList(creditCardEnabled, paypalEnabled, false, allowedTokenProviders, customFilter);
  const [key, setKey] = useState();
  const theme = useTheme();
  const useStyles = createUseStyles(customStyles && customStyles.cvvInput ? customStyles : getPiPickerForSubscriptionStyles(theme));
  const cvvStyles = useStyles();


  const updatePi = useCallback((item) => {
    const isAddPI = item.key === PI_TYPE.CARD || item.key === PI_TYPE.PAYPAL;

    selectPi(isAddPI ? undefined : item);
    setPi(isAddPI ? undefined : item);
    setCvv('');
    setKey(item.key);
  }, [selectPi]);

  const onChange = useCallback((_event, item) => {
    updatePi(item);
  }, [updatePi]);

  const prevPiId = useMemo(() => getSelectedPiId(piList, additionalInfo), [additionalInfo, piList]);
  const isPrevPiInvalid = prevPiId === undefined || prevPiId === null;

  useEffect(() => {
    if (!piList || !_.any(piList)) {
      updatePi({ key: PI_TYPE.CARD });
    } else {
      _.once(() => {
        performance.mark(MarkerNames.HasPIRendered);
        if (performance.getEntriesByName(MarkerNames.DonateButtonClicked).length &&
        !isFirefox()) {
          logPerf(
            'ClickToExistingPIRendered', 'Banner click to existing PI rendered duration', null, null, new Date(),
            performance.measure('ClickToExistingPIRendered', MarkerNames.DonateButtonClicked, MarkerNames.HasPIRendered).duration
          );
        }
      })();

      const defaultPi = getDefaultPI(piList, prevPiId, piFilter);
      if (defaultPi) {
        updatePi(defaultPi);
      } else {
        updatePi({ key: PI_TYPE.CARD });
      }
    }
  }, [piFilter, piList, prevPiId, updatePi]);

  useEffect(() => {
    if (error) {
      updatePi({ key: PI_TYPE.CARD });
    }
  }, [error, updatePi]);


  const piDetails = useMemo(
    () => {
      switch (pi?.PaymentType) {
        case PI_TYPE.CARD: {
          return ({
            PiId: pi.Id,
            XPayId: pi.XPayId,
            IsTokenized: pi.IsTokenized,
            CVV: cvv,
            Name: pi.Name,
            Line1: pi.Line1,
            Line2: pi.Line2,
            City: pi.City,
            PostCode: pi.PostCode,
            ExpiryMonth: pi.ExpiryMonth,
            ExpiryYear: pi.ExpiryYear,
            State: pi.State,
            CardType: pi.CardType,
            CardTypeDisplayLogoUrl: pi.CardTypeDisplayLogoUrl,
            CardTypeDisplayName: pi.CardTypeDisplayName,
            Last4CardNumber: pi.CardNumber,
            Country: pi.Country,
          });
        }
        case PI_TYPE.PAYPAL: {
          return ({
            PiId: pi.Id,
            XPayId: pi.XPayId,
            Email: pi.Email,
          });
        }
        default:
          return {};
      }
    },
    [pi, cvv]
  );

  const isValid = useMemo(
    () => !pi || isPiValid(pi, cvv.length),
    [cvv.length, pi]
  );

  const isCvvLengthValid = useMemo(() => !pi || isCvvValid(pi, cvv.length), [cvv.length, pi]);

  if (isLoading) {
    return {
      isValid,
      isLoading,
      error,
      piPicker: <ShowLoaderComponent />,
      showPiAddForm: false,
      pidlSelector: null,
      cvvInput: null,
      piDetails: {},
      reset,
    };
  }

  let addPiType;
  const showPiAddForm = key === PI_TYPE.CARD || key === PI_TYPE.PAYPAL;
  if (showPiAddForm) {
    addPiType = key;
  }

  return {
    isValid,
    isCvvLengthValid,
    isLoading,
    error,
    piPicker: <PiPicker
      defaultUseDropdown={defaultUseDropdown}
      onChange={onChange}
      piList={piList}
      creditCardEnabled={creditCardEnabled}
      paypalEnabled={paypalEnabled}
      selectedPiId={prevPiId}
      labelName={PiPickerLabelName}
      piFilter={piFilter}
      customStyles={customStyles}
      onCustomizedRenderOption={onCustomizedRenderOption}
      onCustomizedRenderTitle={onCustomizedRenderTitle}
    />,
    showPiAddForm,
    isPrevPiInvalid,
    cvvInput: (pi?.PaymentType === PI_TYPE.CARD && !pi.IsTokenized) ? (
      <CvvInput
        className={cvvStyles.cvvInput}
        setCvv={setCvv}
        value={cvv}
        labelName={cvvLabelName}
        placeholderName={cvvPlaceholder}
        rightAlign={rightAlign}
      />
    ) : null,
    piDetails,
    addPiType,
    piList,
    reset,
  };
};
