import _ from 'underscore';
import URI from 'urijs';
import { v4 as uuidv4 } from 'uuid';
import { globalContext } from '../../FunctionModules/GlobalContext/global-context';
import { LogData, LogLevel } from './types';
import { logger as oneDSLogger } from './1ds-logger';
import { EventCounterNames, MetricType } from './metrics';

export {
  LogLevel,
};

export const getAbsoluteUrl = (url: string) => {
  const uri = URI(url);
  uri.hash('');
  if (uri.is('absolute')) {
    return uri.toString();
  }
  return uri.absoluteTo(window.location.origin).toString();
};

export const stringifyMessage = (message: LogData) => {
  if (_.isString(message)) {
    return message;
  }

  if (_.isError(message)) {
    return JSON.stringify({
      message: message.message,
      stack: message.stack,
    });
  }

  return JSON.stringify(message);
};

let logCounter: number = 0;
const getLogSequence = () => {
  logCounter += 1;
  return logCounter;
};

const addToQueue = (
  logLevel: LogLevel,
  message: LogData,
  correlationId?: string,
  url?: string,
  timestamp = new Date(),
  extraEntries: object = {}
) => {
  const logSeq = getLogSequence();
  const logId = `${logSeq}`;
  const entity = _.extend(
    {
      logId, // TODO: remove when client side logs are fully migrated to 1ds
      logSeq,
      luid: uuidv4(), // log unique id for precise comparison/validation
      timestamp,
      data: stringifyMessage(message),
      logLevel,
    },
    extraEntries,
    globalContext.accountId && { accountId: globalContext.accountId },
    globalContext.sessionId && { sessionId: globalContext.sessionId },
    globalContext.scenarioName && { scenarioName: globalContext.scenarioName },
    { pilotFlags: JSON.stringify(globalContext.pilotFlags) },
    correlationId && { correlationId },
    url && { url: getAbsoluteUrl(url) }
  );

  oneDSLogger(entity);

  if (process.env.CONSOLE_LOG_FROM_LOGGER) {
    switch (logLevel) {
      case LogLevel.Error:
        console.error('XPAY_LOG_ERROR', JSON.stringify(entity), entity);
        break;
      case LogLevel.Information:
        console.debug('XPAY_LOG_INFO', JSON.stringify(entity), entity);
        break;
      case LogLevel.Warning:
        console.warn('XPAY_LOG_WARNING', JSON.stringify(entity), entity);
        break;
      default:
        console.log('XPAY_LOG_LOG', JSON.stringify(entity), entity);
        break;
    }
  }
};

export const logInfo = (message: LogData, correlationId?: string, url?: string) => {
  addToQueue(LogLevel.Information, message, correlationId, url || (window.document?.URL ?? ''));
};

export const logError = (message: LogData, correlationId?: string, url?: string) => {
  addToQueue(LogLevel.Error, message, correlationId, url);
};

export const logWarning = (message: LogData, correlationId?: string, url?: string) => {
  addToQueue(LogLevel.Warning, message, correlationId, url);
};

export const logPerf = (
  eventName: string,
  message: LogData,
  correlationId?: string,
  url?: string,
  timestamp = new Date(),
  duration?: number,
  isPerf = true
) => {
  addToQueue(LogLevel.Information, message, correlationId, url, timestamp, {
    eventName,
    duration: _.isNumber(duration) ? Math.round(duration) : duration,
    isPerf,
  });
};

export const logEvent = (
  eventName: string,
  message: LogData,
  correlationId?: string,
  url?: string,
  timestamp = new Date(),
  duration?: number
) => {
  logPerf(eventName, message, correlationId, url, timestamp, duration, false);
};

export const logMetrics = (Metric: MetricType, dimensions: (string|number)[], value?: number) => {
  const message = { value, ..._.object(Metric.dimensions, dimensions) };
  addToQueue(LogLevel.Metric, message, '', window.document?.URL ?? '', new Date(), { eventName: Metric.name });
};

export const logEventCounter = (counterName: EventCounterNames, value?: number) => {
  addToQueue(LogLevel.Metric, `${value ?? 1}`, '', window.document?.URL ?? '', new Date(), { eventName: counterName });
};

export interface PerfInterface {
  stop: Function,
}
export const startPerf = (eventName: string, correlationId?: string, url?: string) : PerfInterface => {
  const startTime = new Date();
  const performanceStartTime = performance?.now();
  logPerf(eventName, `${eventName} Start`, correlationId, url, startTime);

  return {
    stop: _.once(() => {
      const endTime = new Date();
      const performanceEndTime = performance?.now();
      const duration = endTime.getTime() - startTime.getTime();
      const performanceDuration = performanceEndTime && performanceEndTime - performanceStartTime;

      logPerf(eventName, {
        message: `${eventName} Stopped`,
        performanceDuration,
      }, correlationId, url, endTime, duration);
    }),
  };
};
