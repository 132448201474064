import { getDefaultOrigin } from './origin-override';

/**
 * This API is used to send a message to another window.
 * @param {Window} [targetWindow] - Window to send the message to. Defaults to window.parent
 * @param {string} type - Key to identify the message
 * @param {object} data - Additional data included in the message
 * @param {string} [originOverride] - If included, message will be sent to this origin instead of default
 * @returns {void}
 */
export const sendMessage = ({ // Label as Pure for tree shaking
  targetWindow = window.parent,
  type,
  data,
  originOverride,
}: {
  targetWindow?: Window,
  type: string,
  data?: any,
  originOverride?: string
}): void => {
  let processedOverride = originOverride;
  if (originOverride && originOverride.startsWith('file://')) {
    processedOverride = getDefaultOrigin();
  }
  targetWindow.postMessage({
    data,
    type,
  }, processedOverride || getDefaultOrigin());
};
