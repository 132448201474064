import { useGetData } from '../context/data-store-context';
import { useCallbackAjax } from './callback-ajax-hook';

export const useImmediateAjaxWithDataStore = (key, params, showLoadingPanel = true, skip = false) => {
  const callback = useCallbackAjax(params, showLoadingPanel);

  return useGetData(
    key,
    callback,
    skip
  );
};
