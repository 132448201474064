import _ from 'underscore';
import React, { createContext, memo, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { setCorrelationId } from '../../../FunctionModules/Logger/log-uploader';
import { setDefaultOrigin } from '../../../FunctionModules/FrameMessageBus/origin-override';
import { initGlobalContext } from '../../../FunctionModules/GlobalContext/global-context';

const { serverContext } = window;
const GlobalReactContext = createContext(serverContext || {});
window.serverContext = undefined;

initGlobalContext(serverContext);

const updatesOnContextChanges = {
  correlationId: setCorrelationId,
  origin: setDefaultOrigin,
};

export const GlobalReactContextPropType = PropTypes.shape({
  locale: PropTypes.string.isRequired,
  currencyCode: PropTypes.string,
  theme: PropTypes.string,
});

export const useGlobalReactContext = () => useContext(GlobalReactContext);

export const GlobalReactContextProvider = memo(({ children, context }) => {
  // if no provided context value, use default context (set from window.serverContext)
  const defaultContext = useContext(GlobalReactContext);
  const providedContext = context || defaultContext;

  // set values this way instead of in a useEffect, so that they are called in the first render
  // instead of asynchronously afterwards
  const setterRef = useRef({});
  const callOnValueUpdate = (key, action) => {
    const value = providedContext[key];
    if (setterRef.current[key] !== value && value) {
      setterRef.current[key] = value;
      action(value);
    }
  };
  _.each(updatesOnContextChanges, (action, key) => {
    callOnValueUpdate(key, action);
  });

  return (
    <GlobalReactContext.Provider value={providedContext}>
      {children}
    </GlobalReactContext.Provider>
  );
});

GlobalReactContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  context: GlobalReactContextPropType,
};
GlobalReactContextProvider.defaultProps = {
  context: null,
};
GlobalReactContextProvider.displayName = 'GlobalReactContextProvider';
