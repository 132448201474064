export const MessageTypes = {
  AjaxMessage: 'IframeAjaxMessage',
  IsLoaded: 'AjaxIframeIsLoaded',
  ServerError: 'IFrameServerError',
};

export const AjaxIframeProperties = {
  name: 'xpay-ajax-frame',
  id: 'xpay-ajax-frame',
};
