/**
 * Enum for Tokens to fetch
 * @readonly
 * @enum {TokenOptions}
 */
export const TokenOptions = Object.freeze({
  /** @member {number} */
  /** Token for exporting card */
  ExportCardToken: 0,
  /** @member {number} */
  /** token for fetching/adding PIs through PIFD */
  PIFDToken: 1,
  /** @member {number} */
  /** Token for Authorization */
  IdToken: 2,
  /** @member {number} */
  /** Token for Edge Tipping */
  EdgeTippingToken: 3,
  /** @member {number} */
  /** Token for Store Cart Data Service */
  RPSToken: 4,
});

export const TokenOptionToAuthKeyMapping = Object.freeze({
  [TokenOptions.ExportCardToken]: 'exportCardToken',
  [TokenOptions.PIFDToken]: 'PIListToken',
  [TokenOptions.IdToken]: 'clientAuthToken',
  [TokenOptions.EdgeTippingToken]: 'edgeTippingToken',
  [TokenOptions.RPSToken]: 'RPSToken',
});
