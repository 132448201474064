import { useCallback } from 'react';
import { useAuthorization, useUpdateAuthorization } from '../context/authZ-context';
import { openMsaAuthWindow, TokenOptions } from '../../../FunctionModules/MSALogin/msa-auth-utils';
import { MsaAuthError } from '../../../FunctionModules/ErrorHandling/error-types';
import { getCardDetail } from '../../../BusinessLogic/EnrollDevice/enrollDevice';
import { useXpayDomainAjaxCall } from '../../../FunctionModules/AjaxHelper/iframe-ajax/index';
import { logInfo } from '../../../FunctionModules/Logger/log';


export const useExportPi = (popupConfig, xpayScenario = null) => {
  const iframeAjaxCall = useXpayDomainAjaxCall();
  const { pidlAuthZ, exportAuthZ } = useAuthorization();
  const { exportAuthZ: setExportAuthZ } = useUpdateAuthorization();

  return useCallback(
    async (piid, cvv) => {
      // if export token missing, get token, then call export
      let exportCardToken;
      if (exportAuthZ) {
        exportCardToken = exportAuthZ;
      } else {
        try {
          logInfo('Prompt user to login for exporting card');
          ({ exportCardToken } = await openMsaAuthWindow(popupConfig, [TokenOptions.ExportCardToken]));
          setExportAuthZ(exportCardToken);
        } catch {
          logInfo('User to login failed for exporting card');
          throw new MsaAuthError('Microsoft Account Login Failed. Please try again.');
        }
      }
      return getCardDetail(iframeAjaxCall, piid, cvv, pidlAuthZ, exportCardToken, xpayScenario);
    },
    [exportAuthZ, iframeAjaxCall, pidlAuthZ, popupConfig, setExportAuthZ, xpayScenario]
  );
};
