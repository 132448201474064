import React, { memo, useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { StringsType } from '../migration';
import { I18nContext, GetStringType } from '.';

import { getNewI18nWithFallbackToEn } from '../strings';
import getStringBase from '../get-string';
import { useGlobalReactContext } from '../../../Shared/Components/context/global-react-context';

export const NewI18nContextProvider = memo(({ children }) => {
  const [i18n, setI18n] = useState<StringsType>();
  const { locale, onI18nLoaded } = useGlobalReactContext();

  const getString: GetStringType = useCallback((...args: [keyof StringsType, any?, any?]) => getStringBase(i18n, ...args), [i18n]);

  useEffect(() => {
    getNewI18nWithFallbackToEn(locale).then(fetchedI18n => setI18n(fetchedI18n));
  }, [locale]);

  useEffect(() => {
    if (i18n) {
      onI18nLoaded?.();
    }
  }, [i18n, onI18nLoaded]);

  if (!i18n) {
    return null;
  }

  return (
    <I18nContext.Provider value={{ i18n, getString }}>
      <IntlProvider messages={i18n} locale={locale} >
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  );
});

NewI18nContextProvider.displayName = 'NewI18nContextProvider';

const I18nContextProvider = NewI18nContextProvider;

export default I18nContextProvider;
