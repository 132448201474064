import * as React from 'react';
import { useMemo } from 'react';
import { Link } from '@fluentui/react';
import { useI18n } from '../../../FunctionModules/Localization/i18n';
import { ReactTemplate } from '../../../Shared/Components/react-template';

const TermsAgreementModel = () => {
  const model = useMemo(() => ({
    Terms:
    (linkText: string) => (<Link id="XPAY_MsTerms" href="https://www.microsoft.com/bing/rebates-terms" target="_blank" rel="noopener noreferrer" underline>{linkText}</Link>),
    Privacy:
    (linkText: string) => (<Link id="XPAY_MsPrivacy" href="https://privacy.microsoft.com/privacystatement" target="_blank" rel="noopener noreferrer" underline>{linkText}</Link>),
  }), []);
  return model;
};

export const TermsAgreement = () => {
  const { i18n } = useI18n();
  const model = TermsAgreementModel();
  return (
    <span>
      <ReactTemplate
        model={model}
        template={i18n.CloTerms}
      />
    </span>
  );
};

