import React from 'react';
import PropTypes from 'prop-types';
import { createTheming } from 'react-jss';
import { Customizer } from '@fluentui/react';

import { useGlobalReactContext } from '../../Shared/Components/context/global-react-context';
import { getThemeObj, getScopedSettings } from './theme-registry';

const ThemeContext = React.createContext({});

const { ThemeProvider, useTheme } = createTheming(ThemeContext);

export const XpayThemeProvider = ({ children }) => {
  const { theme: themeName } = useGlobalReactContext();
  const themeObj = getThemeObj(themeName);

  // Note: Fluent UI will deprecate Customizer in favor of ThemeProvider starting v8
  const scopedSettings = getScopedSettings();
  const customizedChildren = !scopedSettings
    ? children
    : (
      <Customizer scopedSettings={scopedSettings}>
        {children}
      </Customizer>
    );

  return (
    <ThemeProvider theme={themeObj}>
      {customizedChildren}
    </ThemeProvider>
  );
};

XpayThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

XpayThemeProvider.displayName = 'ThemeProvider';

export { useTheme };
