import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import { getUrlPilotString } from '../PilotManager/url-piloting';
import { useI18nObj } from '../Localization/i18n';
import { addSpinnerToIframe } from './iframe-script';
import { useAuthorization } from '../../Shared/Components/context/authZ-context';
import { globalContext } from '../../FunctionModules/GlobalContext/global-context';
import { siteUrl, xpayEnv } from '../../Shared/Utils/xpay-env-values';

const IframeWrapper = forwardRef(({
  wrapperUrl,
  iframeStyles,
  iframeTitle,
  iframeProperties,
  formParameterDict,
  componentName,
  bundleEntryFile,
  showLoadingIconInitially,
  xpayClientName,
  skipAddingTokens,
}, iframeRef) => {
  const { pidlAuthZ, exportAuthZ, xpayAuthZ } = useAuthorization();
  const submitFormRef = useRef();
  const i18n = useI18nObj();
  const iframeBackupRef = useRef();

  const ref = iframeRef || iframeBackupRef;
  // on unmount, unset iframeRef
  useEffect(() => (() => {
    ref.current = null;
  }), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showLoadingIconInitially) {
      addSpinnerToIframe(ref.current, i18n);
    }
  }, [i18n, ref, showLoadingIconInitially]);

  const url = useMemo(
    () => {
      const baseUrl = `${siteUrl}${wrapperUrl}${globalContext.noIframePilots ? '' : `?${getUrlPilotString()}`}`;

      if (skipAddingTokens) {
        return baseUrl;
      }

      return `${baseUrl}#${exportAuthZ
        ? `exportToken=${encodeURIComponent(exportAuthZ)}&`
        : ''}pidlToken=${encodeURIComponent(pidlAuthZ)}`;
    },
    [wrapperUrl, skipAddingTokens, exportAuthZ, pidlAuthZ]
  );

  // submit file, resubmit if url/componentName/bundleEntryFile changes
  useEffect(() => {
    submitFormRef.current.submit();
  }, [url, componentName, bundleEntryFile]);

  const formParameterDictWithContext = _.extend(
    {},
    formParameterDict,
    { sessionId: globalContext.sessionId },
    { hostPageId: globalContext.hostPageId },
    { scenarioName: globalContext.scenarioName },
    { parentEnv: xpayEnv }
  );

  return (
    <>
      <iframe
        ref={ref}
        {...iframeProperties}
        title={iframeTitle}
        style={iframeStyles}
      />
      <form
        style={{ display: 'none' }}
        action={url}
        method="POST"
        target={iframeProperties.name}
        name="xpay-form-panel"
        ref={submitFormRef}
      >
        {_.map(formParameterDictWithContext, (value, name) => (
          <input
            key={name}
            name={`parameterDict[${name}]`}
            value={value}
            type="hidden"
          />
        ))}
        <input
          name="Authorization"
          value={xpayAuthZ}
          type="hidden"
        />
        <input
          name="XPayClientName"
          value={xpayClientName}
          type="hidden"
        />
        {componentName && (<input
          name="componentName"
          value={componentName}
          type="hidden"
        />)}
        <input
          name="componentBundleEntryName"
          value={bundleEntryFile}
          type="hidden"
        />
      </form>
    </>
  );
});
IframeWrapper.propTypes = {
  wrapperUrl: PropTypes.string.isRequired,
  iframeStyles: PropTypes.objectOf(PropTypes.any),
  iframeTitle: PropTypes.string,
  iframeProperties: PropTypes.objectOf(PropTypes.any),
  formParameterDict: PropTypes.objectOf(PropTypes.string),
  bundleEntryFile: PropTypes.string.isRequired,
  showLoadingIconInitially: PropTypes.bool,
  xpayClientName: PropTypes.string.isRequired,
  componentName: PropTypes.string,
  skipAddingTokens: PropTypes.bool,
};
IframeWrapper.defaultProps = {
  iframeStyles: {},
  iframeTitle: _.unique('xpay-iframe-wrapper-'),
  iframeProperties: {},
  formParameterDict: {},
  showLoadingIconInitially: true,
  componentName: null,
  skipAddingTokens: false,
};

export default IframeWrapper;
