// TODO: don't let this stay as '*'
let defaultOrigin = '*';

/**
 * This API is used to set the origin messages should be sent to and recieved from.
 * If a message is going to/coming from a different origin,
 * that can be specified explicitly for individual messages/listeners
 * @param {string} origin - origin of other window(s) sending/recieving messages
 * @returns {void}
 */
export const setDefaultOrigin = (origin: string): void => {
  defaultOrigin = origin;
};

/**
 * This API is used to get the origin messages should be sent to and recieved from.
 * If a message is going to/coming from a different origin,
 * that can be specified explicitly for individual messages/listeners
 * @returns {string} origin of other window(s) sending/recieving messages
 */
export const getDefaultOrigin = (): string => defaultOrigin;
