// When adding values here, also add to these files:
// \src\ux\Microsoft.Pay.Web\appsettings.<env>.json or appsettings.json
interface Auth0Config {
  clientId: string;
  domain: string;
}
declare const XPAY_ENV_VAR: {
  XPAYENV: number;
  SITEURL: string;
  RESOURCEURL: string;
  ONEDSKEY: string;
  PIDLENV: string;
  AUTH0CONFIG: Auth0Config;
  TOKENIZATIONENDPOINT: string;
  PIFDENDPOINT: string;
  EXPORTENDPOINT: string;
  PIDLIMAGEURL: string;
  SUBSCRIPTIONURL: string;
  SUBSORCHESTRATORURL: string;
  TRANSACTIONHISTORYURL: string;
  MSAAUTHENDPOINT: string;
  REDIRECTSITEURL: string;
  REDIRECTCDNURL: string;
  EXPORTCARDSCOPE: string;
  KOUNTSDKURL: string;
  AKSHOSTLOGSERVICEENDPOINT: string;
  EDGETIPPINGSERVICEENDPOINT: string;
  EDGETIPPINGUSERPROFILEURL: string;
  EDGETIPPINGUSERPHOTOURL: string;
  ONBOARDINGPORTALURL: string;
  TEAMSGRAPHQLURI: string;
  STRIPE_PK: {[key: string]: string};
  BLUESNAPENDPOINT: string;
  BUYNOWSERVICEENDPOINT: string;
  BUYNOWREWARDPOINTS: number;
  BUYNOWREWARDSENABLED: boolean;
  BUYNOWPUBLICSHOPIFYAPIS: boolean;
  CATALOGSERVICEENDPOINT: string;
  FIRMLYVAULTMESANGERURL: string;
  EARNREWARDSPOINTSURL: string;
  MSALAUTHORITYENDPOINTS: string;
  FORUMREDIRECTURL: string;
};

export const xpayEnv = XPAY_ENV_VAR.XPAYENV;
export const siteUrl = XPAY_ENV_VAR.SITEURL;
export const resourceUrl = XPAY_ENV_VAR.RESOURCEURL;
export const oneDSInstrumentationKey = XPAY_ENV_VAR.ONEDSKEY;
export const pidlEnv = XPAY_ENV_VAR.PIDLENV;
export const auth0Config = XPAY_ENV_VAR.AUTH0CONFIG;
export const tokenizationEndpoint = XPAY_ENV_VAR.TOKENIZATIONENDPOINT;
export const pifdEndpoint = XPAY_ENV_VAR.PIFDENDPOINT;
export const exportEndpoint = XPAY_ENV_VAR.EXPORTENDPOINT;
export const pidlImageUrl = XPAY_ENV_VAR.PIDLIMAGEURL;
export const subscriptionUrl = XPAY_ENV_VAR.SUBSCRIPTIONURL;
export const subscriptionOrchestratorUrl = XPAY_ENV_VAR.SUBSORCHESTRATORURL;
export const bingTransactionHistoryUrl = XPAY_ENV_VAR.TRANSACTIONHISTORYURL;
export const msaAuthEndPoint = XPAY_ENV_VAR.MSAAUTHENDPOINT;
export const redirectSiteUrl = XPAY_ENV_VAR.REDIRECTSITEURL;
export const redirectCDNUrl = XPAY_ENV_VAR.REDIRECTCDNURL;
export const exportCardScope = XPAY_ENV_VAR.EXPORTCARDSCOPE;
export const kountSdkUrl = XPAY_ENV_VAR.KOUNTSDKURL;
export const AKSHostLogServiceEndPoint = XPAY_ENV_VAR.AKSHOSTLOGSERVICEENDPOINT;
export const edgeTippingServiceEndPoint = XPAY_ENV_VAR.EDGETIPPINGSERVICEENDPOINT;
export const edgeTippingUserProfileUrl = XPAY_ENV_VAR.EDGETIPPINGUSERPROFILEURL;
export const edgeTippingUserPhotoUrl = XPAY_ENV_VAR.EDGETIPPINGUSERPHOTOURL;
export const onboardingPortalUrl = XPAY_ENV_VAR.ONBOARDINGPORTALURL;
export const teamsGraphqlUri = XPAY_ENV_VAR.TEAMSGRAPHQLURI;
export const stripePublicKey = XPAY_ENV_VAR.STRIPE_PK;
export const blueSnapBaseUrl = XPAY_ENV_VAR.BLUESNAPENDPOINT;
export const buynowServiceUrl = XPAY_ENV_VAR.BUYNOWSERVICEENDPOINT;
export const buynowRewardsEnabled = XPAY_ENV_VAR.BUYNOWREWARDSENABLED;
export const buynowRewardPoints = XPAY_ENV_VAR.BUYNOWREWARDPOINTS;
export const catalogServiceUrl = XPAY_ENV_VAR.CATALOGSERVICEENDPOINT;
export const enableBuynowPublicShopifyApis = XPAY_ENV_VAR.BUYNOWPUBLICSHOPIFYAPIS;
export const firmlyVaultMessangerUrl = XPAY_ENV_VAR.FIRMLYVAULTMESANGERURL;
export const earnPointsUrl = XPAY_ENV_VAR.EARNREWARDSPOINTSURL;
export const msalAuthorityEndPoints = XPAY_ENV_VAR.MSALAUTHORITYENDPOINTS;
export const forumRedirectUrl = XPAY_ENV_VAR.FORUMREDIRECTURL;
