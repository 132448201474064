import React, { createContext, memo, useContext, useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';
import _ from 'underscore';

import { useGlobalReactContext } from './global-react-context';
import { setAuthToken } from '../../../FunctionModules/AjaxHelper/ajax-helper';

const TOKEN_DEFINITIONS = {
  xpayAuthZ: {
    expireMinutes: 6 * 60,
    // transform: addWlid,
    getter: ({ globalReactContext }) => globalReactContext.xpayAuthZToken,
    onUpdate: (val) => {
      setAuthToken(val);
    },
  },
  pidlAuthZ: {
    // expireMinutes: 2 * 60,
    // transform: addWlid,
    getter: ({ hash }) => hash.pidlToken,
  },
  exportAuthZ: {
    expireMinutes: 5,
    // transform: addWlid,
    getter: ({ hash }) => hash.exportToken,
  },
  rpsTicket: {
    expireMinutes: 12 * 60,
    getter: ({ hash }) => hash.rpsTicket,
  },
};

export const wrapInitialAuthZ = authZ => authZ && _.mapObject(authZ, val => ({ val }));

export const AuthZContextPropType = PropTypes.shape(_.mapObject(TOKEN_DEFINITIONS, () => PropTypes.shape({
  val: PropTypes.string,
  expire: PropTypes.number,
})));

const getVal = (nonTransformedVal, transform, expireMinutes, now = Date.now()) => {
  if (nonTransformedVal) {
    const val = _.isFunction(transform)
      ? transform(nonTransformedVal)
      : nonTransformedVal;

    let newExpireDate = null;
    // set to expire in <expireMinutes> minutes for new authZ
    if (_.isNumber(expireMinutes)) {
      const expireDate = new Date(now);
      expireDate.setMinutes(expireDate.getMinutes() + expireMinutes);
      newExpireDate = expireDate.getTime();
    }

    return { val, expire: newExpireDate };
  }
  return { };
};

const getInitialAuthZs = ({ globalReactContext }) => {
  const now = Date.now();
  const { hash: hashStr } = window.location;

  const hash = hashStr.length
    ? URI(`?${hashStr.substring(1)}`).escapeQuerySpace(false).query(true)
    : {};

  return _.mapObject(TOKEN_DEFINITIONS, ({ getter, expireMinutes, transform }) => {
    const nonTransformedVal = getter({ hash, globalReactContext });
    return getVal(nonTransformedVal, transform, expireMinutes, now);
  });
};

export const AuthorizationContext = createContext();

const authZReducer = (existingState, updatedValues) => {
  if (_.all(updatedValues, (value, key) => _.isEqual(existingState[key].val, value))) {
    return existingState;
  }

  const now = Date.now();
  const formattedUpdatedValues = _.mapObject(
    updatedValues,
    (val, key) => getVal(val, null, TOKEN_DEFINITIONS[key].expireMinutes, now)
  );
  return {
    ...existingState,
    ...formattedUpdatedValues,
  };
};

export const AuthorizationContextProvider = memo(({
  children,
  initialAuthZ,
}) => {
  const globalReactContext = useGlobalReactContext();

  const [authZ, updateAuthZ] = useReducer(
    authZReducer,
    undefined, // don't pass an initial value, because we are using the init function instead
    () => {
      const initialAuthzWithAutoFetch = _.defaults(initialAuthZ, getInitialAuthZs({ globalReactContext }));

      // because we are setting the auth values, call any of their update handlers
      _.each(TOKEN_DEFINITIONS, ({ onUpdate }, key) => {
        const { val } = initialAuthzWithAutoFetch[key];
        if (onUpdate && val) {
          onUpdate(val);
        }
      });

      return initialAuthzWithAutoFetch;
    }
  );

  // every minute, check if the tokens are still valid, unset them if they are not
  useEffect(() => {
    if (_.any(authZ, ({ val }) => !_.isUndefined(val))) {
      const timer = setInterval(() => {
        const updatedAuthZ = {};

        _.each(authZ, ({ expire }, authZName) => {
          // check if AuthZ expired
          if (expire && Date.now() > expire) {
            updatedAuthZ[authZName] = null;
          }
        });

        if (!_.isEmpty(updatedAuthZ)) {
          updateAuthZ(updatedAuthZ);
        }
      }, 60000);

      // clearing interval
      return () => clearInterval(timer);
    }
    return undefined;
  }, [authZ]);

  return (
    <AuthorizationContext.Provider
      value={{
        authorizations: useMemo(
          () => _.mapObject(authZ, authZData => (authZData.val === 'undefined' ? undefined : authZData.val)), // TODO: Can't find why some undefined is converted to "undefined". Will spend more time to investigate.
          [authZ]
        ),
        updateAuthorizations: useMemo(() => _.mapObject(
          TOKEN_DEFINITIONS,
          ({ onUpdate }, key) => ((val) => {
            if (onUpdate) {
              onUpdate(val);
            }

            updateAuthZ({ [key]: val });
          })
        ), []),
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
});
AuthorizationContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  initialAuthZ: AuthZContextPropType,
};
AuthorizationContextProvider.defaultProps = {
  initialAuthZ: null,
};
AuthorizationContextProvider.displayName = 'AuthorizationContextProvider';

export const useAuthorization = () => useContext(AuthorizationContext).authorizations;
export const useUpdateAuthorization = () => useContext(AuthorizationContext).updateAuthorizations;
