/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import { globalContext } from '../../FunctionModules/GlobalContext/global-context';
import {
  logError,
  logInfo,
  logPerf,
  logWarning,
  startPerf,
  LogLevel,
} from './log';

export {
  logError,
  logInfo,
  logWarning,
  logPerf,
  startPerf,
  LogLevel,
};

let scenario: string;
let isSubscriptionUserLogin = false;

export const setScenario = (scenarioName: string) => { scenario = scenarioName; };
export const setSubscriptionUserLogin = (isLogin: boolean) => { isSubscriptionUserLogin = isLogin; };

let pageCorrelationId: string;
let sessionId: string;

export const setCorrelationId = (correlationId?: string) => {
  pageCorrelationId = correlationId || uuidv4();
  globalContext.correlationId = correlationId;
};

export const setHostPageId = (pageId?: string) => {
  globalContext.hostPageId = pageId || uuidv4();
};

export const setSessionId = (existingSessionId?: string) => {
  sessionId = existingSessionId || uuidv4();
  globalContext.sessionId = sessionId;
  return sessionId;
};

export const setFlightIds = (flightIds: string[]) => {
  globalContext.flightIds = flightIds ?? [];
};

export const getFlightIds = () => globalContext.flightIds;
export const getHostPageId = () => globalContext.hostPageId;
export const getCorrelationId = () => pageCorrelationId;
export const getScenario = () => scenario;
export const getSubscriptionUserLogin = () => isSubscriptionUserLogin;

export function onBeforeunload() {
  // Temporarily removed the log here to save Aria space. Enable it when there is need to investigate log missing
  // logInfo('onBeforeunload');
}

export function onPageHide() {
  // Temporarily removed the log here to save Aria space. Enable it when there is need to investigate log missing
  // logInfo('onPageHide');
}


export function onVisibilityChange() {
  // Temporarily removed the log here to save Aria space. Enable it when there is need to investigate log missing
  // logInfo('onVisibilityChange');
}

// wrap in function with no params to be compatable with addEventListener signature
export const onUnload = () => {
  // Temporarily removed the log here to save Aria space. Enable it when there is need to investigate log missing
  // logInfo('onUnload');
};

// beforeunload event is triggered when navigation happens.
// unload event and onUnload included for IE compatability,
// visibility change and sendBeacon is the preferred way for best UX.
// unload called after visibility change, and checks the log list is empty to prevent both happening
if (window.addEventListener) {
  window.addEventListener('unload', onUnload, false);
  window.addEventListener('beforeunload', onBeforeunload, false);
  window.addEventListener('visibilitychange', onVisibilityChange, false);
  // Safari support for visibility change
  window.addEventListener('pagehide', onPageHide, false);
}
