/* eslint-disable function-paren-newline */
import { logError } from '../../Logger/log';

const getNewI18nData = (locale = 'en-us') =>
  import(
    /* webpackChunkName: "new-l10n/[request].[index]" */
    /* webpackMode: "lazy" */
    /* webpackPreload: true */
    `../../../lang-dist/${locale.toLowerCase()}.json`
  );

export const getFallbackLocale = (_locale: string) => {
  const locale = _locale?.toLowerCase?.() ?? '';

  switch (locale) {
    case 'en-au': return 'en-gb';
    default: break;
  }

  const simpleLocale = locale.split('-')?.[0];

  switch (simpleLocale) {
    case 'en': return 'en-us';
    case 'ja': return 'ja-jp';
    case 'sv': return 'sv-se';
    case 'ar': return 'ar-eg';
    case 'zh': return 'zh-cn';
    default: return `${simpleLocale}-${simpleLocale}`;
  }
};

export const getNewI18nWithFallbackToEn = (locale: string) => getNewI18nData(locale)
  .catch(() => getNewI18nData(getFallbackLocale(locale)))
  .catch(() => getNewI18nData())
  .catch((err) => {
    console.error(err);
    logError({
      message: `failed to load new i18n for ${locale}`,
      err,
    });
  })
  .then(fetchedI18n => fetchedI18n);
