import _ from 'underscore';

export const ChannelParamName = 'channel';

export const getChannelFromURL = () : string | undefined => {
  if (!window.location) {
    return null;
  }

  const { search } = window.location;

  console.log(`search ${search}`);
  const params = search.replace('?', '').split('&');

  let res;
  _.each(params, (param) : void => {
    const [key, value] = param.split('=');
    if (key === ChannelParamName) {
      res = value;
    }
  });

  return res;
};

