import _ from 'underscore';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useConst } from '@uifabric/react-hooks';

import {
  listenOnce,
  listenPersistently,
  requestResponse,
  respondToRequests,
  sendMessage,
} from '../../../../FunctionModules/FrameMessageBus';
import {
  PidlIframe,
} from '../../../../FunctionModules/IframeWrapper/constants';
import IframeWrapper from '../../../../FunctionModules/IframeWrapper/iframe-wrapper-react';
import {
  ComponentBundleMap,
  sharedIframeWrapperURL
} from '../../../BundlesInIframe/bundle-constants';
import EventTypes, { getCallbackType } from './event-types';
import { useGlobalReactContext } from '../../context/global-react-context';
import { useLoader } from '../../context/loading-context';
import { useDeepObjectMemo } from '../../hooks/react-utils';
import { logError } from '../../../../FunctionModules/Logger/log';
import { useDefaultCreatePaypalPopup } from '../utils';
import { useAuthorization } from '../../context/authZ-context';
import { siteUrl } from '../../../Utils/xpay-env-values';

const iframeBaseStyles = {
  border: 0,
  width: '100%',
};

const IFRAME_WRAPPER_CALLBACK_SETTER_KEYS = ['resetPidlInstance', 'resolve', 'reject'];

const PidlIframeComponent = ({
  country,
  shouldLoadPidl,
  setPidlSubmit,
  setReturnVals,
  callbackSetterKeys,
  iframeRef,
  triggerLoader,
  ...otherProps
}) => {
  const {
    locale,
    theme,
    xpayClientName,
  } = useGlobalReactContext();
  const { pidlAuthZ } = useAuthorization();

  const originOverride = siteUrl;
  const [isIframeLoaded, setIframeIsLoaded] = useState(false);

  const [height, setHeight] = useState('100%');
  const iframeStyles = useMemo(() => ({
    ...iframeBaseStyles,
    height,
  }), [height]);


  const otherPropsDeepMemo = useDeepObjectMemo(otherProps);

  const updateValues = useCallback(() => {
    if (isIframeLoaded && iframeRef.current) {
      sendMessage({
        type: EventTypes.UpdateProps,
        data: {
          shouldLoadPidl,
          triggerLoader: false,
          callbackSetterKeys,
          ...otherPropsDeepMemo,
        },
        targetWindow: iframeRef.current.contentWindow,
        originOverride,
      });
    }
  }, [callbackSetterKeys, isIframeLoaded, originOverride, otherPropsDeepMemo, shouldLoadPidl, iframeRef]);

  useEffect(updateValues, [updateValues]);

  useEffect(() => {
    // if pidl should be loaded, and iframe isn't loaded, wait for the iframe to be loaded
    if (!isIframeLoaded) {
      return listenOnce({
        type: EventTypes.IframeLoaded,
        actionCb: () => {
          updateValues();
          setIframeIsLoaded(true);
        },
        originOverride,
      });
    }
    return undefined;
  }, [isIframeLoaded, originOverride, updateValues]);

  useEffect(() => listenPersistently({
    type: EventTypes.UpdateReturnValues,
    actionCb: setReturnVals,
    originOverride,
  }), [originOverride, setReturnVals]);

  useEffect(() => listenPersistently({
    type: EventTypes.UpdateSize,
    actionCb: (iframeHeight) => {
      setHeight(iframeHeight);
    },
    originOverride,
  }), [originOverride, setHeight]);

  const showLoader = useLoader();

  const setIsLoading = useCallback((isLoading) => {
    if (triggerLoader) {
      showLoader(isLoading);
    }
  }, [showLoader, triggerLoader]);


  const submitPidl = useCallback((...args) => {
    if (!isIframeLoaded || !iframeRef.current) {
      logError('Pidl submit called when Pidl is not loaded');
      return Promise.reject(Error('Pidl is not loaded'));
    }
    setIsLoading(true);
    return requestResponse({
      type: EventTypes.PidlSubmit,
      targetWindow: iframeRef.current.contentWindow,
      sendData: args,
      originOverride,
    }).finally(() => {
      setIsLoading(false);
    });
  }, [isIframeLoaded, originOverride, setIsLoading, iframeRef]);

  useEffect(() => {
    setPidlSubmit(submitPidl);
  }, [submitPidl, setPidlSubmit]);

  const iframeTitle = useConst(_.uniqueId('Pidl_Iframe'));

  const { current: formParameterDict } = useRef({
    locale,
    origin: window.location.origin,
    theme,
    country,
  });

  // if we have the pidl auth token, load the pidl iframe
  return Boolean(pidlAuthZ) && (
    <IframeWrapper
      ref={iframeRef}
      wrapperUrl={sharedIframeWrapperURL}
      iframeStyles={iframeStyles}
      iframeTitle={iframeTitle}
      iframeProperties={PidlIframe}
      formParameterDict={formParameterDict}
      bundleEntryFile={ComponentBundleMap.Pidl}
      showLoadingIconInitially
      xpayClientName={xpayClientName}
    />
  );
};

PidlIframeComponent.propTypes = {
  country: PropTypes.string.isRequired,
  shouldLoadPidl: PropTypes.bool.isRequired,
  setPidlSubmit: PropTypes.func.isRequired,
  setReturnVals: PropTypes.func.isRequired,
  callbackSetterKeys: PropTypes.arrayOf(PropTypes.string),
  iframeRef: PropTypes.shape({
    current: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  triggerLoader: PropTypes.bool,
};
PidlIframeComponent.defaultProps = {
  callbackSetterKeys: [],
  triggerLoader: true,
};

export const useIframeAddPIPidl = ({
  country,
  shouldLoadPidl,
  callbackSetters = {},
  ...otherProps
}) => {
  const iframeRef = useRef();

  const originOverride = siteUrl;
  const [returnVals, setReturnVals] = useState({
    isValid: false,
    validationErrors: [],
    piDetails: { State: null },
    cardType: null,
  });
  const submitPidlRef = useRef();


  const internalCallbackSetterRefs = useRef({});

  const { piType, popupConfig } = otherProps ?? {};

  const createPaypalPopup = useDefaultCreatePaypalPopup(piType, popupConfig);
  const submitPidl = useCallback((popupInfo, ...args) => {
    if (submitPidlRef.current) {
      const cleanupFunctions = [];

      let newPopupInfo = popupInfo ?? createPaypalPopup();
      if (newPopupInfo) {
        const { navigate, promise: popupPromise, closeWindow } = newPopupInfo ?? {};
        cleanupFunctions.push(closeWindow);

        const navigateIsFunction = _.isFunction(navigate);
        if (navigateIsFunction) {
          cleanupFunctions.push(listenOnce({
            type: EventTypes.Navigate,
            actionCb: (data) => {
              navigate(...data);
            },
            originOverride,
          }));
        }

        const popupPromiseExists = !!popupPromise;
        if (popupPromiseExists) {
          cleanupFunctions.push(respondToRequests({
            type: EventTypes.PopupPromise,
            transformData: () => popupPromise,
            originOverride,
          }));

          // this should resolve/reject on completion of paypal;
          popupPromise.then((data) => {
            const { resolve: submitResolve } = internalCallbackSetterRefs.current;
            if (submitResolve) {
              submitResolve(data);
            }
          }).catch((err) => {
            const { reject: submitReject } = internalCallbackSetterRefs.current;
            if (submitReject) {
              submitReject(err);
            }
            // undo paypal submit
            const { resetPidlInstance } = internalCallbackSetterRefs.current;
            if (resetPidlInstance) {
              resetPidlInstance();
            }
          });
        }

        // pass navigateIsFunction/popupPromiseExists instead of an object that won't transfer between frames
        newPopupInfo = { navigateIsFunction, popupPromiseExists };
      }
      return submitPidlRef.current(newPopupInfo, ...args).finally(() => {
        _.each(cleanupFunctions, (cleanup) => {
          cleanup();
        });
      });
    }
    logError('Pidl submit called before pidl fully initialized');
    return Promise.reject(new Error('Pidl is not initialized'));
  }, [createPaypalPopup, originOverride]);

  const setPidlSubmit = useCallback((pidlSubmit) => {
    submitPidlRef.current = pidlSubmit;
  }, []);

  const [isPidlLoaded, setIsPidlLoaded] = useState(false);

  useEffect(() => listenPersistently({
    type: EventTypes.PidlLoaded,
    actionCb: () => {
      setIsPidlLoaded(true);
    },
    originOverride,
  }), [originOverride]);

  useEffect(() => {
    if (!shouldLoadPidl) {
      setIsPidlLoaded(false);
    }
  }, [shouldLoadPidl]);

  const extendedCallbackSetters = useMemo(() => {
    const result = {
      ...callbackSetters,
    };

    _.each(IFRAME_WRAPPER_CALLBACK_SETTER_KEYS, (key) => {
      const parentCallback = result[key];

      result[key] = (cb) => {
        if (parentCallback) {
          parentCallback(cb);
        }
        internalCallbackSetterRefs.current[key] = cb;
      };
    });

    return result;
  }, [callbackSetters]);

  const callbackSetterKeys = useMemo(() => {
    if (extendedCallbackSetters) {
      return _.keys(extendedCallbackSetters);
    }

    return undefined;
  }, [extendedCallbackSetters]);

  useEffect(() => {
    _.each(extendedCallbackSetters, (setCb, key) => {
      setCb((...data) => {
        if (iframeRef.current) {
          sendMessage({
            type: getCallbackType(key),
            data,
            targetWindow: iframeRef.current.contentWindow,
            originOverride,
          });
        }
      });
    });
  }, [originOverride, extendedCallbackSetters]);

  const pidlForm = (
    <PidlIframeComponent
      country={country}
      shouldLoadPidl={shouldLoadPidl || false}
      setPidlSubmit={setPidlSubmit}
      {...otherProps}
      setReturnVals={setReturnVals}
      callbackSetterKeys={callbackSetterKeys}
      iframeRef={iframeRef}
    />
  );

  return {
    pidlForm,
    submitPidl,
    isPidlLoaded,
    ...returnVals,
  };
};
