export const ScenarioNames = {
  BING_GIVE: 'BingGive',
  BING_SERP: 'BingSERP',
  EDGE_TIPPING: 'EdgeTipping',
  EDGE_MOBILE_WALLET: 'EdgeMobileWallet',
  BuyNow: 'BuyNow',
  COPILOT_CHECKOUT: 'CopilotCheckout',
  BUYNOW_FIRST_PARTY: 'BuyNowFirstParty',
  BUYNOW_THIRD_PARTY: 'BuyNowThirdParty',
};

export const AppNames = {
  Donation: 'Donation',
  Subscription: 'Subscription',
  Rebates: 'Rebates',
  EdgeTipping: 'EdgeTipping',
  Teams: 'Teams',
  Buynow: 'BuyNow',
  BuynowSubscription: 'BuyNowSubscription',
  EdgeMobileWallet: 'EdgeMobileWallet',
  CopilotCheckout: 'CopilotCheckout',
  BuyNowFirstParty: 'BuyNowFirstParty',
  BuyNowThirdParty: 'BuyNowThirdParty',
};
