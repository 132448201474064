import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useReducer,
  memo
} from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

const LoaderContext = createContext();
const useStyles = createUseStyles({
  hidden: {
    display: 'none !important',
  },
  spinnerOverlay: {
    display: 'block',
    backgroundColor: 'rgba(0,0,0,.6)',
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99999,
  },
  spinnerOverlayEdgeTipping: {
    display: 'block',
    backgroundColor: 'rgba(0,0,0,0)',
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99999,
  },
  spinnerOverlayChild: {
    display: 'block',
    backgroundColor: 'rgba(0,0,0,.6)',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99999,
  },
  relative: {
    position: 'relative',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 0,
    padding: 0,
    height: '100%',
    width: '100%',
  },
});

const padding = 15;
const spinnerStyle = (showSpinnerText = true) => ({
  circle: {
    position: 'absolute', // TODO: remove when multi-line round spinner issue is fixed
    left: padding, // TODO: remove when multi-line round spinner issue is fixed
  },
  label: {
    paddingLeft: 25, // TODO: remove when multi-line round spinner issue is fixed
  },
  root: {
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    left: '50%',
    top: '50%',
    backgroundColor: showSpinnerText ? 'white' : 'none',
    padding,
    minHeight: showSpinnerText ? 55 : 0, // TODO: remove when multi-line round spinner issue is fixed
  },
});

const initialLoaderState = 0;
const reducer = (count, action) => {
  switch (action) {
    case 'increment': return count + 1;
    case 'decrement': return count - 1;
    default: throw new Error('Unexpected action');
  }
};

export const LoaderContextProvider = memo(({
  children, isTransientBackground, isChildContext, showSpinnerText,
}) => {
  const classes = useStyles();
  const [text, setLoaderText] = useState('');
  const [loaderCount, dispatch] = useReducer(reducer, initialLoaderState);

  const increaseLoaderCount = useCallback(() => {
    dispatch('increment');
  }, []);
  const decreaseLoaderCount = useCallback(() => {
    dispatch('decrement');
  }, []);

  const countIs0 = loaderCount === 0;

  const content = (
    <>
      <div
        className={classnames(
                  // eslint-disable-next-line no-nested-ternary
                  isChildContext ? classes.spinnerOverlayChild : isTransientBackground ? classes.spinnerOverlayEdgeTipping : classes.spinnerOverlay,
          countIs0 ? classes.hidden : ''
        )}
      >
        <Spinner
          className={classes.spinner}
          size={SpinnerSize.large}
          label={text}
          labelPosition="right"
          styles={spinnerStyle(showSpinnerText)}
        />
      </div>
      {children}
    </>
  );

  return (
    <LoaderContext.Provider value={{ increaseLoaderCount, decreaseLoaderCount, setLoaderText }} >
      {
        isChildContext
        ? (
          <div className={classes.relative}>
            {content}
          </div>
        )
        : content
      }
    </LoaderContext.Provider>
  );
});
LoaderContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  isChildContext: PropTypes.bool,
  showSpinnerText: PropTypes.bool,
  isTransientBackground: PropTypes.bool,
};
LoaderContextProvider.defaultProps = {
  showSpinnerText: true,
  isChildContext: false,
  isTransientBackground: false,
};
LoaderContextProvider.displayName = 'LoaderContextProvider';

// TODO: set up so multiple loading texts can be added (with priorities), and as the higher priority texts are finished, the lower priority texts will show until those tasks are finished
export const useLoader = (defaultLoaderText) => {
  const { increaseLoaderCount, decreaseLoaderCount, setLoaderText } = useContext(LoaderContext);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (isLoading) {
      increaseLoaderCount();

      return decreaseLoaderCount;
    }
    return undefined;
  }, [decreaseLoaderCount, increaseLoaderCount, isLoading]);

  return useCallback((isLoadingToSet, text) => {
    setIsLoading(isLoadingToSet);
    const loaderText = text || defaultLoaderText;
    if (loaderText) {
      setLoaderText(text);
    }
  }, [defaultLoaderText, setLoaderText]);
};
