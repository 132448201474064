import _ from 'underscore';
import { getChannel } from '../GlobalContext/global-context';
import { xpayEnv } from '../../Shared/Utils/xpay-env-values';
import { EnvList } from '../../Shared/Configs/config';
import { TESTSCENARIO_HEADER_KEY, Environment_HEADER_KEY, Environment_ID_HEADER_KEY } from './constants';

const autoHeader = {};
let authToken: string = null;
let testScenario: string;
try {
  testScenario = new URLSearchParams(window.location.search).get('testscenario');
} catch (error) {
  // Do nothing
}

const addAutoHeader = (additionalHeaders: {[key: string]: string}) => {
  _.extend(autoHeader, additionalHeaders);
};
export const setXPayClientName = (clientName: string) => {
  addAutoHeader({ XPayClientName: clientName });
};
export const setAuthToken = (token: string) => {
  addAutoHeader({ Authorization: `Bearer ${token}` });
  authToken = token;
};
// TODO: no one is using it, maybe delete later?
export const setTestAuthToken = (token: string) => {
  addAutoHeader({ 'token-test': `${token}` });
};

export const InjectCITestHeader = (header: { [key: string]: string }) => {
  if (testScenario) {
    _.extend(header, { [TESTSCENARIO_HEADER_KEY]: testScenario });
  }
};

export const InjectChannelHeader = (header: { [key: string]: string }) => {
  if (xpayEnv === EnvList.INT) {
    _.extend(header, { [Environment_HEADER_KEY]: 'PrivatePPE' });
    _.extend(header, { [Environment_ID_HEADER_KEY]: getChannel() });
  }
};

export const getAuthToken = () => authToken;
export const getAutoHeader = () => autoHeader;
