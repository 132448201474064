import _ from 'underscore';

export function addStyleToHeadSection(head: HTMLHeadElement, content: string) {
  const dynamicStyles = document.createElement('style');
  dynamicStyles.type = 'text/css';
  const textNodes = document.createTextNode(content);
  dynamicStyles.appendChild(textNodes);
  head.appendChild(dynamicStyles);
}

export const addFluentIcons = _.once(() => {
  const stylesheet = document.createElement('link');
  stylesheet.rel = 'stylesheet';
  stylesheet.href = 'https://static2.sharepointonline.com/files/fabric/office-ui-fabric-core/11.0.0/css/fabric.min.css';

  document.head.appendChild(stylesheet);
});
