export const PERSISTENT_LISTENER_KEY = 'persistentListeners';
export const ONE_TIME_LISTENER_KEY = 'oneTimeListeners';
export const DEFAULT_ORIGIN_KEY = 'UseDefaultOrigin';

/**
 * Enum for Message Names
 * @readonly
 * @enum {string}
 */
export const MessageTypes = Object.freeze({
  /** @member {string} */
  /** The parent window should show the success dialog. */
  ShowSuccessDialog: 'ShowSuccessDialog',
  // /** @member {string} */
  // /** The parent window should open the side panel. */
  // OpenSidePanel: 'OpenSidePanel',
  // /** @member {string} */
  /** The parent window should close the side panel. */
  ClosePanel: 'ClosePanel',
  /** @member {string} */
  // /** The parent window should show the cvv input dialog. */
  // PopUpCVVInputDialog: 'PopUpCVVInputDialog',
  // /** @member {string} */
  // /** The parent window should send data tile */
  // TileAskInfoFromBootstrap: 'TileAskInfoFromBootstrap',
  /** @member {string} */
  /** The PI list token is being sent. */
  PIListToken: 'PIListToken',
  /** The export token is being sent. */
  ExportToken: 'ExportToken',
  /** The edge tipping token is being sent. */
  EdgeTippingToken: 'EdgeTippingToken',
  /** The openId token is being sent. */
  OpenIdToken: 'OpenIdToken',
  /** The rps token is being sent. */
  RPSToken: 'RPSToken',
  /** The pidl redirect response. */
  PidlRedirect: 'PidlRedirect',
  /** @member {string} */
  /** The parent window should send data to the current window. */
  GetAccountAndPaymentInfo: 'GetAccountAndPaymentInfo',
});
