import * as _ from 'underscore';

export { getScopedSettings, setScopedSettings } from './fluent';

export type XpayTheme = _.Dictionary<any>;

let defaultTheme: XpayTheme = {};
const registry: _.Dictionary<XpayTheme> = { };

export const registerThemes = (themeMapping: _.Dictionary<XpayTheme>) => {
  _.extend(registry, themeMapping);
};
export const setDefaultTheme = (theme: XpayTheme) => {
  defaultTheme = theme;
};
export const getThemeObj = (themeName: string) => registry[themeName] ?? defaultTheme;
