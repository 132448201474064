import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import URI from 'urijs';
import { CORRELATIONID_HEADER_KEY, HOSTPAGEID_HEADER_KEY, SESSIONID_HEADER_KEY, SCNAME_HEADER_KEY } from '../RequestHeaders/constants';
import { JSONError, UnauthorizedError } from '../ErrorHandling/error-types';

import {
  startPerf,
  logInfo,
  logError,
  getCorrelationId,
} from '../Logger/log-uploader';
import { globalContext } from '../GlobalContext/global-context';
import { setXPayClientName, setAuthToken, getAuthToken, getAutoHeader, InjectCITestHeader, InjectChannelHeader } from '../RequestHeaders';

export { setXPayClientName, setAuthToken, getAuthToken }; // For compatible reasons

const shouldParseResponseToJSON = (res: Response) => {
  const responseTypeIsJSON = res.headers.get('content-type')?.indexOf('application/json') !== -1;
  const responseLengthNotZero = res.headers.get('content-length') !== '0';
  return responseTypeIsJSON && responseLengthNotZero;
};

const shouldParseResponseToImage = (res: Response) => {
  const responseTypeIsImage = res.headers.get('content-type')?.indexOf('image/') !== -1;
  const responseLengthNotZero = res.headers.get('content-length') !== '0';
  return responseTypeIsImage && responseLengthNotZero;
};

// This is a temporary solution to check details for the error object
const inspectErrorObject = (error: Error | { [key: string]: any}) => {
  const obj: any = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key of _.allKeys(error)) {
    if (typeof ((error as any)[key]) === 'function') {
      obj[key] = 'function';
    } else {
      obj[key] = (error as any)[key];
    }
  }
  return obj;
};

let ajaxCallCounter = 0;

interface AjaxCallParam {
  url: string,
  data?: any,
  customData?: any,
  method?: string,
  headers?: { [key: string]: string },
  skipAutoAddFields?: boolean,
  mode?: RequestMode,
}

export const ajaxCall = ({
  url,
  data = {},
  customData = undefined,
  method = 'POST',
  headers = {
    'Content-Type': 'application/json',
  },
  skipAutoAddFields = false,
  mode = undefined,
}: AjaxCallParam) => {
  const correlationId = uuidv4();
  const pageCorrelationId = getCorrelationId();
  return new Promise<any>((resolve, reject) => {
    const headersWithAuth = _.clone(headers);

    if (!skipAutoAddFields) {
      _.extend(headersWithAuth, getAutoHeader());

      headersWithAuth[SESSIONID_HEADER_KEY] = globalContext.sessionId ?? '';
      headersWithAuth[HOSTPAGEID_HEADER_KEY] = globalContext.hostPageId ?? '';
      headersWithAuth[SCNAME_HEADER_KEY] = globalContext.scenarioName ?? '';
      headersWithAuth[CORRELATIONID_HEADER_KEY] = correlationId;
      InjectCITestHeader(headersWithAuth);
      InjectChannelHeader(headersWithAuth);
    }

    ajaxCallCounter += 1;

    const currentCounter = ajaxCallCounter;

    const uri = new URI(url);
    const loggingActivity = startPerf(uri.pathname(), correlationId, url);
    logInfo({
      message: 'Ajax Start',
      url,
      method,
      withAuthToken: _.has(headersWithAuth, 'Authorization')
        && !_.isEmpty(headersWithAuth.Authorization),
      headers: _.omit(headers, 'Authorization'),
      mode,
      pageCorrelationId,
      ajaxCallSequence: currentCounter,
    }, correlationId, url);

    const fetchOptions = (method.toLowerCase() === 'get' || method.toLowerCase() === 'head') ?
      {
        method,
        headers: new Headers(headersWithAuth),
        mode,
      } :
      {
        method,
        headers: new Headers(headersWithAuth),
        body: _.isUndefined(customData) ? (JSON.stringify(data)) : customData,
        mode,
      };

    fetch(url, fetchOptions).then((r) => {
      loggingActivity.stop();

      if (r.ok) {
        logInfo({
          message: 'Ajax Success',
          url,
          method,
          pageCorrelationId,
          ajaxCallSequence: currentCounter,
        }, correlationId, url);
        if (shouldParseResponseToJSON(r)) {
          resolve(r.json());
        } else if (shouldParseResponseToImage(r)) {
          resolve(r.blob());
        } else {
          resolve(r.text());
        }
      } else if (r.status === 401) {
        reject(new UnauthorizedError());
      } else if (shouldParseResponseToJSON(r)) {
        r.json().then((jsonData) => {
          reject(new JSONError(jsonData));
        }).catch((err) => {
          reject(new Error(`Status Code: ${r.status}, error: ${err}`));
        });
      } else {
        r.text().then((text) => {
          throw new Error(text);
        }).catch((err) => {
          reject(new Error(`Status Code: ${r.status}, error: ${err}`));
        });
      }
    }).catch((err) => {
      loggingActivity.stop();
      logError({
        message: 'Ajax Failure',
        url,
        method,
        errorMessage: err.message,
        error: inspectErrorObject(err),
        pageCorrelationId,
        ajaxCallSequence: currentCounter,
      }, correlationId, url);
      reject(err);
    });
  });
};
