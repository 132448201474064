export const globalConfig = {
  msAppClientId: 'f78b16b6-0f85-48c0-b19b-fcaa5da5dae2',
  popupWindowWidth: 500,
  popupWindowHeight: 700,
  waitForPanelLoadTimeLimit: 5000, // in ms. This value should be multiples of tickInterval.
  iframeTokenReceiveTimeLimit: 4000, // in ms. This value should be multiples of tickInterval.
  tickInterval: 250, // in ms
  usePopupWindowToAuthWhenIframeFailed: false,
};

export const fakeCardForBluesnap = {
  ccNumber: '4263982640269299', // Real card not working in bluesnap sandbox
  cvv: '837',
  expDate: '02/2023',
};

export const EnvList = {
  PROD: 0,
  CI: 1,
  TEST: 2,
  INT: 3,
  TESTHTTP: 4,
  STAGING: 5,
};

export const pidlScopes = ['PIFD.Read', 'PIFD.Export', 'PIFD.Create', 'PIFD.Update'];
