export const PartnerThemeList = {
  BingGive: 'BingGive', // Give with Bing
  BingSERP: 'BingSERP', // Search result page
  MSNSubscription: 'MSNSubscription',
  EdgeTipping: 'EdgeTipping',
  Clo: 'Clo',
  Buynow: 'BuyNow',
  BuyNowFirstParty: 'BuyNowFirstParty',
  EdgeMobileWallet: 'EdgeMobileWallet',
  CopilotCheckout: 'CopilotCheckout',
};
