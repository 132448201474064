import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@fluentui/react';

import { useI18nObj } from '../../FunctionModules/Localization/i18n';
import { useInputChangeCallback } from './hooks/react-utils';

const styles = {
  field: {
    textAlign: 'right',
    '::-webkit-input-placeholder': {
      color: '#A19F9D',
    },
  },
};

interface CvvInputProps {
  className: string;
  setCvv: (cvv: string) => void;
  value: string;
  labelName: string;
  placeholderName: string;
  rightAlign: boolean;
}

export const CvvInput: React.FC<CvvInputProps> = memo((props: CvvInputProps) => {
  const i18n = useI18nObj();
  const cvvLabelName = props.labelName ?? i18n.Cvv;
  const cvvPlaceholderName = props.placeholderName ?? i18n.Cvv;
  const handleCvvInputChange = useInputChangeCallback(props.setCvv);
  return (
    <div className="form-group">
      <TextField
        id="CvvInput"
        value={props.value}
        className={props.className}
        placeholder={cvvPlaceholderName}
        label={cvvLabelName}
        ariaLabel={cvvLabelName}
        maxLength={4}
        onChange={handleCvvInputChange}
        styles={props.rightAlign ? styles : null}
        autoComplete="off"
        type="password"
      />
    </div>
  );
});

CvvInput.defaultProps = {
  className: '',
  labelName: undefined,
  placeholderName: undefined,
  rightAlign: false,
};

CvvInput.propTypes = {
  className: PropTypes.string,
  setCvv: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  labelName: PropTypes.string,
  placeholderName: PropTypes.string,
  rightAlign: PropTypes.bool,
};
