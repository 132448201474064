import _ from 'underscore';
import { listenOnce } from './FrameMessageBus';

import { addSpinnerToDocument } from './IframeWrapper/iframe-script';
import { logInfo, startPerf, PerfInterface } from './Logger/log';

const blankPageUrl = 'about:blank';

interface ListenerParams<ReturnType> {
  type: string,
  action: (data: object) => Promise<ReturnType>|ReturnType,
  originOverride?: string,
}

interface CreatePopupParams<ReturnType> {
  target: string,
  url?: string,
  popupConfig?: string,
  titleForBlankPage?: string,
  loaderTextForBlankPage?: string,
  closeCheckInterval?: number,
  listenerConfigs?: ListenerParams<ReturnType>[],
}

// can check if error is instance of this class if need to do different error handling for this error type
export class PopupError extends Error { }

export function createPopup<ReturnType>({
  target,
  url = blankPageUrl,
  popupConfig,
  titleForBlankPage,
  loaderTextForBlankPage,
  closeCheckInterval = 100,
  listenerConfigs = [],
} : CreatePopupParams<ReturnType>) {
  const cleanupMethods: (() => void)[] = [];
  let windowHandle: Window;
  let closeWindow: () => void;
  let perfActivity: PerfInterface;
  const promise: Promise<ReturnType|void> = new Promise<ReturnType|void>((resolve, reject) => {
    closeWindow = () => resolve();
    try {
      perfActivity = startPerf('popup window create');
      windowHandle = window.open(url, target, popupConfig);
    } catch (err) {
      reject(err);
    }

    if (!windowHandle) {
      reject(new PopupError('Failed to open popup window'));
      return;
    }

    if (url === blankPageUrl) {
      windowHandle.document.title = titleForBlankPage;
      addSpinnerToDocument(windowHandle.document, loaderTextForBlankPage);
    }

    const windowOpenCheckId = setInterval(() => {
      if (windowHandle && windowHandle.closed) {
        logInfo('Popup window was closed');
        reject(new PopupError('Popup window was closed'));
      }
    }, closeCheckInterval);
    cleanupMethods.push(() => {
      clearInterval(windowOpenCheckId);
    });
    _.each(listenerConfigs, ({
      type,
      action,
      originOverride,
    }) => {
      const listenerCleanup = listenOnce({
        type,
        actionCb: async (data) => {
          try {
            resolve(action(data));
          } catch (err) { // catch errors if action is synchronous, async errors should propagate out automatically
            reject(err);
          }
        },
        originOverride,
      });
      cleanupMethods.push(listenerCleanup);
    });
  }).finally(() => {
    if (windowHandle && !windowHandle.closed) {
      perfActivity.stop();
      logInfo('Popup window will close');
      windowHandle.close();
    }
    _.each(cleanupMethods, (cleanupMethod) => {
      cleanupMethod();
    });
  });

  return {
    promise,
    windowHandle,
    closeWindow,
  };
}
