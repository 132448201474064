import { useLayoutEffect } from 'react';
import { useI18nObj } from '../../FunctionModules/Localization/i18n';
import { useLoader } from './context/loading-context';

/**
 * Show Loading Panel if rendered
 * @return  {void}
 */
export function ShowLoaderComponent() : JSX.Element {
  const i18n = useI18nObj();
  const setIsLoading = useLoader();

  // useLayoutEffect so it happens before screen is painted
  // this prevents flashing UI without panel
  useLayoutEffect(() => {
    setIsLoading(true, i18n.Loading);
    return () => {
      setIsLoading(false);
    };
  }, [i18n, setIsLoading]);

  return null;
}
