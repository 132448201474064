/* istanbul ignore file */
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import _ from 'underscore';

import { useUrlPilot } from '../context/url-piloting-react';
import {
  PIDL_ADDRESS_FIELDS, PIDL_FIELD_MAPPING,
  PIDL_PROPERTY_NAME_TO_DISPLAYID_MAPPING,
  PIDL_REQUIRED_ADDRESS_FIELDS_BY_COUNTRY,
  PIDL_FIELD_ISSUES,
} from './constants';
import { validateCard, validateCvv, validatePostCode } from '../../Utils/card-validation';
import { useAuthorization } from '../context/authZ-context';
import { useCv } from '../hooks/use-cv';
import { ajaxCall } from '../../../FunctionModules/AjaxHelper/ajax-helper';
import { addTokenDescriptor } from '../../Utils/token-header-utils';
import { useI18nObj } from '../../../FunctionModules/Localization/i18n';
import { createPopup } from '../../../FunctionModules/popup-helper';
import { MessageTypes } from '../../../FunctionModules/FrameMessageBus';
import { PidlFatalError } from '../../../FunctionModules/ErrorHandling/error-types';
import PI_TYPES from '../../Entities/pi-types';
import { siteUrl } from '../../Utils/xpay-env-values';
import { logInfo } from '../../../FunctionModules/Logger/log';
import { validateDate } from './helper/date-validation';

const getDisplayIdFromPropertyPropertyName = propertyName =>
  PIDL_PROPERTY_NAME_TO_DISPLAYID_MAPPING[propertyName] || propertyName;

const clearKey = 'CLEAR_VALUES';
const piDetailsReducer = (piDetails, updatedVal) => {
  if (updatedVal === clearKey) {
    return {};
  }

  const correctedUpdateVal = updatedVal;
  if (correctedUpdateVal.ExpiryMonth && correctedUpdateVal.ExpiryMonth.length === 1) {
    correctedUpdateVal.ExpiryMonth = `0${correctedUpdateVal.ExpiryMonth}`;
  }

  // if all new vals match prev pi values, return existing object
  if (_.all(correctedUpdateVal, (val, key) => piDetails[key] === val)) {
    return piDetails;
  }

  return {
    ...piDetails,
    ...correctedUpdateVal,
  };
};

export const useGetPaypalInfoCallback = () => {
  const { pidlAuthZ } = useAuthorization();
  const CV = useCv();
  return useCallback(piId => ajaxCall({
    url: `${siteUrl}/api/paymentinstr/get`,
    data: {
      MsaToken: addTokenDescriptor(pidlAuthZ),
      CV,
      PiId: piId,
    },
  }), [CV, pidlAuthZ]);
};

const getPidlInput = (pidlContainerRef, propertyName) => {
  const selector = `.pidlddc-input-${propertyName}`;
  const inputFields = pidlContainerRef.current.querySelectorAll(selector);
  if (inputFields.length === 0) {
    return null;
  }
  const firstElement = _.first(inputFields);
  const firstElementTag = firstElement.nodeName.toLowerCase();
  if (firstElementTag === 'div') {
    return _.first(firstElement.querySelectorAll('.pidlddc-input-property'));
  }

  return firstElement;
};

const updateAddressState = (pidlContainerRef, dispatchPiDetails) => {
  // Address might be prefilled, so get the values when the form loads
  const updatedAddress = {};
  _.each(PIDL_ADDRESS_FIELDS, (pidlKey) => {
    const input = getPidlInput(pidlContainerRef, pidlKey);
    const fieldVal = input && input.value;
    if (fieldVal) {
      const addressReturnKey = PIDL_FIELD_MAPPING[pidlKey];
      updatedAddress[addressReturnKey] = fieldVal;
    }
  });
  if (updatedAddress) {
    dispatchPiDetails(updatedAddress);
  }
};

const updateCardNumberState = (pidlContainerRef, dispatchPiDetails) => {
  // CardNumber might be prefilled, so get the values when the form loads
  const input = getPidlInput(pidlContainerRef, 'accountToken');
  const fieldVal = input && input.value;

  if (fieldVal) {
    dispatchPiDetails({ [PIDL_FIELD_MAPPING.accountToken]: fieldVal });
  }
};

const updateAccountHolderNameState = (pidlContainerRef, dispatchPiDetails) => {
  const input = getPidlInput(pidlContainerRef, 'accountHolderName');
  const fieldVal = input && input.value;

  if (fieldVal) {
    dispatchPiDetails({ [PIDL_FIELD_MAPPING.accountHolderName]: fieldVal });
  }
};

const updateCardExpirationMonthState = (pidlContainerRef, dispatchPiDetails) => {
  const input = getPidlInput(pidlContainerRef, 'expiryMonth');
  const fieldVal = input && input.value;

  if (fieldVal) {
    dispatchPiDetails({ [PIDL_FIELD_MAPPING.expiryMonth]: fieldVal });
  }
};

const updateCardExpirationYearState = (pidlContainerRef, dispatchPiDetails) => {
  const input = getPidlInput(pidlContainerRef, 'expiryYear');
  const fieldVal = input && input.value;

  if (fieldVal) {
    dispatchPiDetails({ [PIDL_FIELD_MAPPING.expiryYear]: fieldVal });
  }
};

const selectionChanged = (
  pidlContainerRef,
  dispatchPiDetails,
  dispatchPiDetailsValid,
  setInputListeners,
  parameters
) => {
  const {
    propertyValue,
    displayId: pidlKey,
  } = parameters;

  if (propertyValue) {
    const xpayKey = PIDL_FIELD_MAPPING[pidlKey];
    if (xpayKey) {
      dispatchPiDetails({ [xpayKey]: propertyValue });
    }
  }

  // If updating country, reset validation checks and input listeners,
  // fetch new address (in case it prefilled from a saved billing address)
  if (pidlKey === 'country') {
    dispatchPiDetailsValid(clearKey);
    updateAddressState(pidlContainerRef, dispatchPiDetails);
    setInputListeners();
  }

  if (pidlKey === 'accountToken') {
    dispatchPiDetailsValid(clearKey);
    updateCardNumberState(pidlContainerRef, dispatchPiDetails);
    setInputListeners();
  }
};

const returnTrue = _.constant(true);

export const usePiDetailsEventHandler = (isPidlVisible, pidlContainerRef) => {
  const pilots = useUrlPilot();
  const { current: updateFunctions } = useRef({});
  const selectOptionsRef = useRef({});

  const [piDetails, dispatchPiDetails] = useReducer(piDetailsReducer, {});
  const [piDetailsValid, dispatchPiDetailsValid] = useReducer(piDetailsReducer, {});
  const i18n = useI18nObj();

  useEffect(
    () => {
      if (!isPidlVisible) {
        dispatchPiDetails(clearKey);
        dispatchPiDetailsValid(clearKey);
      }
    },
    [isPidlVisible]
  );

  const setIsValid = (key, value) => {
    if (key) {
      dispatchPiDetailsValid({ [key]: value });
    }
  };

  const eventHandler = useCallback(
    (eventName, parameters) => {
      if (pilots.logPidlEvent) {
        console.info('Recieved Pidl Event', eventName, parameters);
      }

      const setInputListeners = () => {
        _.each(PIDL_FIELD_MAPPING, (xpayKey, pidlKey) => {
          const input = getPidlInput(pidlContainerRef, pidlKey);
          if (input) {
            const prevFunc = updateFunctions[pidlKey];

            // TODO: only do change for selects, and input for input
            // (need to be sure input is supported for all browsers)
            const eventTypes = ['change', 'input'];

            // clear previous listeners if they are still set on input
            if (prevFunc) {
              _.each(
                eventTypes,
                (eventType) => {
                  input.removeEventListener(eventType, prevFunc);
                }
              );
            }

            const updateFunc = (e) => {
              dispatchPiDetails({ [xpayKey]: e.target.value });
            };
            updateFunctions[pidlKey] = updateFunc;

            _.each(
              eventTypes,
              (eventType) => {
                input.addEventListener(eventType, updateFunc);
              }
            );
          }
        });
      };

      switch (eventName) {
        case 'selectionChanged':
          selectionChanged(
            pidlContainerRef,
            dispatchPiDetails,
            dispatchPiDetailsValid,
            setInputListeners,
            parameters
          );
          break;
        case 'pidlDownloaded':
          // if pidl is re-downloaded, clear all the validations, as they might have changed
          dispatchPiDetailsValid(clearKey);
          selectOptionsRef.current = {};
          // TODO: make sure this happens
          if (pilots.updateAddressesOnPidlDownloaded) {
            updateAddressState(pidlContainerRef, dispatchPiDetails);
          }
          updateCardNumberState(pidlContainerRef, dispatchPiDetails);
          updateAccountHolderNameState(pidlContainerRef, dispatchPiDetails);
          updateCardExpirationMonthState(pidlContainerRef, dispatchPiDetails);
          updateCardExpirationYearState(pidlContainerRef, dispatchPiDetails);
          break;
        case 'pageRendered':
          updateAddressState(pidlContainerRef, dispatchPiDetails);
          updateCardNumberState(pidlContainerRef, dispatchPiDetails);
          updateAccountHolderNameState(pidlContainerRef, dispatchPiDetails);
          updateCardExpirationMonthState(pidlContainerRef, dispatchPiDetails);
          updateCardExpirationYearState(pidlContainerRef, dispatchPiDetails);
          setInputListeners();
          break;
        case 'propertyCreating':
          if (parameters.displayDescription &&
            !_.isEmpty(parameters.displayDescription.possibleOptions)
            && PIDL_FIELD_MAPPING[parameters.displayDescription.displayId]) {
            selectOptionsRef.current[PIDL_FIELD_MAPPING[parameters.displayDescription.displayId]] =
              _.mapObject(parameters.displayDescription.possibleOptions, returnTrue);
          }
          // when inputting cc number, new inputs are created, so we need to update the listeners when properties are created
          setInputListeners();
          break;
        // If Pidl does validation, block when validation fails
        case 'propertyValidated':
          setIsValid(
            PIDL_FIELD_MAPPING[getDisplayIdFromPropertyPropertyName(parameters.propertyName)],
            true
          );
          break;
        case 'error':
          if (parameters.error.propertyName) {
            setIsValid(
              PIDL_FIELD_MAPPING[parameters.error
                && getDisplayIdFromPropertyPropertyName(parameters.error.propertyName)],
              false
            );
          }
          break;
        default: break;
      }
    },
    [pidlContainerRef, pilots.logPidlEvent, pilots.updateAddressesOnPidlDownloaded, updateFunctions]
  );

  const invalidSelectFields = useMemo(
    () => _.chain(selectOptionsRef.current)
      .reduce(
        (mem, options, xpaykey) => {
          let propertyValue = piDetails[xpaykey];

          // property values in option list for expiry date doesn't have leading 0
          if (
            xpaykey === 'ExpiryMonth'
            && propertyValue
            && propertyValue.length
            && propertyValue[0] === '0'
          ) {
            propertyValue = propertyValue.substr(1);
          }

          if (!_.has(options, propertyValue)) {
            // eslint-disable-next-line no-param-reassign
            mem[xpaykey] = PIDL_FIELD_ISSUES.SelectOptionMissing;
          }
          return mem;
        },
        {}
      )
      .value(),
    [piDetails]
  );

  const unfilledFields = useMemo(
    () => _.reduce(
      [
        'CardNumber',
        'CVV',
        'Name',
        'ExpiryMonth',
        'ExpiryYear',
        'Country',
        ...(PIDL_REQUIRED_ADDRESS_FIELDS_BY_COUNTRY[piDetails.Country] || []),
      ],
      (mem, xpaykey) => {
        if (_.isEmpty(piDetails[xpaykey])) {
          // eslint-disable-next-line no-param-reassign
          mem[xpaykey] = PIDL_FIELD_ISSUES.FieldEmpty;
        }
        return mem;
      },
      {}
    ),
    [piDetails]
  );

  const { validationErrors: valueValidationErrors, cardType } = useMemo(
    () => {
      const validationErrorsInner = _.chain(piDetailsValid)
        .reduce(
          (mem, val, key) => {
            if (!val) {
              // eslint-disable-next-line no-param-reassign
              mem[key] = PIDL_FIELD_ISSUES.FieldInvalidPidlDetected;
            }
            return mem;
          },
          {}
        )
        .value();

      let cardTypeInner;
      let isCardNumberValid;
      // if cardNumber doesn't have validation errors already, run our basic validation
      if (piDetails.CardNumber && !_.has(validationErrorsInner, 'CardNumber')) {
        ({ cardType: cardTypeInner, isValid: isCardNumberValid } = validateCard(piDetails.CardNumber));

        if (!isCardNumberValid) {
          validationErrorsInner.CardNumber = PIDL_FIELD_ISSUES.FieldInvalid;
        }

        // if cvv doesn't have validation errors already, run our basic validation
        if (cardTypeInner && !_.has(validationErrorsInner, 'CVV') && !validateCvv(piDetails.CVV, cardTypeInner)) {
          validationErrorsInner.CVV = PIDL_FIELD_ISSUES.FieldInvalid;
        }

        // if zipCode doesn't have validation errors already, run our basic validation
        if (piDetails.PostCode && !_.has(validationErrorsInner, 'PostCode') && !validatePostCode(piDetails.PostCode, piDetails.Country)) {
          validationErrorsInner.PostCode = PIDL_FIELD_ISSUES.FieldInvalid;
        }
      }

      if (piDetails.ExpiryMonth && piDetails.ExpiryYear) {
        const expiryGroupErrEle = pidlContainerRef?.current?.querySelector('#pidlddc-error-expiryGroup');
        if (!validateDate(piDetails.ExpiryMonth, piDetails.ExpiryYear)) {
          validationErrorsInner.ExpiryGroup = PIDL_FIELD_ISSUES.FieldInvalid;
          if (expiryGroupErrEle) {
            expiryGroupErrEle.innerHTML = i18n.CheckPiExpireDate;
            expiryGroupErrEle.style.display = 'block';
          }
        } else {
          if (validationErrorsInner.ExpiryGroup) {
            delete validationErrorsInner.ExpiryGroup;
          }
          if (expiryGroupErrEle) {
            expiryGroupErrEle.innerHTML = '';
            expiryGroupErrEle.style.display = 'none';
          }
        }
      }

      return { validationErrors: validationErrorsInner, cardType: cardTypeInner };
    },
    [
      piDetailsValid,
      piDetails.CardNumber,
      piDetails.ExpiryMonth,
      piDetails.ExpiryYear,
      piDetails.CVV,
      piDetails.PostCode,
      piDetails.Country,
      pidlContainerRef,
      i18n.CheckPiExpireDate,
    ]
  );

  const validationErrors = useMemo(
    () => _.defaults({}, valueValidationErrors, invalidSelectFields, unfilledFields),
    [valueValidationErrors, invalidSelectFields, unfilledFields]
  );

  const isValid = useMemo(
    () => !_.any(validationErrors),
    [validationErrors]
  );

  return {
    isValid,
    validationErrors,
    piDetails,
    eventHandler,
    cardType,
  };
};

export const useCreatePaypalPopup = (piType, popupConfig, loaderText, loadingTitle) => {
  const getPaypalInfoCallback = useGetPaypalInfoCallback();
  const pilots = useUrlPilot();

  return useCallback(() => {
    if (piType !== PI_TYPES.PAYPAL) {
      return undefined;
    }

    let navigateContext;

    const popupInfo = createPopup({
      popupConfig,
      titleForBlankPage: loadingTitle,
      loaderTextForBlankPage: loaderText,
      listenerConfigs: [{
        type: MessageTypes.PidlRedirect,
        action: async (data) => {
          const { successParams, failureParams } = navigateContext;

          logInfo(`PIDL REDIRECT RESPONSE: ${JSON.stringify(data)}`);
          if (data.result) {
            const isSuccess = data.result === 'Success';
            if (isSuccess) {
              if (pilots.pidlForceReject) {
                throw new PidlFatalError({
                  errorCode: 'PilotFail',
                });
              } else {
                return getPaypalInfoCallback(successParams.id);
              }
            } else {
              throw new PidlFatalError({
                errorCode: 'RedirectResultFailed',
                err: data.err,
                details: failureParams,
              });
            }
          } else {
            throw new PidlFatalError({
              errorCode: 'MissingRedirectResult',
              err: data.err,
              details: failureParams,
            });
          }
        },
        originOverride: siteUrl,
      }],
    });

    const navigate = (url, context) => {
      popupInfo.windowHandle.location.href = url;

      navigateContext = context;
    };

    // if promise isn't chained to anything, silence the "Uncaught (in promise) Error:" console error
    popupInfo.promise.catch(() => null);

    return {
      ...popupInfo,
      navigate,
    };
  }, [getPaypalInfoCallback, loaderText, loadingTitle, piType, pilots, popupConfig]);
};

export const useDefaultCreatePaypalPopup = (piType, popupConfig) => {
  const i18n = useI18nObj();

  return useCreatePaypalPopup(piType, popupConfig, i18n.PaypalLoadingText, i18n.PaypalLoadingText);
};
