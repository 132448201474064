import { useRef } from 'react';
import { awa } from '@bingads-webui/jsll';

if (!awa.cv.isInit()) {
  awa.cv.init();
}

export const {
  increment,
  header,
} = awa.cv;

export const useCv = () => {
  const cvRef = useRef();
  if (!cvRef.current) {
    cvRef.current = awa.cv.increment();
  }

  return cvRef.current;
};
