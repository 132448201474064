export const DonatePanelIframe = {
  name: 'xpay-frame-panel',
  id: 'xpay-frame-panel',
};

export const TransactionHistoryIframe = {
  name: 'xpay-trans-history-frame',
  id: 'xpay-trans-history-frame',
};

export const PidlIframe = {
  name: 'xpay-pidl-frame',
  id: 'xpay-pidl-frame',
};

export const EdgeTippingIframe = {
  name: 'xpay-edge-tipping-frame',
  id: 'xpay-edge-tipping-frame',
};

export const BingProsIframe = {
  name: 'xpay-bing-pros-frame',
  id: 'xpay-bing-pros-frame',
};

export const donationIframeWrapperURL = '/Iframe/donationComponentWrapper';
export const subscriptionIframeWrapperURL = '/iframe/subscriptionComponentWrapper';
export const edgeTippingIframeWrapperURL = '/edgeTipping';
export const xpayTippingIframeWrapperURL = '/xpayTipping';
export const bingProsIframeWrapperURL = '/Iframe/bingProsComponentWrapper';
