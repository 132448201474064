const ComponentBundleMap = {
  Pidl: 'iframePidl',
  AjaxIframe: 'ajaxIframe',
};

const sharedIframeWrapperURL = '/Iframe/sharedComponentWrapper';

module.exports = { // Use CommonJS module here to be imported by webpack.config file.
  ComponentBundleMap, sharedIframeWrapperURL,
};
