import React, { useContext, createContext, memo, FC, PropsWithChildren } from 'react';

import { useGlobalReactContext } from './global-react-context';
import { getUrlPilots } from '../../../FunctionModules/PilotManager/url-piloting';

export interface UrlPilotContextType {
  isAlwaysExport: boolean
  separateBluesnapForm: boolean
  exportCardMock: boolean
  pidlForceReject: boolean
  logPidlEvent: boolean
  updateAddressesOnPidlDownloaded: boolean
  clickLinkToSaveWallet: boolean
}

export const urlPilots = getUrlPilots();

const UrlPilotContext = createContext<UrlPilotContextType>(urlPilots as any);

export const UrlPilotContextProvider: FC<PropsWithChildren<{}>> = memo(({ children }) => {
  const { pilots = {} } = useGlobalReactContext();

  return (
    <UrlPilotContext.Provider
      value={{
        ...pilots,
        ...urlPilots,
      }}
    >
      {children}
    </UrlPilotContext.Provider>
  );
});

export const useUrlPilot = () => useContext(UrlPilotContext);
