import { isInIframe } from '../../Shared/Utils/is-in-iframe';
import { AKSHostLogServiceEndPoint } from '../../Shared/Utils/xpay-env-values';

enum LogMessagType {
    AskForLogEndpoint = 'AskForLogEndpoint',
    AnswerLogEndpoint = 'AnswerLogEndpoint',
}

export const askParentWindowForEndpointIfInIframe = (setLoggingOrigin: (origin: string) => void, setUseAKS: (use: boolean) => void) => {
  if (isInIframe()) {
    window.parent.postMessage({
      type: LogMessagType.AskForLogEndpoint,
    }, '*');
    window.addEventListener('message', (event) => { // TODO: Add origin check
      if (event.data.type === LogMessagType.AnswerLogEndpoint) {
        setLoggingOrigin(event.data.logEndpoint);
        setUseAKS(event.data.useAKSHost);
      }
    });
  }
};


export const answerIframeLogEndpoint = (useAKSHostLogService: boolean) => {
  window.addEventListener('message', (event) => {
    if (event.data.type === LogMessagType.AskForLogEndpoint) {
      (event.source as Window).postMessage({
        type: LogMessagType.AnswerLogEndpoint,
        logEndpoint: AKSHostLogServiceEndPoint,
        useAKSHost: useAKSHostLogService,
      }, event.origin);
    }
  });
};
