import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import { siteUrl } from '../../Shared/Utils/xpay-env-values';
import { globalContext } from '../GlobalContext/global-context';
import { LogData, LogEntity, LogLevel } from './types';
import { isUXLoggerEnabled } from '../PilotManager/features/enable-ux-logger';

const LOG_PATH = '/Log/LogClientSideBatch';

let logCounter: number = 0;
const getLogSequence = () => {
  logCounter += 1;
  return logCounter;
};

let logQueue: LogEntity[] = [];

export const getLogQueue = () => logQueue;

export const clearLogQueue = () => {
  logQueue = [];
};
export const addToLogQueue = (newLogs: LogEntity[]) => {
  _.each(newLogs, log => logQueue.push(log));
};

export const addUXLogQueue = (
  logLevel: LogLevel,
  message: LogData,
  correlationId?: string,
  url?: string,
  timestamp = new Date(),
  extraEntries: object = {}
) => {
  const logSeq = getLogSequence();
  const logId = `${logSeq}`;
  const entity = _.extend(
    {
      logId,
      logSeq,
      luid: uuidv4(),
      timestamp,
      data: message,
      logLevel,
    },
    extraEntries,
    globalContext.accountId && { accountId: globalContext.accountId },
    globalContext.sessionId && { sessionId: globalContext.sessionId },
    globalContext.scenarioName && { scenarioName: globalContext.scenarioName },
    globalContext.sdkAppName && { sdkAppName: globalContext.sdkAppName },
    { pilotFlags: JSON.stringify(globalContext.pilotFlags) },
    correlationId && { correlationId },
    url && { url: window.location.href.replace(window.location.hash, '') }
  );

  logQueue.push(entity);
};

const prepLogsForUpload = (logs: LogEntity[], occasion: string) => JSON.stringify({
  defaultUrl: window.location.href.replace(window.location.hash, ''),
  defaultCorrelationId: globalContext.correlationId,
  sessionId: globalContext.sessionId,
  hostPageId: globalContext.hostPageId,
  isInIframe: globalContext.isInIframe,
  sdkAppName: globalContext.sdkAppName,
  sdkVer: globalContext.sdkVer,
  ...globalContext.additionalLogProperties,
  occasion,
  logs,
});

export function logUXData(isAsync = false, occasion = '') {
  if (!isUXLoggerEnabled()) {
    return;
  }
  const batchToLog = getLogQueue();
  if (batchToLog.length) {
    try {
      clearLogQueue();
      // log synchronously on unload (async requests will get cancelled on unload)
      const client = new XMLHttpRequest();
      client.open('POST', `${siteUrl}${LOG_PATH}`, isAsync);
      client.setRequestHeader('Content-Type', 'application/json');
      client.withCredentials = true;
      client.onreadystatechange = () => {
        // In local files, status is 0 upon success in Mozilla Firefox
        if (client.readyState === XMLHttpRequest.DONE) {
          const { status } = client;

          if (status === 0 || (status >= 200 && status < 400)) {
            return;
          }
          // if failure occurs, add back to logQueue
          addToLogQueue(batchToLog);
        }
      };
      client.send(prepLogsForUpload(batchToLog, occasion));
    } catch (err) {
      console.log(`Failed to log due to error ${err}`);
      // TODO: retry if failure occurs
      addToLogQueue(batchToLog);
    }
  }
}

export function logOneUXMessage(message: LogData) {
  if (!isUXLoggerEnabled() || logCounter > 0) {
    return;
  }
  addUXLogQueue(LogLevel.Information, message);
  logUXData();
}
