import { useEffect, useMemo } from 'react';
import _ from 'underscore';
import { useImmediateAjaxWithDataStore } from '../hooks/immediate-ajax-hook';
import { useAuthorization } from '../context/authZ-context';
import { addTokenDescriptor } from '../../Utils/token-header-utils';
import { useGlobalReactContext } from '../context/global-react-context';
import { useCv } from '../hooks/use-cv';
import { useUrlPilot } from '../context/url-piloting-react';
import { logError, logInfo } from '../../../FunctionModules/Logger/log';
import { siteUrl } from '../../Utils/xpay-env-values';

export const usePiList = (creditCardEnabled, paypalEnabled, showLoadingPanel = true, allowedTokenProviders = [], piFilter = null) => {
  const { pidlAuthZ } = useAuthorization();
  const { country } = useGlobalReactContext();
  const CV = useCv();
  const { isAlwaysExport } = useUrlPilot();

  const { result, ...otherReturns } = useImmediateAjaxWithDataStore(
    'piList',
    {
      url: `${siteUrl}/api/paymentinstr/list`,
      data: {
        MsaToken: addTokenDescriptor(pidlAuthZ),
        Country: country,
        CV,
        CreditCardEnabled: creditCardEnabled,
        PaypalEnabled: paypalEnabled,
        allowedTokenProviders,
      },
    },
    showLoadingPanel,
    !pidlAuthZ
  );

  const piList = useMemo(
    () => {
      if (isAlwaysExport) {
        const returnVal = _.map(result, pi => _.omit(pi, 'IsTokenized', 'XPayId'));
        if (piFilter && returnVal) {
          return returnVal.filter(piFilter);
        }
        return returnVal;
      }
      if (piFilter && result) {
        return result.filter(piFilter);
      }
      return result;
    },
    [isAlwaysExport, result, piFilter]
  );

  useEffect(() => {
    if (!otherReturns?.isLoading && !otherReturns?.error) {
      logInfo('successfully fetched pi list'); // Deprecating. Do not use it.
      logInfo(`Fetch PI List success, PI count:${result?.length}`);
      const existingPIListlog = result?.length > 0 ? `Existing PI count=${result.length}` : 'No existing PI';
      logInfo(`Fetch PI List success. ${existingPIListlog}`);
    }
    if (otherReturns?.error && pidlAuthZ) {
      logError('Fetch PI list failed');
    }
  }, [otherReturns?.error, otherReturns?.isLoading, pidlAuthZ, result?.length]);

  return { piList, ...otherReturns };
};
