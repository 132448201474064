export enum EventCounterNames {
  // Donation
  SERPButtonImpression = 'Counter.Donation.SERP.Button.Impression', // Will be deprecated
  GwBButtonImpression = 'Counter.Donation.GwB.Button.Impression', // Will be deprecated
  SERPImpression = 'Counter.Donation.SERP.Impression',
  GwBImpression = 'Counter.Donation.GwB.Impression',
  // Rebates
  RebatesButtonImpression = 'Counter.Rebates.Button.Impression',
  RebatesButtonClick = 'Counter.Rebates.Button.Click',
  RebatesPIAdded = 'Counter.Rebates.PIAdded.Success',
  // BeaconTip
  BeaconTipButtonClick = 'Counter.BeaconTip.Button.Click',
  BeaconTipWithFreeToken = 'Counter.BeaconTip.FreeToken.Click',
  // EdgeTipping
  EdgeTippingPanelUserLanded = 'Counter.EdgeTipping.Panel.UserLanded',
  // Subscription
  SubscriptionOverlayImpression = 'Counter.Subscription.Overlay.Impression',
  SubscriptionWithFreeToken = 'Counter.Subscription.FreeToken.Click',
  // Forum
  ForumPaymentSectionImpression = 'Counter.Forum.PaymentSection.Impression',
  // BuyNow
  BuynowButtonImpression = 'Counter.Buynow.Button.Impression',
}

export interface MetricType {
  name: string;
  dimensions: string[];
  value?: number;
}

const DonationEngagement: MetricType = { name: 'Metric.Donation.Engagement', dimensions: ['Scenario'] };
const DonationFunnel: MetricType = { name: 'Metric.Donation.Funnel', dimensions: ['FunnelStep', 'Additional'] };
const SubscriptionEngagement: MetricType = { name: 'Metric.Subscription.Engagement', dimensions: ['Version', 'Entry'] };
const BeaconEngagement: MetricType = { name: 'Metric.Beacon.Engagement', dimensions: ['Scenario'] };
const ForumEngagement: MetricType = { name: 'Metric.Forum.Engagement', dimensions: ['Scenario'] };
const MerchantOnboarding: MetricType = { name: 'Metric.Merchant.Onboarding', dimensions: ['Event', 'ClientName'] };
const BuynowEngagement: MetricType = { name: 'Metric.Buynow.Engagement', dimensions: ['Scenario'] };

export const MetricTypes = {
  DonationEngagement,
  DonationFunnel,
  SubscriptionEngagement,
  BeaconEngagement,
  ForumEngagement,
  MerchantOnboarding,
  BuynowEngagement,
};
