import { useContext } from 'react';
import { siteUrl } from '../../../Shared/Utils/xpay-env-values';
import { ajaxCall } from '../ajax-helper';
import { IframeAjaxContext } from './context';

export const useXpayDomainAjaxCall = () => {
  const isXpayHostedPage = window.location.href.indexOf(siteUrl) === 0;

  const iframeAjaxCall = useContext(IframeAjaxContext) || ajaxCall;

  return isXpayHostedPage ? ajaxCall : iframeAjaxCall;
};
