import React, { useCallback, useEffect, useState } from 'react';

import { useBoolean } from '@uifabric/react-hooks';
import { Checkbox, Panel, PanelType, Stack, TextField, DefaultButton, ICheckboxStyles } from '@fluentui/react';
import { TokenOptions } from '../../../FunctionModules/MSALogin/constants';
import * as APIConstants from '../../../BusinessLogic/XPayTransaction/constants';
import { usePiPicker } from '../../../Shared/Components/pi-picker';
import { useI18n } from '../../../FunctionModules/Localization/i18n';
import { useInputChangeCallback } from '../../../Shared/Components/hooks/react-utils';
import '../../../Shared/Components/styles/index.less';
import PI_TYPE from '../../../Shared/Entities/pi-types';
import { PidlValidationErrors } from '../../../Shared/Components/add-pi-pidl/pidl-errors';
import { useGlobalReactContext } from '../../../Shared/Components/context/global-react-context';
import { useAuthorization, useUpdateAuthorization } from '../../../Shared/Components/context/authZ-context';
import { makePopUpWindowConfig, openMsaAuthWindow } from '../../../FunctionModules/MSALogin/msa-auth-utils';
import { pidlImageUrl } from '../../../Shared/Utils/xpay-env-values';
import { logError, logInfo } from '../../../FunctionModules/Logger/log';
import { ubEvent } from '../../../FunctionModules/Logger/log-instrument';
import { validateCard } from '../../../Shared/Utils/card-validation';
import { globalConfig } from '../../../Shared/Configs/config';
import { PiDetailsFromPIDLForm } from '../../../Shared/Components/pi-picker-card-mode/pi-list-of-card-mode';
import { PiDetailsFromPIPicker } from '../../Subscription/Components/interface';
import { useExportPi } from '../../../Shared/Components/hooks/useExport';
import { useLoader } from '../../../Shared/Components/context/loading-context';
import { CloError } from './sapphire-pidl-form';
import { TermsAgreement } from './terms-agreement';
import { useIframeAddPIPidl } from '../../../Shared/Components/add-pi-pidl/iframe/iframe-wrapper';

const checkboxStyles: ICheckboxStyles = {
  root: {
    marginTop: 12,
  },
  label: {
    fontSize: '11px!important',
    fontFamily: '"Roboto",Sans-Serif!important',
    alignItems: 'flex-start',
  },
  text: {
    fontSize: '11px!important',
    fontFamily: '"Roboto",Sans-Serif!important',
  },
};

export interface PidlFormResponse{
  err: CloError,
  cardNumber: string,
  isCardSaved: boolean,
}

const stackTokens = { childrenGap: 10 };

const {
  popupWindowWidth,
  popupWindowHeight,
} = globalConfig;
const popupConfig = makePopUpWindowConfig(popupWindowWidth, popupWindowHeight);

interface NewCardPI {
  cardNumber: string;
  err: CloError;
}

export const CLORenderer = ({
  open,
  onFinish,
  supportedCardTypes = ['amex', 'mc', 'visa', 'discover'],
  piListFilter,
} : {
    open: boolean,
    onFinish:(data:PidlFormResponse) => void,
    supportedCardTypes: string[],
    piListFilter: (pi: any) => boolean,
  }) => {
  const [isOpen, { setFalse: hideDialog }] = useBoolean(open);
  const [cardNumber, setCardNumber] = useState('');
  const [agreeToTOS, { toggle: toggleAgreeeToTOS }] = useBoolean(false);
  const [saveCard, { toggle: toggleSaveCard, setFalse: setSaveCardFalse }] = useBoolean(false);
  const [saveCardDisabled, { setTrue: setSaveCardDisabled, setFalse: setSaveCardEnabled }] = useBoolean(false);
  const handleCardNumberInputChange = useInputChangeCallback(setCardNumber);
  const { i18n } = useI18n();
  const [isValid, setIsValid] = useState(false);
  const [errors, setPIDLValidationError] = useState(null);
  const {
    country,
  } = useGlobalReactContext();
  const { pidlAuthZ, xpayAuthZ } = useAuthorization();
  const { pidlAuthZ: setPidlAuthZ, xpayAuthZ: setXpayAuthZ } = useUpdateAuthorization();
  const {
    pidlForm, submitPidl, isValid: isPidlValid, validationErrors, piDetails,
  } = useIframeAddPIPidl({
    country,
    shouldLoadPidl: true,
    piType: PI_TYPE.CARD,
    prefillValues: { accountToken: cardNumber },
  });

  const setIsLoading = useLoader();

  useEffect(() => {
    setPIDLValidationError(null);
  }, [piDetails, cardNumber]);

  useEffect(() => {
    const isCardNumberValid = (enteredCardNumber = '') => {
      const { isValid: isCardValid, cardType } = validateCard(enteredCardNumber);
      if (isCardValid && supportedCardTypes.indexOf(cardType) !== -1) {
        return true;
      }
      return false;
    };

    if (!isCardNumberValid(cardNumber)) {
      setSaveCardDisabled();
      setSaveCardFalse();
    } else {
      setSaveCardEnabled();
    }
  }, [cardNumber, setSaveCardDisabled, setSaveCardFalse, setSaveCardEnabled, supportedCardTypes]);

  useEffect(() => {
    setIsValid(!saveCard && cardNumber.length >= 13 && cardNumber.length <= 19);
  }, [cardNumber.length, saveCard]);

  useEffect(() => {
    const getAuthZ = async () => {
      if (!xpayAuthZ) {
        logInfo('Donate Auth Missing: authToken is empty.');
        const { clientAuthToken, PIListToken: newPiListToken } =
          await openMsaAuthWindow(popupConfig, [TokenOptions.IdToken, TokenOptions.PIFDToken]);
        setXpayAuthZ(clientAuthToken);
        setPidlAuthZ(newPiListToken);
      } else if (!pidlAuthZ) {
        logInfo('Donate Auth Missing: PIListToken is empty.');
        const { PIListToken: newPiListToken } =
          await openMsaAuthWindow(popupConfig, [TokenOptions.PIFDToken]);
        setPidlAuthZ(newPiListToken);
      }
    };
    getAuthZ().then(() => {
      logInfo('Auth Completed, opening panel');
    });
  }, [pidlAuthZ, setPidlAuthZ, setXpayAuthZ, xpayAuthZ]);

  const {
    piPicker,
    showPiAddForm,
    piDetails: piPickerDetails,
    cvvInput,
  } = usePiPicker({
    defaultUseDropdown: true,
    allowedTokenProviders: [APIConstants.PaymentInstrumentTokenProvider.BlueSnap],
    additionalInfo: null,
    cvvLabelName: i18n.EnterCvv,
    cvvPlaceholder: i18n.Cvv,
    PiPickerLabelName: i18n.PaymentMethod,
    customFilter: piListFilter,
  });
  const exportCard = useExportPi(popupConfig);
  const getPI = useCallback(async () : Promise<NewCardPI> => {
    const piDetailsFromPIPicker = piPickerDetails as PiDetailsFromPIPicker;
    try {
      const exportCardNumber = await exportCard(piDetailsFromPIPicker.PiId, piDetailsFromPIPicker.CVV);
      return {
        cardNumber: exportCardNumber,
        err: null,
      };
    } catch (e) {
      return {
        cardNumber: '',
        err: CloError.ExportCardError,
      };
    }
  }, [piPickerDetails, exportCard]);

  const onSuccess = useCallback(async () => {
    let cardSaved = false;
    ubEvent('Submit clicked');
    if (showPiAddForm && saveCard && !isPidlValid) {
      ubEvent('Error when SubscribeBtnClicked', { validationErrors: (validationErrors || []).join(',') });
      setPIDLValidationError(validationErrors);
      return;
    }
    if (showPiAddForm && !saveCard && !isValid) {
      const error = cardNumber.length === 0
        ? ['CardNumber is required']
        : ['Card Number is invalid'];
      ubEvent('Error when SubscribeBtnClicked', { validationErrors: error });
      setPIDLValidationError(error);
      return;
    }
    setPIDLValidationError(null);
    let card = cardNumber;
    let error;
    if (saveCard) {
      try {
        await submitPidl();
        cardSaved = true;
      } catch (err) {
        logError({
          message: 'SaveCardFailed',
          err,
        });
        error = CloError.SaveCardError;
      }
      card = ((piDetails as PiDetailsFromPIDLForm).CardNumber);
      ubEvent('User successfully saved the card to the MSA account');
    } else if (!showPiAddForm) {
      setIsLoading(true);
      ({ cardNumber: card, err: error } = await getPI());
      ubEvent('Card linked with saved card');
      setIsLoading(false);
    }
    onFinish({ cardNumber: card, err: error, isCardSaved: cardSaved });
    hideDialog();
  }, [showPiAddForm, setIsLoading, saveCard, isPidlValid, isValid, cardNumber, onFinish, hideDialog, validationErrors, piDetails, submitPidl, getPI]);

  const renderTermsLabel = useCallback(() => (
    <TermsAgreement />
  ), []);

  const onRenderFooterContent = useCallback(() => (
    <DefaultButton text={i18n.Confirm} disabled={!agreeToTOS || (saveCardDisabled && showPiAddForm)} onClick={onSuccess} />
  ), [agreeToTOS, onSuccess, i18n, saveCardDisabled, showPiAddForm]);

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={hideDialog}
      type={PanelType.custom}
      customWidth="436px"
      closeButtonAriaLabel={i18n.Close}
      headerText={i18n.CloHeader}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
    >
      <Stack tokens={stackTokens}>
        {piPicker}
        {showPiAddForm && !saveCard &&
        <Stack tokens={stackTokens}>
          <Stack.Item>
            <div className="pidlddc-div-logo">
              { supportedCardTypes.indexOf('visa') !== -1 && <img alt="" src={`${pidlImageUrl}logo_visa.svg`} /> }
              { supportedCardTypes.indexOf('amex') !== -1 && <img alt="" src={`${pidlImageUrl}logo_amex.svg`} /> }
              { supportedCardTypes.indexOf('mc') !== -1 && <img alt="" src={`${pidlImageUrl}logo_mc.svg`} /> }
              { supportedCardTypes.indexOf('discover') !== -1 && <img alt="" src={`${pidlImageUrl}logo_discover.svg`} /> }
            </div>
          </Stack.Item>
          <Stack.Item>
            <TextField id="xpay_clo_cardNumber" label={i18n.CardNumber} onChange={handleCardNumberInputChange} />
          </Stack.Item>
        </Stack>
        }
        {showPiAddForm && !saveCardDisabled &&
        <Checkbox
          styles={checkboxStyles}
          label={i18n.Save_PI_Label}
          checked={saveCard}
          onChange={toggleSaveCard}
        />
        }
        {!showPiAddForm && cvvInput}
        {showPiAddForm && saveCard && pidlForm}
        {showPiAddForm && errors &&
        <PidlValidationErrors PIDLValidationErrors={errors} />
        }
        <Checkbox
          styles={checkboxStyles}
          checked={agreeToTOS}
          onChange={toggleAgreeeToTOS}
          onRenderLabel={renderTermsLabel}
        />
      </Stack>
    </Panel>
  );
};
