// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved, import/extensions
import styleLoader from '!css-loader?sourceMap=false!./iframe-style.css';

// theoretically, we should be able to minify this
import { addStyleToHeadSection } from '../../Shared/Utils/style-helper';

export const getIframeCSSContent = () => styleLoader.toString();

export function addSpinnerToDocument(affectedDocument, spinnerMessage) {
  const panelSpinner = document.createElement('div');
  panelSpinner.classList.add('spinner-loader');
  const panelSpinnerLoader = document.createElement('div');
  const panelSpinnerLabel = document.createElement('label');
  panelSpinnerLabel.textContent = spinnerMessage;
  panelSpinner.appendChild(panelSpinnerLoader);
  panelSpinner.appendChild(panelSpinnerLabel);

  addStyleToHeadSection(affectedDocument.head, getIframeCSSContent());
  affectedDocument.body.appendChild(panelSpinner);
}

export function addSpinnerToIframe(iframe, i18n) {
  addSpinnerToDocument(iframe.contentDocument, i18n.Loading);
}
