import { answerIframeLogEndpoint } from '../../FunctionModules/Logger/set-iframe-endpoint';
import { insertSilentAuthIframeIntoBody } from '../../FunctionModules/MSALogin/insert-silent-auth-iframe-utils';
import { logPagePerfEvent } from '../../FunctionModules/Logger/log-instrument';
import { TokenOptions, trySilentMSAuth } from '../../FunctionModules/MSALogin/msa-auth-utils';

export async function silentAuth() {
  answerIframeLogEndpoint(true);
  insertSilentAuthIframeIntoBody('silent-auth');
  logPagePerfEvent('Start silent MSA Auth');
  const result = await trySilentMSAuth('silent-auth', [TokenOptions.PIFDToken]);
  if (result) {
    logPagePerfEvent('Silent MSA Auth succeed');
  } else {
    logPagePerfEvent('Silent MSA Auth failed');
  }
  return result?.PIListToken;
}
