import React, { createContext, useContext, useReducer, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';

const DataStoreContext = createContext({});

export const useDataStoreContext = () => useContext(DataStoreContext);

export const skipRequestError = 'skip';

const reducer = (object, newValues) => ({ ...object, ...newValues });

export const DataStoreContextProvider = memo(({ children }) => {
  const [dataStore, addToDataStore] = useReducer(reducer, {});

  return (
    <DataStoreContext.Provider value={{ dataStore, addToDataStore }} >
      {children}
    </DataStoreContext.Provider>
  );
});
DataStoreContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
DataStoreContextProvider.displayName = 'DataStoreContextProvider';

export const useGetData = (key, getDataFunction, skip = false) => {
  const { dataStore, addToDataStore } = useContext(DataStoreContext);
  const { isLoading, result, error } = dataStore[key] || { };

  const update = useCallback((data) => {
    addToDataStore({ [key]: data });
  }, [addToDataStore, key]);

  const reset = useCallback(() => {
    update(undefined);
  }, [update]);

  const firstRender = !isLoading && !result && !error;
  // return isLoading is true when all are false, because loading will be started by the effect
  const correctedIsLoading = isLoading || firstRender;

  // TODO: fix so callback only called once if requested by multiple components
  useEffect(() => {
    if (correctedIsLoading && !skip) {
      update({ isLoading: true });
      getDataFunction().then((res) => {
        update({ result: res, isLoading: false });
      }).catch((err) => {
        update({ error: err, isLoading: false });
      });

      return () => {};
    }
    return undefined;
  }, [getDataFunction, update, correctedIsLoading, skip]);

  if (skip) {
    return {
      isLoading: false,
      result: null,
      error: skipRequestError,
      reset,
    };
  }
  return {
    isLoading: correctedIsLoading, result, error, reset,
  };
};
