import React, { createContext, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useGlobalReactContext } from '../../../Shared/Components/context/global-react-context';
import { requestResponse, listenPersistently } from '../../FrameMessageBus/index';
import IframeWrapper from '../../IframeWrapper/iframe-wrapper-react';
import {
  ComponentBundleMap,
  sharedIframeWrapperURL
} from '../../../Shared/BundlesInIframe/bundle-constants';
import { MessageTypes, AjaxIframeProperties } from './constants';
import { AjaxServerError } from '../../ErrorHandling/error-types';
import { useAuthorization } from '../../../Shared/Components/context/authZ-context';
import { logError, logInfo } from '../../Logger/log';
import { siteUrl } from '../../../Shared/Utils/xpay-env-values';

const MAX_RETRIES = 2;

export const IframeAjaxContext = createContext(undefined);

const hiddenStyles = { display: 'none' };

export const IframeAjaxContextProvider = memo(({ children }: { children: JSX.Element }) => {
  const { xpayClientName } = useGlobalReactContext();
  const { xpayAuthZ } = useAuthorization();

  const isXpayHostedPage = window.location.origin.indexOf(siteUrl) === 0;

  const iframeRef = useRef<HTMLIFrameElement>();

  const [retriesRemaining, setRetriesRemaining] = useState(MAX_RETRIES);
  const [renderIframeIfReady, setRenderIframeIfReady] = useState(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const renderIframe = xpayAuthZ && renderIframeIfReady;

  const iframeAjaxCall = useCallback((...args) => (isLoaded
    ? requestResponse({
      type: MessageTypes.AjaxMessage,
      targetWindow: iframeRef?.current?.contentWindow,
      sendData: args,
      originOverride: siteUrl,
    }).catch((err) => {
      // convert AjaxServerError to current window's version, so typechecks can be successful
      if (err.name === 'AjaxServerError') {
        throw new AjaxServerError({
          message: err.message,
          errorCodes: err.errorCodes,
          additionalDetails: err.additionalDetails,
        });
      }

      // other errors *should* still be handled okay
      throw err;
    }).finally(() => {
      logInfo('Iframe Ajax call sent');
    })
    : Promise.reject(new Error('Ajax Iframe not loaded'))), [isLoaded]);

  const { current: formParameterDict } = useRef({
    origin: window.location.origin,
  });

  const iframeLoadedCleanupFunc = useRef<() => void>();
  // if iframe is visible, but not loaded, listen for isLoaded event
  useEffect(() => {
    if (renderIframe && !isLoaded) {
      return listenPersistently({
        type: MessageTypes.IsLoaded,
        actionCb: () => {
          setIsLoaded(true);
          logInfo('Ajax Iframe is loaded');
          iframeLoadedCleanupFunc.current?.();
        },
        originOverride: siteUrl,
      });
    }
    return undefined;
  }, [isLoaded, renderIframe]);

  // if iframe load error page due to server issue, stop retrying and rendering iframe
  useEffect(() => {
    if (renderIframe && !isLoaded) {
      return listenPersistently({
        type: MessageTypes.ServerError,
        actionCb: () => {
          setRetriesRemaining(0);
          setRenderIframeIfReady(false);
        },
        originOverride: siteUrl,
      });
    }
    return undefined;
  }, [isLoaded, renderIframe]);

  useEffect(() => {
    if (!isLoaded && renderIframe && iframeRef.current) {
      let timeoutId: ReturnType<typeof setTimeout>;
      const iframe = iframeRef.current;

      // if this isn't cleaned up before 2 seconds after iframe is loaded,
      // then setRenderIframe to false, so it can be re-loaded
      const onLoadFunc = () => {
        timeoutId = setTimeout(() => {
          setRenderIframeIfReady(false);
        }, 2000);
      };

      iframe.addEventListener('load', onLoadFunc);

      const cleanup = () => {
        clearTimeout(timeoutId);
        iframe?.removeEventListener('load', onLoadFunc);
        iframeLoadedCleanupFunc.current = null;
      };
      iframeLoadedCleanupFunc.current = cleanup;

      // clear timout and event listener if cleanup is called (if iframe is loaded, or removed)
      return cleanup;
    }
    return undefined;
  }, [isLoaded, renderIframe, retriesRemaining]);

  // if renderIframeIfReady is set to false, set back to true if retries left, to re-add the iframe (to reload it)
  useEffect(() => {
    if (!renderIframeIfReady) {
      iframeRef.current = undefined;
      if (retriesRemaining === 0) {
        logError('Failed to load Ajax Iframe after retries');

        logInfo(`typeof xpayAuthZ ${typeof xpayAuthZ}`);
        logInfo(`xpayAuthZ.length ${xpayAuthZ?.length}`);
      } else {
        logInfo('retrying loading ajax iframe');
        setRenderIframeIfReady(true);
        setRetriesRemaining(retriesRemaining - 1);
      }
    }
  }, [renderIframeIfReady, retriesRemaining, xpayAuthZ]);

  if (isXpayHostedPage) {
    return children;
  }

  return (
    <IframeAjaxContext.Provider value={iframeAjaxCall} >
      {children}
      {renderIframe && (
      <IframeWrapper
        ref={iframeRef}
        iframeStyles={hiddenStyles}
        wrapperUrl={sharedIframeWrapperURL}
        iframeTitle="IframeAjax"
        iframeProperties={AjaxIframeProperties}
        formParameterDict={formParameterDict}
        bundleEntryFile={ComponentBundleMap.AjaxIframe}
        xpayClientName={xpayClientName}
        skipAddingTokens
        showLoadingIconInitially={false}
      />
        )}
    </IframeAjaxContext.Provider>
  );
});
IframeAjaxContextProvider.displayName = 'IframeAjaxContextProvider';
