import React, { memo } from 'react';
import ReactDOM from 'react-dom';
import './clo-app-setup';
import { validateAuthToken } from '../../BusinessLogic/XPayTransaction/XPayAPI';
import { setAuthToken } from '../../FunctionModules/AjaxHelper/ajax-helper';
import { setDefaultOrigin } from '../../FunctionModules/FrameMessageBus';
import { globalContext } from '../../FunctionModules/GlobalContext/global-context';
import { withContexts } from '../../Shared/Components/context';
import { EnvList } from '../../Shared/Configs/config';
import { CLORenderer } from './Components/clo-panel';

import {
  getCorrelationId,
} from '../../FunctionModules/Logger/log-uploader';
import { PartnerThemeList } from '../../FunctionModules/Themes/constants';
import { siteUrl } from '../../Shared/Utils/xpay-env-values';
import { addFluentIcons } from '../../Shared/Utils/style-helper';
import { XPayClientNameForClo } from './constants';
import { silentAuth } from '../../Shared/Utils/silent-auth';
import { CloError } from './Components/sapphire-pidl-form';


let PIListToken: string;
silentAuth().then((result) => { PIListToken = result; });

interface RenderCLOParams {
  localeId: string;
  authToken: string;
  onFinish: any;
  supportedCardTypes?: string[];
}

const getReactContainer = () => {
  let reactContainer = document.getElementById('xpay-clo-panel');
  if (!reactContainer) {
    reactContainer = document.createElement('div');
    reactContainer.setAttribute('id', 'xpay-clo-panel');
    document.body.appendChild(reactContainer);
  }
  return reactContainer;
};

setDefaultOrigin(siteUrl);
addFluentIcons();

const renderCLO = async (
  params: RenderCLOParams
) => {
  setAuthToken(params.authToken);
  try {
    await validateAuthToken(siteUrl);
    const reactContainer = getReactContainer();

    const supportedCardTypes = params.supportedCardTypes || ['amex', 'mc', 'visa', 'discover'];

    const piListFilter = (pi: any) => supportedCardTypes.indexOf(pi.CardType) !== -1;
    const clo = memo(() => (
      <CLORenderer open onFinish={params.onFinish} supportedCardTypes={supportedCardTypes} piListFilter={piListFilter} />
    ));

    const CloWithContext = withContexts(clo, {
      country: 'us',
      locale: params.localeId,
      origin: siteUrl,
      theme: PartnerThemeList.Clo,
      xpayAuthZToken: params.authToken,
      xpayClientName: XPayClientNameForClo,
      correlationId: getCorrelationId(),
      currencySymbol: '$',
      scenarioName: globalContext.scenarioName,
      sessionId: globalContext.sessionId,
      hostPageId: globalContext.hostPageId,
    }, { pidlAuthZ: PIListToken });
    ReactDOM.render(<CloWithContext />, reactContainer);
  } catch (err) {
    params.onFinish({ cardNumber: '', err: CloError.AuthError });
  }
};

const clo = {
  renderCLO, EnvList, CloError,
};
export default clo;
