import _ from 'underscore';

export const convertPilotToBool = (val, isEnabledByDefault) => {
  if ((val === null || val === undefined) && !isEnabledByDefault) {
    return false;
  }
  if (typeof val === 'string') {
    switch (val.toLowerCase()) {
      case 'false':
      case '0':
        return false;
      case 'true':
      case '1':
      default:
        return true;
    }
  }
  return true;
};

export const getUrlPilots = (isEnabledByDefault) => {
  const { search } = window.location;

  const params = search.replace('?', '').split('&');

  return _.reduce(params, (memo, param) => {
    // has no value, isn't a url pilot
    if (param.indexOf('=') === -1 && !isEnabledByDefault) {
      return memo;
    }

    const [key, value] = param.split('=');
    return {
      ...memo,
      [key]: convertPilotToBool(value, isEnabledByDefault),
    };
  }, {});
};

export const getUrlPilotString = () => {
  const { search } = window.location;

  return search && search.length > 0
    ? search.substring(1)
    : '';
};
