import { useCallback, useRef } from 'react';
import _ from 'underscore';

export const useInputChangeCallback = setValue => useCallback(
  ({ target: { value } }) => {
    setValue(value);
  },
  [setValue]
);

export const useDeepObjectMemo = (obj) => {
  const objRef = useRef(obj);

  if (!_.isEqual(objRef.current, obj)) {
    objRef.current = obj;
  }
  return objRef.current;
};
