import Cookies from 'js-cookie';
import { globalContext } from '../../FunctionModules/GlobalContext/global-context';
import { LogEntity, LogLevel } from './types';

let muid = Cookies.get('MUID');

const getMUID = () => {
  // Sometimes MUID cookie is ready than first log starts so ensure MUID has been populated
  // On page unload event, cookies are not available so persiste MUID in variable
  if (!muid) {
    muid = Cookies.get('MUID');
  }
  return muid;
};

let anon = Cookies.get('ANON');

const getANON = () => {
  // Sometimes ANON cookie is ready before logging starts so additional check here ensures ANON could been populated
  // On page unload event, cookies are not available so persiste ANON in variable
  if (!anon) {
    anon = Cookies.get('ANON');
  }
  return anon;
};

export const cookLogWithContext = (log: LogEntity) => ({
  url: window.location?.href?.replace(window.location.hash, ''),
  referral: window.document?.referrer,
  correlationId: globalContext.correlationId,
  sessionId: globalContext.sessionId,
  hostPageId: globalContext.hostPageId,
  isInIframe: globalContext.isInIframe,
  sdkAppName: globalContext.sdkAppName,
  sdkVer: globalContext.sdkVer,
  featureName: globalContext.featureName,
  bundleEntryName: globalContext.bundleEntryName,
  muid: getMUID(),
  anon: getANON(),
  market: globalContext.marketMetaData,
  pilotFlags: globalContext.pilotFlags,
  ...globalContext.additionalLogProperties,
  flightIds: globalContext.flightIds,
  bizContext: globalContext.bizContext,
  ...log,
});

export const getLogLevelLabel = (level: LogLevel) => {
  if (level === LogLevel.Error) {
    return 'Error';
  } else if (level === LogLevel.Warning) {
    return 'Warning';
  } else if (level === LogLevel.Information) {
    return 'Information';
  } else if (level === LogLevel.Metric) {
    return 'Metric';
  }
  return 'Unknown';
};
