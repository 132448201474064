import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Image, Link } from '@fluentui/react';
import PiTypes from '../../Entities/pi-types';

const iconStyles = {
  root: {
    display: 'inline',
  },
  image: {
    height: 20,
    marginRight: 5,
    marginBottom: 5,
    verticalAlign: 'middle',
    borderStyle: 'none',
    display: 'inline',
  },
};
const getIcon = (card) => {
  if (card && card.CardTypeDisplayLogoUrl) {
    return (
      <Image
        src={card.CardTypeDisplayLogoUrl}
        alt={card.CardTypeDisplayName}
        styles={iconStyles}
      />
    );
  }

  return null;
};

export const getMaskedLast4Digits = card => `**** ${card.CardNumber.substr(-4)}`;

const formatCardText = (card) => {
  const { ExpiryMonth, ExpiryYear } = card;

  let last2YearDigits = ExpiryYear;
  if (ExpiryYear.length > 2) {
    last2YearDigits = ExpiryYear.substr(ExpiryYear.length - 2);
  }

  return `${getMaskedLast4Digits(card)} Exp ${ExpiryMonth}/${last2YearDigits}`;
};
const formatCardTextWithCardType = card => `${card.CardTypeDisplayLogoUrl} ${formatCardText(card)}`;


const getInlineCardRender = (card) => {
  const icon = getIcon(card);
  const text = icon === null && card && card.CardType
    ? formatCardTextWithCardType(card)
    : formatCardText(card);

  return (
    <React.Fragment>
      {icon}
      <span>{text}</span>
    </React.Fragment>
  );
};
const getInlinePaypalRender = (pp, getString) => getString('PaypalName', { email: pp.Email });

export const getInlinePiRender = (pi, getString) => {
  switch (pi.PaymentType) {
    case PiTypes.CARD:
      return getInlineCardRender(pi);
    case PiTypes.PAYPAL:
      return getInlinePaypalRender(pi, getString);
    default: return null;
  }
};

export const onRenderOption = (pi, getString) => (
  <div>
    {pi.key === PiTypes.CARD || pi.key === PiTypes.PAYPAL
      ? pi.text
      : getInlinePiRender(pi, getString)}
  </div>
);

export const onRenderTitle = (options, getString) => onRenderOption(options[0], getString);

export const getDefaultPI = (piList, selectedPiId, piFilter = null) => {
  if (selectedPiId) {
    return _.findWhere(piList, { Id: selectedPiId });
  }
  if (piFilter) {
    return _.find(piList, piFilter);
  }
  return _.findWhere(piList, { IsDefault: true });
};


export const getSelectedPiId = (piList, additionalPaymentInfo) => {
  if (!additionalPaymentInfo || !piList) return null;
  const additionalInfo = JSON.parse(additionalPaymentInfo);
  const CardType = additionalInfo.cardType;
  const CardNumber = additionalInfo.last4CardNumber;
  const Id = additionalInfo.piId;
  return _.findWhere(piList, { Id }) ? _.findWhere(piList, { Id }).Id : _.findWhere(piList, { CardType, CardNumber })?.Id;
};

export const LinkButton = props => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link onClick={props.onClick} underline>
    {props.children}
  </Link>
);
LinkButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  onClick: PropTypes.func.isRequired,
};
