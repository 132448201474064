import _ from 'underscore';
import { getUrlPilots } from '../url-piloting';
import { setPilotFlags } from '../../GlobalContext/global-context';

const { uxLoggerEnabled, uxLogDisabled } = getUrlPilots() as any;
const randomOpen = _.once(() => Math.random() < 1);
const pilotFlag = 'uxLog';

export const isUXLoggerEnabled = (): boolean => {
  let isOpen: boolean = false;
  if (uxLoggerEnabled) {
    isOpen = true;
  } else if (uxLogDisabled) {
    isOpen = false;
  } else {
    isOpen = randomOpen();
  }

  setPilotFlags(pilotFlag, isOpen);
  return isOpen;
};
