import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { AuthZContextPropType, AuthorizationContextProvider, wrapInitialAuthZ } from './authZ-context';
import { LoaderContextProvider } from './loading-context';
import { DataStoreContextProvider } from './data-store-context';
import I18nContextProvider from '../../../FunctionModules/Localization/i18n-context/i18n-context-provider';
import { XpayThemeProvider } from '../../../FunctionModules/Themes/theme-context';
import { GlobalReactContextProvider, GlobalReactContextPropType } from './global-react-context';
import { IframeAjaxContextProvider } from '../../../FunctionModules/AjaxHelper/iframe-ajax/context';
import { UrlPilotContextProvider } from './url-piloting-react';

export const ContextProvider = memo(({ children, globalReactContext, initialAuthZ }) => {
  const wrappedInitialAuthZ = useMemo(() => wrapInitialAuthZ(initialAuthZ), [initialAuthZ]);

  return (
    <GlobalReactContextProvider context={globalReactContext}>
      <UrlPilotContextProvider>
        <LoaderContextProvider>
          <I18nContextProvider>
            <DataStoreContextProvider>
              <AuthorizationContextProvider initialAuthZ={wrappedInitialAuthZ}>
                <XpayThemeProvider>
                  <IframeAjaxContextProvider>
                    {children}
                  </IframeAjaxContextProvider>
                </XpayThemeProvider>
              </AuthorizationContextProvider>
            </DataStoreContextProvider>
          </I18nContextProvider>
        </LoaderContextProvider>
      </UrlPilotContextProvider>
    </GlobalReactContextProvider>
  );
});
ContextProvider.propTypes = {
  globalReactContext: GlobalReactContextPropType,
  initialAuthZ: AuthZContextPropType,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
ContextProvider.defaultProps = {
  globalReactContext: null,
  initialAuthZ: null,
};

// TODO: remove uses of withContexts, and use ReactSetupWrapper instead
export const withContexts = (Component, globalReactContext, initialAuthZ) => {
  const HOC = props => (
    <ContextProvider globalReactContext={globalReactContext} initialAuthZ={initialAuthZ} >
      <Component {...props} />
    </ContextProvider>
  );
  HOC.displayName = `withContexts(${Component.displayName})`;
  return HOC;
};
