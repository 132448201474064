import React from 'react';
import _ from 'underscore';

export const PidlValidationErrors = ({ PIDLValidationErrors }: { PIDLValidationErrors: string[] }) => (
  <div id="submitValidationErrors">
    {_.isArray(PIDLValidationErrors)
    ? _.map(
        PIDLValidationErrors,
        err => <div className="field-validation-error">{err}</div>
    )
    : <div className="field-validation-error">{PIDLValidationErrors }</div>
    }
  </div>
);
