import _ from 'underscore';
import { logEvent, logPerf } from './log';
import { globalContext } from '../../FunctionModules/GlobalContext/global-context';

const isPerformanceSupported = window.performance !== undefined;
// Try to get time with more precision from window.performance
const getCurrentTime = () => (isPerformanceSupported ? window.performance.now() : Date.now());

let pagePerfStartTime: number;
let lastEvent: string;
let lastEventTime: number;
// TODO: replace publisherName/publisherId params with something that will work in all scenarios (maybe an extraParams instead?)
export const pagePerfStarts = (eventName: string, subScenario: string, publisherName?: string, publisherId?: string) => {
  const nowTime = getCurrentTime();
  pagePerfStartTime = nowTime;
  lastEventTime = nowTime;
  lastEvent = eventName;

  const message = _.extend(
    {
      subScenario,
      publisherName,
      publisherId,
    },
    !isPerformanceSupported && { isPerformanceSupported },
    globalContext.PrevScenarioName && { prevScenarioName: globalContext.PrevScenarioName },
    globalContext.PrevSessionId && { prevSessionId: globalContext.PrevSessionId }
  );
  logPerf(eventName, message, undefined, document.URL, new Date(), 0);
};

export const logPagePerfEvent = (eventName: string, extraParams?: object) => {
  const nowTime = getCurrentTime();
  const duration = pagePerfStartTime ? nowTime - pagePerfStartTime : 0;
  const durationOfPhase = nowTime - lastEventTime;

  logPerf(eventName, { lastEvent, durationOfPhase, ...extraParams }, undefined, document.URL, new Date(), duration);
  lastEvent = eventName;
  lastEventTime = nowTime;
};

let ubStartTime: number;
let lastUbAction: string;
let lastUbActionTime: number;
// TODO: replace publisherName/publisherId params with something that will work in all scenarios (maybe an extraParams instead?)
// When panel or iframe render completes, user could be able to take action on the page.
export const ubStarts = (subScenario?: string, publisherName?: string, publisherId?: string, userAccountId?: number) => {
  const nowTime = getCurrentTime();
  ubStartTime = nowTime;
  lastUbActionTime = nowTime;
  lastUbAction = 'UB Start';

  logEvent(
    lastUbAction,
    {
      subScenario, publisherName, publisherId, userAccountId, lastEvent,
    },
    undefined,
    document.URL,
    new Date(),
    pagePerfStartTime ? nowTime - pagePerfStartTime : 0
  );
};

export const ubEvent = (eventName: string, extraParams?: object) => {
  const nowTime = getCurrentTime();
  if (ubStartTime === undefined) {
    // Handle case when user takes action before page ready
    logEvent(
      eventName,
      {
        isPageReady: false,
        lastEvent,
        durationOfPhase: nowTime - lastEventTime,
        ...extraParams,
      },
      undefined,
      document.URL,
      new Date(),
      pagePerfStartTime ? nowTime - pagePerfStartTime : 0
    );
  } else {
    logEvent(
      eventName,
      {
        lastUbAction,
        durationOfPhase: nowTime - lastUbActionTime,
        ...extraParams,
      },
      undefined,
      document.URL,
      new Date(),
      nowTime - ubStartTime
    );
  }
  lastUbAction = eventName;
  lastUbActionTime = nowTime;
};
