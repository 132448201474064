import { EnvList } from '../../Shared/Configs/config';
import { CPError } from '../../FunctionModules/ErrorHandling/error-types';
import { DRILogTemp } from '../../FunctionModules/DebugConsole/dri-log';
import { exportEndpoint, pifdEndpoint, tokenizationEndpoint, xpayEnv } from '../../Shared/Utils/xpay-env-values';

const NodeRSA = require('node-rsa');
const cryptoJS = require('crypto-js');
const { v4: uuidv4 } = require('uuid');
// eslint-disable-next-line max-len
const { addTokenDescriptor, addWildTokenDescriptor } = require('../../Shared/Utils/token-header-utils');
// eslint-disable-next-line max-len
const { header: cvHeader, increment: cvIncrement } = require('../../Shared/Components/hooks/use-cv');


function exchangeCVVToken(ajaxCall, cvv) {
  return ajaxCall({
    url: `${tokenizationEndpoint}/tokens/cvv/getToken`,
    data: { data: cvv },
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    skipAutoAddFields: true,
  })
    .then(({ data }) => data)
    .catch(() => {
      throw new CPError('ExchangeCVVToken Failed');
    });
}

function exchangeHmacKeyToken(ajaxCall, hmacKeyBase64) {
  return ajaxCall({
    url: `${tokenizationEndpoint}/tokens/piAuthKey/getToken`,
    mode: 'cors',
    data: { data: hmacKeyBase64 },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    skipAutoAddFields: true,
  })
    .then(({ data }) => data)
    .catch(() => {
      throw new CPError('ExchangeHmacToken Failed');
    });
}

async function enrollDevice(ajaxCall, piid, authHeader, devicePermission, xpayScenario) {
  const enrollUrl = `${pifdEndpoint}/users/me/paymentInstruments/${piid}/enrollDevice`;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: authHeader,
    [cvHeader]: cvIncrement(),
  };

  if (xpayEnv !== EnvList.PROD) { // This header is required in CP INT environment.
    // eslint-disable-next-line max-len
    headers['x-ms-test'] = `{"scenarios":"cvvmatched","contact":"xpay","retention":"0001-01-01T00:00:00","context_props":{${xpayScenario ? `"xpayScenario":"${xpayScenario}"` : ''}}}`;
  }

  const enrollResult = await ajaxCall({
    url: enrollUrl,
    data: devicePermission,
    headers,
    skipAutoAddFields: true,
  });
  return enrollResult;
}

function exportCard(ajaxCall, enrollmentId, deviceId, exportAuthHeader) {
  const exportUrl = `${exportEndpoint}/CreditCards/Export`;
  const bodyJson = {
    device_id: deviceId,
    enrollment_id: enrollmentId,
  };

  const headers = {
    Authorization: exportAuthHeader,
    [cvHeader]: cvIncrement(),
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  return ajaxCall({
    url: exportUrl,
    data: bodyJson,
    headers,
    skipAutoAddFields: true,
  }).catch((err) => {
    throw new CPError(`ExportCard Failed, Error: ${err.message || err.errorMsg}`);
  });
}

/**
 * @param {function} ajaxCall - AjaxCall (set up to call from iframe on xpay domain)
 * @param {string} piid - Payment instrument ID from PIFD
 * @param {string} cvv  - CVV code. Input by User
 * @param {string} PIListToken - Access token of PIList. The second token of iframe src query parameter
 * @param {string} exportToken - Acess token of export card. The third token of iframe src query parameter
 * @param {string} xpayScenario - optional parameter indicating the xpay scenario
 * @returns {Promise<string>} Card Number
 */
async function getCardDetail(ajaxCall, piid, cvv, PIListToken, exportToken, xpayScenario = null) {
  const authHeader = addTokenDescriptor(PIListToken);
  const exportHeader = addWildTokenDescriptor(exportToken);
  const deviceId = uuidv4();
  const rsa = new NodeRSA({ b: 2048 });

  const modulus = rsa.exportKey('components-public-pem').n;
  const n = modulus.toString('base64');
  const e = 'AQAB';
  const publicKeyBody = {
    alg: 'RS256',
    e,
    n,
    kty: 'RSA',
    use: 'enc',
    kid: 'device1',
  };
  const clientPublicKey = JSON.stringify(publicKeyBody);

  const hmacKey = cryptoJS.lib.WordArray.random(64);
  const hmacKeyInBase64 = cryptoJS.enc.Base64.stringify(hmacKey);

  // eslint-disable-next-line max-len
  const hmacPayload = `Cvv:${cvv}|HMACKey:${hmacKeyInBase64}|UserCredential:${authHeader}|DeviceId:${deviceId}|ClientPublicKey:${clientPublicKey}`;
  const hash = cryptoJS.HmacSHA256(hmacPayload, hmacKey);
  let cvvToken;
  try {
    cvvToken = await exchangeCVVToken(ajaxCall, cvv);
  } catch (err) {
    console.log(`${DRILogTemp}CP error, export card fail`);
    throw new CPError('EnrollDevice Failed when exchange CVV token');
  }

  let keyToken;
  try {
    keyToken = await exchangeHmacKeyToken(ajaxCall, hmacKeyInBase64);
  } catch (err) {
    console.log(`${DRILogTemp}CP error, export card fail`);
    throw new CPError('EnrollDevice Failed when exchange hmac key token');
  }

  const devicePermission =
    {
      cvvToken,
      deviceId,
      hmac: {
        data: cryptoJS.enc.Base64.stringify(hash),
        keyToken,
        algorithm: 'hmacsha256',
      },
      deviceKey: publicKeyBody,
      userCredential: authHeader,
      riskData: {
        orderId: uuidv4(),
        deviceId,
        greenId: uuidv4(),
        billingDetails: {
          amount: '0',
          currency: 'USD',
        },
        OrderLineItems: [],
        MerchantDetails: {},
      },
    };

  try {
    const enrollResult = await enrollDevice(ajaxCall, piid, authHeader, devicePermission, xpayScenario);
    const { enrollmentId } = enrollResult;

    const exportResult = await exportCard(ajaxCall, enrollmentId, deviceId, exportHeader);
    rsa.setOptions({ encryptionScheme: 'pkcs1' });
    const decryResult = rsa.decrypt(exportResult.data, 'utf8');
    return decryResult;
  } catch (err) {
    console.log(`${DRILogTemp}CP error, export card fail`);
    throw new CPError(`ExportCard failed, Error: ${err.message || err.errorMsg}`);
  }
}

export { getCardDetail };
