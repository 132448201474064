/**
 * This component is copied from @bingads-webui-react/react-template and converted to TypeScript with minimal modifications.
 * Please see the Readme for usage details:
 * https://msasg.visualstudio.com/Bing_Ads/_git/AdsAppUISharedComponents?path=%2Fprivate%2Fclient-react%2Fpackages%2Freact-template%2FREADME.md&version=GBmaster&_a=preview
 * */
import * as React from 'react';

export const ReactTemplate = React.memo(({
  interpolate = /\{\{(.+?)\}\}/g,
  model,
  template,
}: {
  interpolate?: RegExp;
  model: { [key: string]: string | JSX.Element | ((...args: any[]) => string | JSX.Element) };
  template: string;
}) => {
  let current = 0;
  const fragments = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const match = interpolate.exec(template);

    if (match === null) break;

    const {
      0: nameWithBraces,
      1: name,
      index,
    } = match;

    const trimedName = name.trim();

    const plainString = template.substring(current, index);
    current = index + nameWithBraces.length;

    if (plainString) {
      fragments.push(plainString);
    }

    if (typeof model[trimedName] === 'function') {
      const matchedPair = interpolate.exec(template);
      if (matchedPair === null) {
        throw new SyntaxError(`Expected matching pair in form of {{/${trimedName}}} but found none`);
      }
      const pairedNameWithBraces = matchedPair[0];
      const pairedName = matchedPair[1].trim();
      const pairedIndex = matchedPair.index;
      if (pairedName[0] !== '/' || pairedName.substring(1).trim() !== trimedName) {
        throw new SyntaxError(`Expected matching pair in form of {{/${trimedName}}} but found different match`);
      }
      const funcParamText = template.substring(current, pairedIndex);
      // @ts-ignore: model[trimedName] is a function
      fragments.push(model[trimedName](funcParamText));
      current = pairedIndex + pairedNameWithBraces.length;
    } else {
      fragments.push(model[trimedName]);
    }
  }

  const tail = template.substring(current);
  if (tail) fragments.push(tail);

  return (
    <React.Fragment>
      {fragments.map((frag, index) => (
        <React.Fragment key={String(index)}>
          {frag}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
});
