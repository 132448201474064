import React, { useMemo, useState, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Dropdown, ResponsiveMode } from 'office-ui-fabric-react/lib/Dropdown';

import { useI18n } from '../../../FunctionModules/Localization/i18n';
import { useAuthorization } from '../context/authZ-context';
import PiTypes from '../../Entities/pi-types';

import {
  getInlinePiRender,
  onRenderOption,
  onRenderTitle,
  getDefaultPI,
  LinkButton,
} from './utils';
import { useTheme } from '../../../FunctionModules/Themes/theme-context';
import { getPiPickerForSubscriptionStyles } from './style';

export const PiPicker = ({
  piList,
  onChange,
  defaultUseDropdown,
  hideOnNoPi,
  creditCardEnabled,
  paypalEnabled,
  selectedPiId,
  labelName,
  autoSelectAddCardEvenWithExistingPIs = false,
  piFilter = null,
  customStyles = null,
  onCustomizedRenderOption = undefined,
  onCustomizedRenderTitle = undefined,
}) => {
  const { i18n, getString } = useI18n();
  const [useDropdown, setUseDropdown] = useState(defaultUseDropdown);
  const { pidlAuthZ } = useAuthorization();
  const showDropdown = useCallback((e) => {
    e.preventDefault();
    setUseDropdown(true);
  }, []);
  const defaultPi = useMemo(() => getDefaultPI(piList, selectedPiId, piFilter), [piFilter, piList, selectedPiId]);
  const defaultKey = useMemo(() => {
    if (!pidlAuthZ) { return null; }
    if (autoSelectAddCardEvenWithExistingPIs) { return PiTypes.CARD; }
    return defaultPi ? defaultPi.Id : PiTypes.CARD;
  }, [autoSelectAddCardEvenWithExistingPIs, defaultPi, pidlAuthZ]);

  const piPickerLabelName = labelName ?? i18n.SelectAPI;

  const theme = useTheme();
  const useStyles = createUseStyles(getPiPickerForSubscriptionStyles(theme));
  const commonStyles = useStyles();

  const addCardPlaceholder = useMemo(() => ({
    key: PiTypes.CARD,
    text: i18n.AddCard,
    ariaLabel: i18n.AddCard,
  }), [i18n]);
  const addPaypalPlaceholder = useMemo(() => ({
    key: PiTypes.PAYPAL,
    text: i18n.AddPaypal,
    ariaLabel: i18n.AddPaypal,
  }), [i18n]);

  const dropdownOptions = useMemo(
    () => {
      const piListBuilder = _.map(
        piList,
        pi => ({
          ...pi,
          key: pi.Id,
        })
      );
      if (creditCardEnabled) {
        piListBuilder.push(addCardPlaceholder);
      }
      if (paypalEnabled) {
        piListBuilder.push(addPaypalPlaceholder);
      }
      return piListBuilder;
    },
    [piList, creditCardEnabled, paypalEnabled, addCardPlaceholder, addPaypalPlaceholder]
  );
  const onRenderOptionWithI18nWrapped = useCallback(
    pi => (onCustomizedRenderOption && typeof (onCustomizedRenderOption) === 'function' ?
      onCustomizedRenderOption(pi, getString) : onRenderOption(pi, getString)),
    [getString, onCustomizedRenderOption]
  );
  const onRenderTitleWithI18nWrapped = useCallback(
    options => (onCustomizedRenderTitle && typeof (onCustomizedRenderTitle) === 'function' ?
      onCustomizedRenderTitle(options, getString) : onRenderTitle(options, getString)),
    [getString, onCustomizedRenderTitle]
  );

  if (hideOnNoPi && !defaultPi) {
    return null;
  }

  if (!useDropdown) {
    return (
      <span>
        {getInlinePiRender(defaultPi, i18n)}
        {_.size(piList) > 1
                  ? (
                    <React.Fragment>
                      { '\u00A0' }
                      <LinkButton ariaLabel={i18n.PI_Choose} onClick={showDropdown}>
                        {i18n.PI_Choose}
                      </LinkButton>
                    </React.Fragment>
                  )
                  : null}
      </span>
    );
  }

  return (
    <div id="PiPicker" className="form-group">
      <Dropdown
        label={piPickerLabelName}
        ariaLabel={piPickerLabelName}
        onRenderTitle={onRenderTitleWithI18nWrapped}
        onRenderOption={onRenderOptionWithI18nWrapped}
        className={commonStyles.dropdown}
        options={dropdownOptions}
        defaultSelectedKey={defaultKey}
        placeholder={i18n.SelectAPaymentMethod}
        onChange={onChange}
        responsiveMode={ResponsiveMode.Small}
        styles={customStyles}
      />
    </div>
  );
};
PiPicker.defaultProps = {
  piList: [],
  onChange: _.noop,
  defaultUseDropdown: false,
  hideOnNoPi: false,
  creditCardEnabled: true,
  paypalEnabled: false,
  selectedPiId: null,
  labelName: _.noop(),
  autoSelectAddCardEvenWithExistingPIs: false,
  piFilter: _.noop,
  customStyles: null,
  onCustomizedRenderOption: undefined,
  onCustomizedRenderTitle: undefined,
};
PiPicker.propTypes = {
  piList: PropTypes.arrayOf(PropTypes.shape({
    IsDefault: PropTypes.bool,
    CardType: PropTypes.string,
    CardTypeDisplayLogoUrl: PropTypes.string,
    CardNumber: PropTypes.string,
    ExpiryMonth: PropTypes.string,
    ExpiryYear: PropTypes.string,
  })),
  onChange: PropTypes.func,
  defaultUseDropdown: PropTypes.bool,
  hideOnNoPi: PropTypes.bool,
  creditCardEnabled: PropTypes.bool,
  paypalEnabled: PropTypes.bool,
  selectedPiId: PropTypes.string,
  labelName: PropTypes.string,
  autoSelectAddCardEvenWithExistingPIs: PropTypes.bool,
  piFilter: PropTypes.func,
  customStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onCustomizedRenderOption: PropTypes.func,
  onCustomizedRenderTitle: PropTypes.func,
};
PiPicker.displayName = 'PiPicker';
