import React from 'react';
import { ShimmerElementsGroup, ShimmerElementType } from 'office-ui-fabric-react/lib/Shimmer';
import PI_TYPES from '../../Entities/pi-types';

export const PIDL_FIELD_MAPPING = Object.freeze({
  accountToken: 'CardNumber',
  cvvToken: 'CVV',
  accountHolderName: 'Name',
  address_line1: 'Line1',
  address_line2: 'Line2',
  city: 'City',
  postal_code: 'PostCode',
  expiryMonth: 'ExpiryMonth',
  expiryYear: 'ExpiryYear',
  addressState: 'State',
  addressCountry: 'Country',
});
export const PIDL_PROPERTY_NAME_TO_DISPLAYID_MAPPING = Object.freeze({
  region: 'addressState',
  country: 'addressCountry',
});
export const PIDL_ADDRESS_FIELDS = Object.freeze([
  'address_line1',
  'address_line2',
  'city',
  'postal_code',
  'addressState',
  'addressCountry',
]);

const shimmerStyles = { display: 'flex' };
const shimmerBlankLine = { type: ShimmerElementType.gap, width: '100%', height: 10 };
const shimmerElementsCard = [
  shimmerBlankLine,
  { type: ShimmerElementType.line, width: '100%' }, shimmerBlankLine,
  { type: ShimmerElementType.line, width: '47.5%' },
  { type: ShimmerElementType.gap, width: '5%' },
  { type: ShimmerElementType.line, width: '47.5%' }, shimmerBlankLine,
  { type: ShimmerElementType.line, width: '80%' },
  { type: ShimmerElementType.gap, width: '5%' },
  { type: ShimmerElementType.line, width: '15%' }, shimmerBlankLine,
  { type: ShimmerElementType.line, width: '100%' }, shimmerBlankLine,
  { type: ShimmerElementType.line, width: '100%' }, shimmerBlankLine,
  { type: ShimmerElementType.line, width: '100%' }, shimmerBlankLine,
  { type: ShimmerElementType.line, width: '100%' }, shimmerBlankLine,
  { type: ShimmerElementType.line, width: '100%' }, shimmerBlankLine,
  { type: ShimmerElementType.line, width: '100%' }, shimmerBlankLine,
];
const shimmerElementsPaypal = [
  shimmerBlankLine,
  { type: ShimmerElementType.line, width: '100%' },
  shimmerBlankLine,
];

const shimmerElements = {
  [PI_TYPES.CARD]: shimmerElementsCard,
  [PI_TYPES.PAYPAL]: shimmerElementsPaypal,
};

export const getShimmerElements = piType => (
  <div style={shimmerStyles}>
    <ShimmerElementsGroup
      flexWrap
      width="100%"
      shimmerElements={shimmerElements[piType]}
    />
  </div>
);

export const PIDL_REQUIRED_ADDRESS_FIELDS_BY_COUNTRY = Object.freeze({
  us: [
    'Line1',
    'City',
    'PostCode',
    'State',
    'Country',
  ],
});

export const PIDL_FIELD_ISSUES = Object.freeze({
  SelectOptionMissing: 'PidlValidation_SelectOptionMissing',
  FieldEmpty: 'PidlValidation_FieldEmpty',
  // These errors should be showing inline, so we don't need to show at the bottom
  FieldInvalidPidlDetected: 'PidlValidation_FieldInvalidPidlDetected',
  FieldInvalid: 'PidlValidation_FieldInvalid',
});
